import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { withAPI } from "../api";
import { FaEye } from "react-icons/fa";
// import "../assets/style/file-submission-form.css";
import "../assets/style/judge-submission-view.css"

const JudgeSubmissionView = ({ api, teamCode, onClose, page }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedLinks, setUploadedLinks] = useState([]);

  // Fetch uploaded files and links when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("looking for submissions", teamCode);
        const response = await api.getUploadedFilesAndLinksForTeam(teamCode);
        const data = response.data;

        // Separate files and links
        const files = data.filter((item) => !item.is_link);
        const links = data.filter((item) => item.is_link);

        setUploadedFiles(files);
        setUploadedLinks(links);
      } catch (error) {
        console.error("Failed to fetch uploaded files and links:", error.message);
      }
    };

    fetchData();
  }, [api, teamCode]);

  return (
    <div className={page} onClick={(e) => {
      if (e.target.classList.contains(page)) {
        onClose();
      }
    }}>
      <div className="popup-container">
        {/* Uploaded Links Section */}
        <div className="popup-header">
          <h2>Uploaded Links</h2>
          <button className="close-btn" onClick={onClose}>×</button>
        </div>
        <ul className="uploaded-links-list">
          {uploadedLinks.length > 0 ? (
            uploadedLinks.map((link, index) => (
              <li key={index} className="uploaded-link-item">
                <div className="link-info">
                  <a href={link.link_url} target="_blank" rel="noopener noreferrer">
                    {link.link_title}
                  </a>
                  {link.link_note && <p className="link-note">{link.link_note}</p>}
                </div>
                <div>
                  {/* Add the FaEye icon for viewing the link */}
                  <a href={link.link_url} target="_blank" rel="noopener noreferrer" className="file-action-icon">
                    <FaEye />
                  </a>
                  {/* Add the FaTrash icon for deleting the link */}
                </div>
              </li>
            ))
          ) : (
            <p>No links uploaded</p>
          )}
        </ul>

        {/* Uploaded Files Section */}
        <div className="popup-header">
          <h2>Uploaded Files</h2>
        </div>
        <ul className="uploaded-links-list">
          {uploadedFiles.length > 0 ? (
            uploadedFiles.map((file, index) => (
              <li key={index} className="uploaded-file-item">
                <span>{file.title}</span>
                <div>
                  <a href={file.webViewLink} target="_blank" rel="noopener noreferrer" className="file-action-icon">
                    <FaEye />
                  </a>
                </div>
              </li>
            ))
          ) : (
            <p>No files uploaded</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default withRouter(withAPI(JudgeSubmissionView));