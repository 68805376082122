/**
 * Features:
 * search bar
 * view all applicants
 * view and edit a given applicants application status (TODO: get typeform response for all data including resume)
 * view all teams
 * view and edit specific team (link to typeform responses)
 *
 * @format
 */

//TODO: make teams page

import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Collapse, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withAPI } from '../../../api';
import { PERM_LEVELS, APPLICATION_STATUS, ROUTES } from '../../../util';
import { withAuthorization } from '../../../session';
import DashboardNav from '../../dashboard';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, {
    CSVExport,
    Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import YearSelectDropDown from '../../../components/YearSelectDropDown';

import RubricPopup from "./rubricPopup";

const { SearchBar } = Search;


export const JudgeTeamsTable = (props) => {
    const { api, judgeName, displaySubmittedRubrics, handleSubmittedRubrics } = props;
    const [teams, setTeams] = useState([]);
    const [shownTeams, setShownTeams] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [currentTeamCode, setCurrentTeamCode] = useState(null);
    const [currentTeamName, setCurrentTeamName] = useState(null);
    const [currentTeamTheme, setCurrentTeamTheme] = useState(null);
    const [currentTeamMembers, setCurrentTeamMembers] = useState(null);
    const [error, setError] = useState(null);
    const [filterByHub, setFilterByHub]=useState(null);
    const [filterByTheme, setFilterByTheme]=useState(null);
    const [displayedTeams, setDisplayedTeams] = useState([]);

    const [teamsData, setTeamsData] = useState([]);
    const [submittedTeamsData, setSubmittedTeamsData] = useState([]);

    const themes = [
        'Sustainable Infrastructure',
        'Economic Empowerment',
        'Health & Humanity',
    ]

    const hubs = [
        'Toronto',
        'London',
        'OnlineGMT',
        'OnlineEST',
    ]
    
    useEffect(() => {
        const getTeams = () => {
            api.getTeams()
                .then((res) => {
                    console.log(res);
                    let teamsDataTemp=[];
                    const Teams = res.Teams;
                    for (let Team of Teams) {
                        const team = {};
                        team['team_name'] = Team['team_name'];
                        team['team_code'] = Team['team_code'];
                        team['theme'] = themeToText(Team['theme']);
                        team['location'] = Team['team_location'];
                        // team['pitch_deck_link'] = Team['pitch_deck_link'];
                        // team['submission_repo_link'] = Team['submission_repo_link'];
                        // team['prerecording_link'] = Team['prerecording_link'];
                        team['team_members'] = Team.team_members .map( (member) =>
                            `${member.first_name} ${member.last_name}`
                        ).join(', ');
                        team['id'] = Team['_id'];
                        teamsDataTemp.push(team);
                    }
                    setTeamsData(teamsDataTemp);
                })
                .catch((error) => {
                    console.log(error.errorMsg);
                    setError(error.errorMsg);
                });
        };
        getTeams();
    }, [judgeName]);

    useEffect(()=>{
        if(displaySubmittedRubrics){
            setTeams(submittedTeamsData);
        }
        else{
            setTeams(teamsData);
        }
    },[displaySubmittedRubrics, judgeName, submittedTeamsData, teamsData])

    useEffect(()=>{
        if(judgeName !== "default"){
            api.getSubmittedRubrics(judgeName)
            .then((res)=>{
                let submittedTeamsDataTemp=[];
                console.log(res);
                const Teams = res.Teams;
                for (let Team of Teams) {
                    const team = {};
                    team['team_name'] = Team['team_name'];
                    team['team_code'] = Team['team_code'];
                    team['theme'] = themeToText(Team['theme']);
                    team['location'] = Team['team_location'];
                    team['team_members'] = Team.team_members .map( (member) =>
                        `${member.first_name} ${member.last_name}`
                    ).join(', ');
                    team['id'] = Team['_id'];
                    submittedTeamsDataTemp.push(team);
                }
                setSubmittedTeamsData(submittedTeamsDataTemp);
            });
        }
    },[judgeName, displaySubmittedRubrics])

    useEffect(()=>{
        let teamsTemp;
        if (displaySubmittedRubrics){
            teamsTemp = submittedTeamsData;
        }
        else{
            teamsTemp = teamsData;
        }
        
        if(hubs.includes(filterByHub)){
            teamsTemp = teamsTemp.filter(team=>team.location === filterByHub);
        }
        if(themes.includes(filterByTheme)){
            teamsTemp = teamsTemp.filter(team=>team.theme === filterByTheme);
        }
        setDisplayedTeams(teamsTemp);

    },[filterByHub, filterByTheme, submittedTeamsData, teamsData, displaySubmittedRubrics])

    // useEffect(()=>{
    //     console.log(teams)
    // },[teams])

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total pagination" style={{marginLeft:"15px"}}>
            Showing {from} to {to} of {size} Results
        </span>
    );
   

    const teamRowEvents = {
        onClick: (e, row, rowIndex) => {
            setCurrentTeamCode(row.team_code);
            setCurrentTeamName(row.team_name);
            setCurrentTeamTheme(row.team_theme);
            setCurrentTeamMembers(row.team_members);
            setPopupOpen(true);
            //   props.history.push(`${ROUTES.ADMIN_DASHBOARD_HACKER_INFO}/${row.id}`);
        },
    };

    // Map to new themes - temporary
    const themeToText = (theme) => {
        if (theme === 'Economic Advancement') return 'Economic Empowerment';
        if (theme === 'Sustainable Societies')
            return 'Sustainable Infrastructure';
        if (theme === 'Health & Humanity') return 'Health & Humanity';
        return 'Unselected';
    };

    const teamColumns = [
        { text: 'Team Name', dataField: 'team_name' },
        { text: 'Team Code', dataField: 'team_code'},
        { text: 'Theme', dataField: 'theme' },
        { text: 'Location', dataField: 'location' },
    ];

    const teamOptions = {
        paginationSize: 4,
        pageStartIndex: 0,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {text: '20',value: 20,},
            {text: '50',value: 50,},
            {text: 'All',value: teams.length,},
        ],
    };

    const hidePopup = () =>{
        setPopupOpen(false);
    }

    return (
        <>
            <div className="gs-applications-container w-100 p-3" style = {{wordBreak: 'break-all'}}>
                {popupOpen && <RubricPopup renderHomePage={hidePopup} 
                                            teamCode={currentTeamCode} 
                                            teamName={currentTeamName}
                                            teamTheme={currentTeamTheme}
                                            teamMembers={currentTeamMembers}
                                            judgeName = {judgeName}
                                            ></RubricPopup>}
                <ToolkitProvider
                    keyField="id"
                    data={displayedTeams}
                    columns={teamColumns}
                    search
                    exportCSV
                >
                    {(props) => (
                        <div className="main-container">
                            <div className="table-header">
                                <div className="welcome"><span>Welcome,&nbsp;<strong> {`${judgeName}!`}</strong> </span></div>
                                <SearchBar
                                    {...props.searchProps}
                                    placeholder="Search Names, Codes, Themes and Location"
                                    className="search-bar text-center"
                                />
                                <div className="filter-container">
                                    
                                    <OverlayTrigger
                                        placement="right" // Tooltip position (top, bottom, left, right)
                                        overlay={<Tooltip className="tooltip" id="tooltip">Turn on to only view teams you have submitted scores for</Tooltip>}
                                        delay={{ show: 100, hide: 200 }} // Optional: delay in ms
                                    >
                                    <div style={{"display":"flex", justifyContent:"center", alignItems:"center", gap:"1rem"}}>
                                        <span>View Submitted Rubrics</span>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            onChange={ ()=>{
                                                handleSubmittedRubrics();
                                            }
                                            }
                                            className="large-switch"
                                        >
                                        </Form.Check>
                                    </div>
                                    </OverlayTrigger>
                                    <div className="display-flex buttons-container">
                                        <FilterByButton setFilterBy={setFilterByHub} options={hubs} type="Hub"/>
                                        <FilterByButton setFilterBy={setFilterByTheme} options={themes} type="Theme"/>
                                    </div>
                                </div>

                                <div style={{ overflowX: "auto" }}>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        pagination={paginationFactory(teamOptions)}
                                        rowEvents={teamRowEvents}
                                        bordered={false}
                                        responsive={true}
                                        hover
                                    />
                                </div>

                            </div>
                        </div>
                    )}
                    </ToolkitProvider>
            </div>
        </>
    );
};

export const FilterByButton = ({ setFilterBy, options, type }) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const dropdownRef = useRef(null);
    const buttonRef=useRef(null);

    const handleButtonClick = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleRadioChange = (event, option) => {
        handleFilterByChange(option);
        handleOptionChange(event);
    };

    const handleFilterByChange = (option) => {
        // changes if the data should be filtered or not be filtered
        setFilterBy((prevFilterBy) =>
            prevFilterBy === option ? null : option
        );
    };

    const handleOptionChange = (event) => {
        // changes radio button to be shown or not shown
        if (selectedOption === event.target.value) {
            setSelectedOption('');
        } else {
            setSelectedOption(event.target.value);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    return (
        <div className={`sortby-button ${isDropdownOpen ? 'open' : ''}`}>
            <button
                className={`dropdown-button judge-dropdown-button ${
                    isDropdownOpen ? 'dropdown-button-radius' : ''
                }`}
                onClick={handleButtonClick}
                ref={buttonRef}
            >
                Filter By {type}
            </button>
            {isDropdownOpen && (
                <div className="dropdown-filterby" ref={dropdownRef}>
                    {
                        options.map((option, index) => (
                            <DropdownItem name={option} handleRadioChange={handleRadioChange} selectedOption={selectedOption} key={index} />
                        ))
                    }
                    
                </div>
            )}
        </div>
    );
};



export const DropdownItem = ({name, handleRadioChange, selectedOption})=>{
    return(
    <label className="display-flex">
            <input
                type="radio"
                name="filterOption"
                value={name}
                checked={selectedOption === name}
                onClick={handleRadioChange}
                onChange={(event) =>
                    handleRadioChange(event, name)
                }
                className="mt-3 ml-3 radio-circle"
            />
            <p className="radio-text1">{name}</p>
        </label>
    );
}


const condition = (authUser) =>
    !!authUser && (authUser.perm_level === PERM_LEVELS.JUDGE ||authUser.perm_level === PERM_LEVELS.ADMIN) ;
export default compose(
    withRouter,
    withAPI,
    withAuthorization(condition)
)(JudgeTeamsTable);
