import React from "react";

import RouterItems from "./components/RouterItems";
import { withAuthentication } from "./session";

const App = () => {
  return (
    <div className="gs-content">
      <RouterItems />
    </div>
  );
};

export default withAuthentication(App);
