/** @format */

import React from 'react';
import { useState, useEffect } from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

import { PAGES_IMAGES, HACKER_LINKS } from '../../../util';
import Footer from '../../../components/Footer';
import DashboardNav from '../';
import { withAuthorization } from '../../../session';
import { withAPI } from '../../../api';

const Resource = ({ title, description, link_name }) => {
    return (
        <>
            <Col md={6} style={{marginBottom:"15px"}}>
                <Card className="mr-3 display-flex h-100" >
                    <Row className="d-flex align-items-center" style={{height:"100%"}}>
                        <Col md={2}>
                            <Image
                                src={PAGES_IMAGES.resources_lightbulb}
                                fluid
                                className="resources-lightbulb rounded-0"
                            />
                        </Col>
                        <Col md={6}>
                            <h6 className="mt-4 font-weight-bold">{title}</h6>
                            <p>{description}</p>
                        </Col>
                        <Col md={3}>
                            <a
                                href={link_name}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="view-button"
                            >
                                <small>View</small>
                            </a>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </>
    );
};


// const Resource = ({ title, description, link_name }) => {
//     return (
//         <>
//             <Col md={6} style={{marginBottom:"15px"}}>
//                 <Card className="mr-3 display-flex h-100" >
//                     <Row className="d-flex align-items-center" style={{height:"100%"}}>
//                         <Col md={2}>
//                             <Image
//                                 src={PAGES_IMAGES.resources_lightbulb}
//                                 fluid
//                                 className="resources-lightbulb rounded-0"
//                             />
//                         </Col>
//                         <Col md={6}>
//                             <h6 className="mt-4 font-weight-bold">{title}</h6>
//                             <p>{description}</p>
//                         </Col>
//                         <Col md={3}>
//                             <a
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                                 className="view-button"
//                             >
//                                 <small>Coming Soon</small>
//                             </a>
//                         </Col>
//                     </Row>
//                 </Card>
//             </Col>
//         </>
//     );
// };

const ResourceGroup = ({title, hackerGuideLink})=>{
    return (
        <>
            <Row className="pb-3">
                <Resource
                    title={title}
                    description="2025 Hacker Guide"
                    link_name={
                        hackerGuideLink
                    }
                />
                
            </Row>
        </>
    )
}

const ThemeCard = ({image, title, description, link})=>{
    return(
        <Col xl={4} lg={6} className="pb-3">
            <Card className="h-100">
                <Card.Img
                    variant="top"
                    src={image}
                />
                <Card.Body className="resources-card-body">
                    <Card.Title
                        className="font-weight-bold"
                        as="h4"
                    >
                        {title}
                    </Card.Title>
                    <Card.Text className="resources-card-text">
                        {description}
                    </Card.Text>
                    <Row className="mt-auto pb-3">
                        {/* <Col>
                            <a href={HACKER_LINKS.global_vid} target="_blank" rel="noopener noreferrer" className="btn-blue w-100">
                                <small>Theme Video</small>
                            </a>
                        </Col> */}
                        <Col >
                            <a
                                href={
                                    link
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn-blue w-100"
                            >
                                <small>Resources</small>
                                {/* <small>Coming Soon</small> */}
                            </a>
                        </Col>
                    </Row>
                    {/* removing theme partner
                    <div className="gs-resource-theme-partners">
                        <h5>
                            Theme Partners:
                        </h5>
                        <div className="text-center">
                            <Image src={SPONSOR_IMAGES.unicef} fluid />
                            <br />
                            <Image src={SPONSOR_IMAGES.wfp} fluid />
                        </div>
                    </div>*/}
                </Card.Body>
            </Card>
        </Col>
    )
}

/* Join Slack Section - removed for now
const JoinSlack = ({ title, description, link_name }) => {
    return (
        <>
            <Col md={12}>
                <Card className="mr-3 display-flex h-100">
                    <Row className="d-flex align-items-center">
                        <Col md={1}>
                        <Image src={PAGES_IMAGES.slack_png} fluid className="slack-img resources-lightbulb rounded-0" />
                        </Col>
                        <Col md={8}>
                            <h6 className="mt-4 font-weight-bold">{title}</h6>
                                <p>{description}</p>
                        </Col>
                        <Col md={3} className="join-slack">
                            <a href={link_name} target="_blank" rel="noopener noreferrer" className="view-button w-100">
                                <small>Join</small>
                            </a>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </>
    )
}
*/

const ResourcePage = (props) => {
    const [location, setLocation] = useState('');

    useEffect(() => {
        props.api.currentUser().then((res) => {
            setLocation(res.User.location);
        });
    }, [props.api]);

    return (
        <>
            <DashboardNav />
            <div className="gs-dashboard-content">
                <div className="gs-resources-container">
                    <Container fluid className="gs-htg-block">
                        {/* Join Slack Resource Section
                        <h2 className="font-weight-bold">Stay Connected!</h2>
                        <Row className="get-connected">
                            <JoinSlack title="" description="Join the Slack for useful updates, to connect with hackers, and ask questions!" link_name={HACKER_LINKS.join_slack_2024} />
                        </Row> 
                        */}

                        <h2 className="font-weight-bold">Resources</h2>
                        <p className="pb-3 resources-card-text">
                            The essential resources you need for Hack the Globe!{' '}
                        </p>
                        {location === "Toronto" && 
                            (<ResourceGroup
                                title="Toronto"
                                isOnline = {false}
                                hackerGuideLink={HACKER_LINKS.hacker_guide_toronto}
                            ></ResourceGroup>)
                        }
                        {location === 'London' && (
                            (<ResourceGroup
                                title="London"
                                isOnline = {false}
                                hackerGuideLink={HACKER_LINKS.hacker_guide_london}
                            ></ResourceGroup>)

                        )}
                        {location === 'OnlineEST' && (
                            (<ResourceGroup
                                title="OnlineEST"
                                isOnline = {true}
                                hackerGuideLink={HACKER_LINKS.hacker_guide_onlineest}
                            ></ResourceGroup>)
                        )}
                        {location === 'OnlineGMT' && (
                            (<ResourceGroup
                                title="OnlineGMT"
                                isOnline = {true}
                                hackerGuideLink={HACKER_LINKS.hacker_guide_onlinegmt}
                            ></ResourceGroup>)
                        )}
                    </Container>

                    <Container fluid className="gs-htg-block">
                        <h2 className="font-weight-bold">
                            Check Out The Themes!
                        </h2>
                        <p className="pb-3 resources-card-text">
                            Three amazing themes lined up for Hack the Globe
                            2025 and resources for each.
                        </p>
                        <Row>
                            <ThemeCard
                                image = {PAGES_IMAGES.global.default}
                                title = "Health and Humanity"
                                description = "Ensuring everyone has access to clean water and sanitation, affordable healthcare, and appropriate food and nutrition - no matter their circumstances."
                                link = {HACKER_LINKS.health_guide}
                            ></ThemeCard>
                            <ThemeCard
                                image = {PAGES_IMAGES.equitable.default}
                                title = "Economic Empowerment"
                                description = "Building the capacity of underserved individuals and communities to gain equal access to education, economic opportunity, finance, and prosperity, upheld by respect for human dignity and rights."
                                link = {HACKER_LINKS.economic_guide}
                            ></ThemeCard>
                            <ThemeCard
                                image = {PAGES_IMAGES.climate.default}
                                title = "Sustainable Infrastructure"
                                description = "Accelerating progress on sustainable solutions to prevent further damage to our shared environment, and acting on the existing adverse effects of climate change."
                                link = {HACKER_LINKS.sustainable_guide}
                            ></ThemeCard>
                            
                            {/* <Col xl={3} lg={6}>
                            <Card className="h-100">
                                <Card.Img variant="top" src={PAGES_IMAGES.economic} />
                                <Card.Body>
                                    <Card.Title as="h4">Economic Empowerment</Card.Title>
                                    <Card.Text>
                                    Empower underrepresented populations to access education, increase economic opportunities, and realize decent work for all. 
                                    </Card.Text>
                                    <Row className="mt-auto pb-3">
                                        <Col>
                                            <a href={HACKER_LINKS.economic_vid} target="_blank" rel="noopener noreferrer" className="btn-blue w-100">
                                                <small>Theme Video</small>
                                            </a>
                                        </Col>
                                        <Col>
                                            <a href={HACKER_LINKS.economic_guide} target="_blank" rel="noopener noreferrer" className="btn-blue w-100">
                                                <small>Resources</small>
                                            </a>
                                        </Col>
                                    </Row>
                                    <div className="gs-resource-theme-partners">
                                        <h5>
                                            Theme Partners:
                                        </h5>
                                        <div className="text-center">
                                            <Image src={SPONSOR_IMAGES.ga} fluid />
                                            <br />
                                            <Image src={SPONSOR_IMAGES.agf} fluid />
                                            <br />
                                            <Image src={SPONSOR_IMAGES.rumie} fluid />
                                            <br />
                                            <Image src={SPONSOR_IMAGES.uncdf} fluid />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            </Col> */}
                        </Row>
                    </Container>

                    {/* Business Resources and Technical Resources 
                    <Container className="gs-htg-block">
                        <Row>
                            <Col md={6}>
                                <Card className="mr-md-3 mb-5 mb-md-0">
                                    <Card.Img variant="top" src={PAGES_IMAGES.business.default} />
                                    <Card.Body>
                                        <Card.Title as="h4">Business Resources</Card.Title>
                                        <Card.Text>
                                        A few resources to help you get started on your business plan and thinking about business strategy
                                        </Card.Text>
                                        <a href={HACKER_LINKS.business} target="_blank" rel="noopener noreferrer" className="btn-secondary w-50">
                                            <small>View</small>
                                        </a>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card className="ml-md-3">
                                    <Card.Img variant="top" src={PAGES_IMAGES.technical.default} />
                                    <Card.Body>
                                        <Card.Title as="h4">Technical Resources</Card.Title>
                                        <Card.Text>
                                        Datasets, platforms, and tutorials to start building your technical solution
                                        </Card.Text>
                                        <a href={HACKER_LINKS.technical} target="_blank" rel="noopener noreferrer" className="btn-secondary w-50">
                                            <small>View</small>
                                        </a>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        </Container> */}
                </div>
                <Footer />
            </div>
        </>
    );
};

const condition = (authUser) =>
    !!authUser &&
    ((authUser.acceptance_status === 'ACCEPTED_RSVP_YES' &&
        authUser.rsvp_option) ||
        authUser.perm_level === 'Admin');
export default compose(
    compose(withRouter, withAuthorization(condition)),
    withAPI
)(ResourcePage);
