/** @format */

import React, {useState} from 'react';
import {
    Modal,
    ModalBody,
    Button,
    Dropdown,
    FormControl,
} from 'react-bootstrap';
import { Component } from 'react';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { withAPI } from '../../../api/index.js';
import { PAGES_IMAGES } from '../../../util/index.js';
import { compose } from 'recompose';
import {SearchableDropdown} from './searchableDropdown.js';

const INITIAL_STATE = {
    name:'',
    actualPassword:'',
    password: '',
    displayPasswordMessage:false,
};

class passwordPopup extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

    }

    componentDidMount(){
        this.state.name = this.props.judgeName;

        this.props.api.getPassword(this.props.judgeName).then((res) => {
            this.state.actualPassword = res.body;
            console.log(this.state)
        })
        .catch((error) => {
            console.log(error);
            console.log(this.state)
        });
    };

    onChange = (event) => {
        if (event.target.value === '') {
            this.setState({
                error: 'Please complete all fields before continuing',
            });
        } else {
            this.setState({ error: '' });
        }
        this.setState({ [event.target.name]: event.target.value });
    };

    
    render() {
        const {
            check,
            judgeName,
            displayPasswordMessage
        } = this.state;
        const isMissingInfo = !(
            judgeName
        );
        const should_render = this.state.should_render;

        const handleInput = (e)=>{
            this.setState({password:e.target.value})
        }

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
              handleSubmission();
            }
        };

        const handleSubmission = () => {
            if (this.state.password === this.state.actualPassword){
                this.props.renderHomePage();
                this.props.setDisplayCancelButton();
            } else{
                this.setState({displayPasswordMessage:true});
            }
        };

        return (
            <Modal show size="xl" centered className="waitlisted no-transition">
                <ModalHeader className="welcome">
                    Hi, {this.props.judgeName}
                </ModalHeader>
                <ModalBody>
                    <div className="popup-content-1">
                        <span>Please enter your judging password</span>
                        
                        <input onChange={handleInput} onKeyDown={handleKeyDown}/>

                        <Button
                            className="btn-purple"
                            onClick={() => {
                                handleSubmission();
                            }}
                        >
                            Finish
                        </Button>

                        {this.props.displayCancelButton? 
                        <Button
                        className="btn-red"
                        onClick={()=>{
                            this.props.renderHomePage();
                        }} 
                        >
                            Cancel
                        </Button> :
                        <Button
                            className="btn-red"
                            onClick={()=>{
                                this.props.renderHomePage();
                                this.props.renderJudgeNamePopup();
                            }} 
                        >
                            Not you? Back
                        </Button> }
                        {displayPasswordMessage && <span className="text-red">Password is incorrect</span>}
                    </div>
                </ModalBody>
                <img
                    className="waitlist-img"
                    src={PAGES_IMAGES.no_team_bg.default}
                ></img>
            </Modal>
        );
    };
}


const PasswordPopup = compose(withAPI)(passwordPopup);

export default PasswordPopup;
