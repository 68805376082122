/** @format */

import React, {useState, useEffect, useRef, createRef} from 'react';
import {
    Modal,
    ModalBody,
    Button,
    Dropdown,
    FormControl,
} from 'react-bootstrap';
import { Component } from 'react';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { withAPI } from '../../../api';
import { PAGES_IMAGES } from '../../../util';
import { compose } from 'recompose';
import {SearchableDropdown} from './searchableDropdown.js';
import "../style.css";
import {columns, comments, questions} from '../util/questions.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfo} from '@fortawesome/free-solid-svg-icons'; 
import JudgeSubmissionView from '../../../components/JudgeSubmissionView';

const INITIAL_STATE = {
    teamCode:'',
    teamMembers:'',
    scores: [],
    comments:[],
    total:0,
    maxPoints:0,
    cancelPopup:false,
    changesMade:false,
    showFiles:false,
    error:false,
};

class Popup extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
        this.rubricRef = createRef();
    }

    columns = columns;
    comments = comments;
    questions = questions;
    numQuestions = 0;


    componentDidUpdate(prevProps, prevState) {
        // Check if the specific state variable has changed
        if (this.state.scores && prevState.scores !== this.state.scores) {
            let newTotal = 0;
            for (let i = 0; i < this.state.scores.length; i++){
                if(this.state.scores[i]) {
                    newTotal += +this.state.scores[i];
                }
            };
            this.setState({total:newTotal});
        }
    }


    componentDidMount() {
        this.props.api.getScore(this.props.teamCode, this.props.judgeName).then((res)=>{
            console.log(res.body);
            if (res.body.scores){this.setState({scores:res.body.scores});}
            if (res.body.comments){this.setState({comments:res.body.comments});}
        })
        for (let question of this.questions[0]){
            if (question.type !== 'comment'){
                this.numQuestions++;
            }
        }
    }

    handleSubmission = () => {
        
        // this.props.api.submit_score(
        //     {   team_code: this.props.teamCode,
        //         judge: this.props.judgeName,
        //         scores: scores,
        //         comments: comments,
                
        //     }).then((res) => {
        //         console.log(res)
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
        if (this.state.scores.some(element => element === '' || element === null || element === undefined) || this.state.scores.length < this.numQuestions){
            this.setState({error: true});
            return;
        }
        this.setState({error: false});
        this.props.api.submit_score( this.props.teamCode,this.props.judgeName,this.state.scores,this.state.comments,
            ).then((res) => {
                console.log(res.status)
            })
            .catch((error) => {
                console.log(error);
            });
        this.setState({changesMade:false, savePopup:true});
    };

    handlecheck = (check) => {
        this.setState({ check: !check });
    };

    onChange = (event) => {
        if (event.target.value === '') {
            this.setState({
                error: 'Please complete all fields before continuing',
            });
        } else {
            this.setState({ error: '' });
        }
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const {
            teamCode,
            total,
            maxPoints,
            cancelPopup,
            changesMade,
            savePopup,
            showFiles,
        } = this.state;
        const isMissingInfo = !(
            teamCode
        );

        const handleSelectionChange = (value) => {
            console.log('Selected value:', value);
            this.setState({judgeName:value});
        };

        const updateRubricQuestion = (index, value) =>{
            const updatedScores = [...this.state.scores];

            updatedScores[index] = value;

            this.setState({scores:updatedScores});
        };

        const updateRubricComment = (index, value) =>{
            const updatedComments = [...this.state.comments];
            updatedComments[index] = value;
            this.setState({comments:updatedComments});
        };

        const updateMaxPoints = (value) =>{
            this.setState(prevState=>({
                maxPoints:prevState.maxPoints + +value
            }));
        }

        const setChangesMade = ()=>{
            this.setState({changesMade:true});
        }

        const setShowFiles = (val) => {
            this.setState({showFiles:val})
        }

        let questionCounter = 0;

        return (
            <Modal show centered size="xl" className="waitlisted rubricPopupContainer">
                {showFiles ? (
                    <><JudgeSubmissionView api={this.props.api} teamCode={this.props.teamCode} onClose={() => setShowFiles(false)} page={"judge"}/></>
                ) : <></>}
                <ModalBody className="rubric-body" >
                    <div className="rubric-content padding-top-0">
                        {!this.props.teamPage && <div className='rubric-info-container'>
                            <span>{`Team Name: ${this.props.teamName}`}</span>
                            <span>{`Code: ${this.props.teamCode}`}</span>
                            <span>{`Team Members: ${this.props.teamMembers}`}</span>
                            <span><Button className="show-submission-button" onClick={() => setShowFiles(true)}>Show File Submissions</Button></span>
                        </div>}
                        <button className="x-btn" onClick={()=>{
                                        this.setState({cancelPopup:false});
                                        this.props.renderHomePage();
                                    }}
                                >×</button>

                        <div className="rubric-grid" ref={this.rubricRef}>
                            {this.columns.map((column, columnIdx)=>
                                (<div className="rubric-column" key={columnIdx} ref={this.columnRef}>
                                    <span className="column-title">{`${column.column_title} (${column.points} points)`}</span>
                                    {column.questions.map((question, questionIdx)=>(
                                        question.type === 'comment' ?
                                            <RubricComment key={`${questionCounter}`} title={question.title} index = {questionCounter++} 
                                            handleUpdate = {updateRubricComment} changesMade={setChangesMade} default_comments={this.state.comments} isDisabled={this.props.teamPage}/> :
                                            <RubricQuestion key = {`${questionCounter}`} title={question.title} question = {question.question} 
                                                points = {question.points} criteria={question.criteria} index={questionCounter++} 
                                                handleUpdate={updateRubricQuestion} updateTotal = {updateMaxPoints} default_score={this.state.scores} 
                                                changesMade={setChangesMade} rubricRef={this.rubricRef} isDisabled={this.props.teamPage}/>
                                    ))}
                                </div>)
                            )}

                            {/* {this.comments.map((comment, index)=>
                                (<RubricComment key={index} title={comment.title} index = {index} handleUpdate = {updateRubricComment} 
                                        default_comments={this.state.comments} changesMade={setChangesMade}></RubricComment>)
                            )} */}

                        </div>
                        <div className="total-container">
                            <span className="total-label">{`Total: ${total} / ${maxPoints}`}</span>
                        {this.state.error && <span className="text-red" style={{position:'absolute', marginBottom:'-3rem'}}>Please fill out all points fields before submitting</span>}
                        </div>
                        {!this.props.teamPage ? <div className="button-container">
                            <Button
                                className="btn-purple"
                                onClick={() => {
                                    this.handleSubmission();
                                    
                                    //this.props.renderHomePage();
                                }}
                            >
                                Save
                            </Button>
                            <Button
                                className="btn-red"
                                onClick={() => {
                                    if(!changesMade){
                                        this.props.renderHomePage();
                                    }
                                    else{
                                        this.setState({cancelPopup: true});
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                        </div> :
                            <Button
                            className="btn-red"
                            onClick={() => {
                                if(!changesMade){
                                    this.props.renderHomePage();
                                }
                                else{
                                    this.setState({cancelPopup: true});
                                }
                            }}
                            >
                            Close
                            </Button>
                        }
                    </div>
                </ModalBody>
                {cancelPopup && 
                <Modal size="sm" show centered>
                    <ModalBody>
                        <div className="rubric-content">
                            Are you sure you want to discard your changes?

                            <div className="button-container">
                                <Button
                                    className="btn-purple"
                                    onClick={()=>{
                                        this.setState({cancelPopup:false});
                                    }}
                                >
                                    Back
                                </Button>
                                <Button
                                    onClick={()=>{
                                        this.setState({cancelPopup:false});
                                        this.props.renderHomePage();
                                    }}
                                >
                                    Discard
                                </Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>}

                {savePopup && 
                <Modal size="sm" show centered>
                    <ModalBody>
                        <div className="rubric-content">
                            Your changes have been saved
                            
                            <Button
                                className="btn-purple"
                                onClick={()=>{
                                    this.setState({savePopup:false});
                                    this.props.renderHomePage();
                                }}
                            >
                                Close
                            </Button>

                        </div>
                    </ModalBody>
                </Modal>}
            </Modal>
        );
    }
}

Popup.defaultProps = {
    teamPage: false,
}


const RubricQuestion = ({title, question, points, index, handleUpdate, updateTotal, default_score, changesMade, criteria, rubricRef, isDisabled})=>{
    const [value, setValue] = useState('');
    const [position, setPosition] = useState("bottom");
    const [horizontalPosition, setHorizontalPosition] = useState("center");
    const [showCriteria, setShowCriteria] = useState("hide");
    const [inputFocused, setInputFocused] = useState(false);
    const tooltipRef = useRef(null);
    const pointInputRef = useRef(null);

    const handleChange = (e) => {
        const newValue = e.target.value;
        if (/^\d*$/.test(newValue) && newValue.length <= 3 && +newValue <= +points) {
            setValue(newValue);
            handleUpdate(index, newValue);
            changesMade();
        }
    };

    useEffect(()=>{
        updateTotal(points);
    },[]);

    useEffect(()=>{
        if(default_score && default_score[index]){
            setValue(default_score[index]);
        }
    },[default_score])

    useEffect(() => {
        const updateTooltipPosition = () => {
            if (!tooltipRef.current) return;

            const tooltipRect = tooltipRef.current.getBoundingClientRect();
            
            if (tooltipRect.bottom > rubricRef.current.getBoundingClientRect().bottom) {
                setPosition("top");
            } else {
                setPosition("bottom");
            }

            if (rubricRef.current.getBoundingClientRect().right < tooltipRect.right){
                setHorizontalPosition("left");
            }
            else if (rubricRef.current.getBoundingClientRect().left > tooltipRect.left){
                setHorizontalPosition("right");
            }
            else{
                setHorizontalPosition("center");
            }
        };
        updateTooltipPosition();
        window.addEventListener("resize", updateTooltipPosition);
        return () => window.removeEventListener("resize", updateTooltipPosition);
    }, []);


    const clickHideCriteria = () =>{
        setShowCriteria("hide");
        
    }

    const clickShowCriteria = () =>{
        setShowCriteria("show");
        setInputFocused(true);
    }

    useEffect(() => {
        const handleClick = (event) => {
            if(event.target.className !== "criteria-item" && event.target !== pointInputRef.current){
                setShowCriteria("hide");
                setInputFocused(false);
            }
        };
    
        document.addEventListener("click", handleClick);
    
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);
    
    return (
        <div  className={`question-container `}> 
            <div className={`rubric-question ${showCriteria === "show" ? "point-outline" : ""}`}>
                <div className="rubric-header">
                    <span className="rubric-question-title">{title}</span>
                    <div className="points-container">
                        <input className={`points-input rubric-input`}
                            maxLength={3}  
                            type='text'
                            value={value}
                            onChange={handleChange}
                            inputMode="numeric"
                            placeholder={0}
                            onFocus = {clickShowCriteria}
                            onBlur = {clickHideCriteria}
                            ref={pointInputRef}
                            disabled={isDisabled}
                            ></input> 
                        <span>{`/${points} points`}</span>
                    </div>
                </div>
                <p key = {index}>{question}</p>
            </div>
            {
            criteria &&
                <div className={`criteria-container ${position} ${showCriteria} ${horizontalPosition}`} ref={tooltipRef}>
                {criteria.map((criterion, idx)=>
                    <>
                        <span className="criteria-item criteria-points" key={idx}>{`${criterion.points} points:`}</span>
                        <span className="criteria-item" key={100-idx}>{criterion.description}</span>
                    </>
                )}   
                </div>
            }
        </div>
    )
}

const RubricComment = ({title, index, handleUpdate, default_comments, changesMade, isDisabled})=>{
    const [value, setValue] = useState('');

    useEffect(()=>{
        if(default_comments && default_comments[index]){
            setValue(default_comments[index]);
        }
    },[default_comments])

    const handleChange = (e)=>{
        const newValue = e.target.value;
        setValue(newValue);
        handleUpdate(index,newValue);
        changesMade();
    }

    return(
        <div className="rubric-question rubric-comment">
            <span className="rubric-question-title">{title}</span>
            <textarea
                onChange={handleChange}
                value={value}
                placeholder="Type here..."
                className="rubric-comment-input rubric-input"
                disabled={isDisabled}/>
        </div>
    )
}

const RubricPopup = compose(withAPI)(Popup);

export default RubricPopup;
