import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Image, Row } from "react-bootstrap";

import { PAGES_IMAGES, ROUTES } from "../../../util";
import Header from "../../../components/Header";

const ForgotPasswordConfirmPage = () => (
  <div>
    <Container fluid>
      <Row className="h-100 mt-auto mb-auto">
        <Col md={5} xl={4}>
          <Header />
          <div className="gs-form-container pr-5 pl-5">
            <h3 className="pb-3">Link Sent!</h3>
            Please Check Your Email For Instructions
            <br />
            <Link to={ROUTES.LOGIN}>Back To Log In</Link>
          </div>
        </Col>
        <Col
          md={7}
          xl={8}
          className="bg-light-yellow h-100 p-0 d-none d-md-block"
        >
          <Image
            src={PAGES_IMAGES.password.default}
            className="gs-password-image"
          />
        </Col>
      </Row>
    </Container>
  </div>
);

export default ForgotPasswordConfirmPage;
