import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import uploadImg from "../assets/images/submission/cloud-upload-regular-240.png";
import "../assets/style/drop-file-input.css";
import fileDefault from '../assets/images/submission/file-blank-solid-240.png';
import fileCSS from '../assets/images/submission/file-css-solid-240.png';
import filePdf from '../assets/images/submission/file-pdf-solid-240.png';
import filePng from '../assets/images/submission/file-png-solid-240.png';

export const ImageConfig = {
    default: fileDefault,
    pdf: filePdf,
    png: filePng,
    css: fileCSS
}

const DropFileInput = ({ onFileChange, onClose }) => {
    const wrapperRef = useRef(null);
    const [fileList, setFileList] = useState([]);

    const onDragEnter = () => wrapperRef.current.classList.add("dragover");
    const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
    const onDrop = () => wrapperRef.current.classList.remove("dragover");

    const onFileDrop = (e) => {
        const newFiles = Array.from(e.target.files);
        if (newFiles.length > 0) {
            const updatedList = [...fileList, ...newFiles];
            setFileList(updatedList);
            onFileChange(updatedList);
        }
    };

    const fileRemove = (file) => {
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);
        onFileChange(updatedList);
    }


    return (
        <div className="popup-overlay" onClick={(e) => {
            if (e.target.classList.contains("popup-overlay")) {
              onClose();
            }
          }}>
            <div className="popup-box">
                <div className="popup-header">
                    <h2>Upload Files</h2>
                    <button className="close-btn" onClick={onClose}>×</button>
                </div>
                <div
                    ref={wrapperRef}
                    className="drop-file-input"
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}
                >
                    <div className="drop-file-input__label">
                        <img src={uploadImg} alt="Upload" />
                        <p>Drag & Drop your files here</p>
                    </div>
                    <input type="file" multiple onChange={onFileDrop} />
                </div>
                {fileList.length > 0 && (
                    <div className="drop-file-preview">
                        <p className="drop-file-preview__title">Ready to upload</p>
                        {fileList.map((item, index) => (
                            <div key={index} className="drop-file-preview__item">
                                <img src={ImageConfig[item.type.split("/")[1]] || ImageConfig["default"]} alt="" />
                                <div className="drop-file-preview__item__info">
                                    <p>{item.name}</p>
                                    <p>{item.size}B</p>
                                </div>
                                <span className="drop-file-preview__item__del" onClick={() => fileRemove(item)}>x</span>
                            </div>
                            
                        ))}
                    </div>
                )}
                <div className="popup-actions">
                    <button className="cancel-btn" onClick={onClose}>Cancel</button>
                    <button className="upload-btn">Upload</button>
                </div>
            </div>
        </div>
    );
};
  

export default DropFileInput;