export const columns = [
    {column_title: 'Business Strategy', points: '45', questions: 
        [{title: 'Scope', question: 'Well-developed, realistic plan laid out for implementing \
                                    the solution to a scoped-down problem area. Integrates the socioeconomic, \
                                    environmental, political, and cultural considerations of the target population.', points: '15',
                                criteria: [{points:4, description: 'Problem is identified, but the scope of the problem selected is vague or excessively broad. \
                                                        Minimal to no consideration of socioeconomic, environmental, political, and cultural needs of the target population'},
                                            {points:8, description: 'Defines a scoped-down problem area with a partially developed implementation plan to address the problem. \
                                                        Some acknowledgement or identification of the target population’s needs but lacks depth in how they are addressed.'},
                                            {points:12, description: 'Well-developed, realistic plan for implementing the solution within a well-scoped problem area. \
                                                                    Integrates consideration of multiple external factors (socioeconomic, environmental, political, and cultural)\
                                                                     but may lack depth in one or two relevant areas.'},
                                            {points:15, description: 'Comprehensive, well-developed solution, tackling a well-scoped problem area, with clear implementation steps.\
                                                             Deep integration of all relevant socioeconomic, environmental, political, and cultural considerations, \
                                                             ensuring relevance to the target population.'}
                                            ]},
        {title: 'Business Operation Model', question: 'The business model can scale well and \
                                                        serve as a model for other efforts. Considers partners \
                                                        and costs associated with scaling.', points: '15',
                                criteria: [{points:4, description: 'Basic business model with a brief mention of scaling. Vague or no mention of costs and partnerships.\
                                                                     Lacks clarity and feasibility. No discussion of potential risks and associated mitigation strategies.'},
                                            {points:8, description: 'Defined business model with a plan for scaling and partnerships. Some consideration of financial sustainability and operational costs but missing key details; feasibility is unclear. Brief mention of potential risks but no mitigation strategies are presented.'},
                                            {points:12, description: 'Strong business model that demonstrates scalability. Demonstrates feasibility, and includes financial planning, partnerships, and operational strategy, though some aspects need further refinement. Some potential risks and mitigation strategies are identified, but explanation lacks sufficient depth.'},
                                            {points:15, description: 'Robust, feasible, and scalable business model with a clear roadmap for growth. Considers financial sustainability, operational costs, and partnerships in detail. Clearly outlines all risks, mitigation strategies, and contingency plans'}
                                            ]},
                                
        {title: 'Impact', question: 'Addresses the needs of the target population and rooted \
                                    in research that identifies the size and scope of the problem.', points: '15',
                                    criteria: [{points:4, description: 'Identifies problem but provides limited research on size or significance. Brief mention of impact but lacks supporting evidence.'},
                                                {points:8, description: 'Compares solution to competitors but lacks depth. \
                                                    Some differentiation, but limited discussion of unique features and how the solution stands out.'},
                                                {points: 12, description: 'Strong research foundation with clear impact strategy for a well-defined problem. Demonstrates potential for long-term benefits with research but lacks completeness in validation or assessment of the solution’s long-term impact.'},   
                                                {points: 15, description: 'Well-researched problem with clear data on solution’s ability to cause an impact. Research is comprehensive with use of case studies, user insights, or market analysis. Considers and presents both direct and indirect impacts, and measurable long-term outcomes.'}
                                                ]}]
    },
    {column_title: 'Technical Implementation', points: '40', questions:
        [
        {title: 'Technical Proficiency', question: 'Solution demonstrated high level of technical skills \
                                                    (e.g. coding, CAD modeling, algorithms).', points: '10'
                                        , criteria: [{points:3, description: 'Concept-stage solution with only an idea, pseudo code, or rough sketches. No functional implementation or inappropriate technology selection for the problem.'},
                                                    {points:5, description: 'Includes some basic structure and technical analysis but focuses only on core functionality. Lacks consideration of sub-processes, has gaps in understanding or critical design errors.\n\n[Software]\nMay function but does not meaningfully contribute to the solution (e.g., chatbot only responds to a single prompt).\n\n[Hardware]\nLacks consideration of feasibility and appropriateness of the hardware components chosen (e.g., unnecessary complexity, poor material selection).'},
                                                    {points: 7, description: 'Developed a functional solution with most key features and working flow. Team can fully explain the code/design. Missing validation testing or key refinements.\n\n[Software]\nGenerally suitable but lacks robustness (e.g., logic gaps, hardcoded values, no error handling).\n\n[Hardware]\nGenerally suitable but lacks optimization for scalability or efficiency.'},   
                                                    {points: 10, description: 'Fully developed prototype demonstrating strong technical execution. Demonstrates functionality and feasibility through validation testing (simulations, scenario analysis, etc.). If a real engineering company had this, they would be ready to turn it into a built prototype. \n\n[Software]\nSoftware is well-integrated, effectively implemented, and scalable (e.g., chatbot handles dynamic interactions with appropriate logic.)\n\n[Hardware]\nHardware is well-suited for the problem, efficiently designed, and optimized for real-world application.'}
                                                    ]},
        {title: 'Feasibility', question: 'Solution is well-designed based on the needs of its end-users \
                                            and functionality for its intended audience. The solution is also \
                                            highly practical and feasible.', points: '10',
                                        criteria: [{points:3, description: 'Rough framework of various phases of product launch and implementation.'},
                                                    {points:5, description: 'Semi-detailed plan of what each phase of launch looks like but the roadmap does not seem realistic or missing critical steps of the process.'},
                                                    {points: 7, description: 'Relatively detailed plan of what each phase of launch looks like but lacks a defined timeline and critical steps (e.g., partnerships, funding sources) for implementing the product in the real-world.'},   
                                                    {points: 10, description: 'Comprehensive plan covering launch, scaling, and major milestones (2-5 years). Includes considerations for partnerships, funding strategy, training, and other steps necessary for real-world implementation.'}
                                        ]},
        {title: 'Scalability', question: 'Technical solution is designed to scale seamlessly and efficiently. \
                                            It can handle increased demand, data, or users without degradation in performance.', points: '10',
                                        criteria:[{points:3, description: 'Surface-level mention of scalability with no long-term vision or planning.'},
                                                {points:5, description: 'Acknowledges scalability concerns but provides limited solutions.\n\n[Software]\nMentions potential performance bottlenecks but provides limited technical strategies for handling higher traffic, larger datasets, or user growth.\n\n[Hardware]\nSome mention of potential production challenges but lack clear research on how to improve efficiency. '},
                                                {points: 7, description: 'Some planning for production scalability, including optimizations for efficiency.\n\n[Software]\nPlans for handling increased demand and efficiency (e.g., cloud integration, load balancing, database efficiency).\n\n[Hardware]\nIncludes potential design modifications for cost reduction or efficiency improvements, but limited discussion on material sourcing or mass production.'},
                                                {points: 10, description: 'Well-defined plan with major milestones (2-5 years) that considers how the technology can be scaled efficiently. Includes potential adaptations to enhance functionality.\n\n[Software]\nConsiders how to optimize performance under high traffic and adapt to broader use cases.\n\n[Hardware]\nConsiders how to manufacture at lower costs over time and adapt to broader production needs.'}
                                        ]},
        {title: 'Innovation', question: 'Solution differentiates itself from others in this problem area by using \
                                        technology and data in a novel way to address the issue.', points: '10',
                                        criteria: [{points:3, description: 'Does not mention existing solutions or provide a description of how their product tackles the problem differently to previous approaches.'},
                                                    {points:5, description: 'Compares solution to competitors where applicable, but lacks depth. Some differentiation, but limited discussion of unique features and how the solution stands out.'},
                                                    {points: 7, description: 'Demonstrates clear technological innovation with detailed comparison to existing solutions where applicable. Focuses on functional improvements rather than market impact.'},   
                                                    {points: 10, description: 'Highly innovative solution with explicit and innovative market differentiation. Includes detailed and convincing market analysis and comparison, showcasing the solution’s strength over existing products. '}
                                        ]}
        ]
    },
    {column_title: 'Presentation', points: '15', questions: 
        [{title: 'Presentation & Delivery', question: 'Presentation slides are organised logically, concisely and clearly.\n \
                                            Presentation tells a compelling story relevant to the problem pitch and the solution \
                                            proposed.\n Presenters have clear and engaging delivery.', points: '10',
                                        criteria: [{points:3, description: 'Slides are disorganized, cluttered, or difficult to follow. The presentation lacks a cohesive structure and does not clearly connect the problem to the solution.\nDelivery is unengaging with frequent hesitations, poor pacing, or a monotone voice. Presenters rely heavily on notes or slides, making minimal eye contact.'},
                                                    {points:5, description: 'Slides have some structure, but key information is either missing or poorly communicated. The presentation has a basic story but lacks flow or engagement.\nDelivery is understandable but inconsistent - presenters may struggle with pacing, clarity, or confidence. Audience connection is weak, with limited eye contact or enthusiasm.'},
                                                    {points: 7, description: 'Well-structured slides that are mostly clear and concise, with a logical flow. The presentation tells a coherent story that connects the problem and solution effectively.\nDelivery is confident and engaging, but there may be occasional lapses in pacing, clarity, or energy. Presenters interact with the audience but may not fully captivate or connect with them.'},
                                                    {points: 10, description: 'Slides are highly organized, visually engaging, and free of unnecessary clutter. The presentation tells a compelling, well-paced story that makes a strong case for the solution.\nDelivery is polished, confident, and dynamic, with strong voice control, enthusiasm, and seamless transitions between teammates. Presenters maintain excellent audience engagement through strong use of eye contact, gestures, and tone variation.'}
                                                ]},
        {title: 'Tech Demo', points: '5', question: 'Well-structured and technically detailed demo, that effectively showcases implementation, testing, and innovation, with well-developed functionality and robustness.',
                                                criteria: [{points:1, description: 'No demo or minimal effort. Basic explanation without technical showcase.'},
                                                            {points:2, description: 'Some demonstration of technical aspects, but lacks depth or clarity. No prototype or proper testing.'},
                                                            {points:3, description: 'Functional but basic demo with a working prototype or simulation. Some depth in the explanation but lacks refinement or depth in functionality offered.'},
                                                            {points:5, description: 'Strong, well-structured, and technically detailed demo with key technical features that effectively showcase implementation, testing, and innovation. Demonstrates well-developed functionality, and robustness.'}]},
        {title: 'General Comments', type:'comment'}]
    }
];

export const questions = [
    columns.flatMap(columns => columns.questions)
]

export const comments = [
    // {title:'comment 1'},
    // {title:'comment 2'},
    // {title:'comment 3'}
];

export default {columns, questions, comments};