/** @format */

// This file is is the nav bar of the dashboard

import React from 'react';
import { Container, Col, Navbar, Nav, Row, Image } from 'react-bootstrap';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCog,
    faFolder,
    faHome,
    faClipboardCheck,
    faUsers,
    faUserFriends,
} from '@fortawesome/free-solid-svg-icons';
import { AuthUserContext, withAuthorization } from '../../session';
import Header from '../../components/Header';
import LogOutButton from '../logout';
import { NavLink } from 'react-router-dom';
import {
    ROUTES,
    SOCIAL_MEDIA_IMAGES,
    PERM_LEVELS,
} from '../../util';


const JudgeNav = () => {
    return (
        <AuthUserContext.Consumer>
            {({ authUser, updateAuthUser }) => (
                <div>
                    <div className="gs-dashboard-nav mt-auto mb-auto bg-light-blue p-0 d-none d-lg-block">
                        <Header />
                        <Nav variant="pills" className="flex-column pt-5">
                            {       
                                <>
                                    <NavLink
                                        className="nav-link"
                                        activeClassName="active"
                                        to={ROUTES.JUDGE_DASHBOARD_HOME}
                                    >
                                        <FontAwesomeIcon icon={faHome} /> Home
                                    </NavLink>
                                </>
                            }
          
                        </Nav>

                        <div className="gs-logout-button">
                            <LogOutButton />
                        </div>
                    </div>

                    <div className="gs-dashboard-nav-mobile d-lg-none bg-grey">
                        <Navbar collapseOnSelect expand="lg">
                            <Row className="gs-navbar-logo">
                                <Image
                                    src={SOCIAL_MEDIA_IMAGES.logo_short}
                                    className="pr-2 pl-3 pt-4 pb-4 mt-auto mb-auto"
                                    fluid
                                />
                                <h4 className="text-black mt-auto mb-auto">
                                    <b>Global</b> Spark
                                </h4>
                            </Row>
                            <Navbar.Toggle />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="mr-auto">
                                    <NavLink
                                        className="nav-link"
                                        activeClassName="active"
                                        to={ROUTES.JUDGE_DASHBOARD_HOME}
                                    >
                                        <FontAwesomeIcon icon={faHome} /> Home
                                    </NavLink>
                                    <div className="gs-logout-button pill">
                                        <LogOutButton />
                                    </div>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                </div>
            )}
        </AuthUserContext.Consumer>
    );
};

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition))(JudgeNav);
