/** @format */

import React, { Component } from 'react';
import {Container, Button, Form } from 'react-bootstrap';
import { compose } from 'recompose';

import { withAPI } from '../../../api';
import { AuthUserContext, withAuthorization } from '../../../session';
import { PERM_LEVELS } from '../../../util';
import PopupRegistration from './popup';
import { SOCIAL_MEDIA_IMAGES } from '../../../util';
import JudgeNav from '../';
import {SearchableDropdown} from './searchableDropdown';
import '../style.css';
import JudgeTeamsTable from './judgeTeamsTable';
import PasswordPopup from './passwordPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser} from '@fortawesome/free-solid-svg-icons'; 

const HomePage = () => {
    return (
        <>
          <JudgeNav/>
            <div className="gs-dashboard-content">
                <Container className="gs-home-container">
                    <JudgeHome />
                </Container>
            </div>
        </>
    );
};

class JudgeHomeBase extends Component {
    componentDidMount() {
        console.log('welcome judge!');
    }

    state = {
      isPopupRendered: true,
      judgeName: 'default',
      passwordRequired: false,
      displaySubmittedRubrics:false,
    };
  
    closeNamePopup = () => {
      this.setState({ isPopupRendered: false });
    };

    closePasswordPopup = () =>{
        this.setState({passwordRequired: false});
    };

    setnewJudge = (newJudgeName) =>{
        this.setState({judgeName:newJudgeName});
        console.log(this.state.judgeName);
        this.setState({passwordRequired:true}, ()=>{
            this.setState({isPopupRendered:false});
        });
    };

    displayJudgeNamePopup=()=>{
        this.setState({isPopupRendered: true});
    }

    setDisplayCancelButton=()=>{
        this.setState({displayCancelButton:true});
    }

    handleSubmittedRubrics=()=>{
        console.log(this.state.displaySubmittedRubrics)
        this.setState(prevState => ({
            displaySubmittedRubrics: !prevState.displaySubmittedRubrics
        }));
    }

    
    render() {
        const { isPopupRendered,judgeName, passwordRequired, displayCancelButton, displaySubmittedRubrics } = this.state;
       
        return (
            <>
            {isPopupRendered && <PopupRegistration renderHomePage={this.closeNamePopup} judgeNameSetter = {this.setnewJudge}  displayCancelButton={displayCancelButton}/>}
            {passwordRequired && <PasswordPopup renderHomePage={this.closePasswordPopup} setDisplayCancelButton={this.setDisplayCancelButton} renderJudgeNamePopup={this.displayJudgeNamePopup}judgeName = {judgeName}/>}
            <AuthUserContext.Consumer>
                {({ authUser, updateAuthUser }) => (
                    <>
                        <div className="mainContainer judging-main-container">
                            <div className="button-container profile-button"
                                onClick={()=>{
                                    this.setState({isPopupRendered: true})
                                }}
                                >
                                <span>{judgeName}</span>
                                
                                <FontAwesomeIcon icon={faUser} 
                                    className="profile-icon"
                                />
                            </div>

                            <div>
                                <JudgeTeamsTable judgeName = {judgeName} displaySubmittedRubrics={displaySubmittedRubrics} handleSubmittedRubrics={this.handleSubmittedRubrics}></JudgeTeamsTable>
                            </div>

                        </div>
                    </>
                )}
            </AuthUserContext.Consumer>
            
            </>
        );
    }
}

const condition = (authUser) => !!authUser && (authUser.perm_level === PERM_LEVELS.JUDGE || authUser.perm_level === PERM_LEVELS); 

const JudgeHome = compose(withAPI, withAuthorization(condition))(JudgeHomeBase);

export default HomePage;

