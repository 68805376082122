/**
 * Features:
 * search bar
 * view all applicants
 * view and edit a given applicants application status (TODO: get typeform response for all data including resume)
 * view all teams
 * view and edit specific team (link to typeform responses)
 *
 * @format
 */

//TODO: make teams page

import React, { useState, useEffect } from 'react';
import { Button, Form, Collapse, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withAPI } from '../../api';
import { PERM_LEVELS, APPLICATION_STATUS, ROUTES } from '../../util';
import { withAuthorization } from '../../session';
import DashboardNav from '../dashboard';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, {
    CSVExport,
    Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import Papa from 'papaparse';
import JudgeSubmissionView from '../../components/JudgeSubmissionView';

const { SearchBar } = Search;

const { ExportCSVButton } = CSVExport;

const AdminHackerInfoPage = (props) => {
    const { api } = props;
    const [apps, setApps] = useState([]);
    const [teams, setTeams] = useState([]);
    const [view, setView] = useState('Applications'); // Views: Applications / Teams
    const [error, setError] = useState(null);
    // const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [showTools, setShowTools] = useState(false); // for the tools button
    const currentYear = new Date().getFullYear();
    const [showErrors, setShowErrors] = useState(true); // for the errors button

    // For team mentor
    const [showMentorModal, setShowMentorModal] = useState(false);
    const [newMentor, setNewMentor] = useState("");
    const [selectedTeam, setSelectedTeam] = useState(null);


    // Handle opening the modal
    const handleShowMentorModal = (row) => {
        setSelectedTeam(row);
        setNewMentor(row.mentor || ""); // Pre-fill with existing mentor name
        setShowMentorModal(true);
    };

    // Handle saving the updated mentor
    const handleSaveMentor = () => {
        const mentorRegex = /^[^,]+,[^,]+,[^,]+$/;
        if(mentorRegex.test(newMentor)){
            if (selectedTeam) {
                // Update local state
                setTeams((prevTeams) =>
                    prevTeams.map((team) =>
                        team.id === selectedTeam.id ? { ...team, mentor: newMentor } : team
                    )
                );
                            
                // TODO: Call API to update mentor in the database
                api.updateTeamMentor(selectedTeam.id, newMentor)
                .catch((error) => {
                    setError(error.errorMsg);
                });
    
                setShowMentorModal(false);
            }
        }
    };

    // For the team change functionality
    const [user, setUser] = useState('');
    const [dest_team, setdest_team] = useState('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const getApps = () => {
            let appsData = [];
            api.getApplications(undefined)
                .then((res) => {
                    const Applicants = res.Applicants;
                    for (let Applicant of Applicants) {
                        const applicant = {};
                        applicant['email'] = Applicant['email'];
                        applicant['status'] = statusToText(Applicant['acceptance_status']);
                        applicant['name'] = `${Applicant['first_name']} ${Applicant['last_name']}`;
                        applicant['team_name'] = Applicant.team ? Applicant.team.team_name : '';
                        applicant['team_code'] = Applicant.team ? Applicant.team.team_code : '';
                        applicant['HTG_GIVEN_id'] = Applicant['application_id'];
                        // Applicant
                        applicant['user_role'] = Applicant['user_role'];
                        applicant['id'] = Applicant['_id'];
                        applicant['dietary_restrictions'] = Applicant['diet_restriction'];
                        applicant['location'] = Applicant['location'];
                        appsData.push(applicant);
                    }
                    setApps(appsData);
                })
                .catch((error) => {
                    setError(error.errorMsg);
                });
        };
        const getTeams = () => {
            let teamsData = [];
            api.getTeams()
                .then((res) => {
                    console.log(res);
                    const Teams = res.Teams;
                    for (let Team of Teams) {
                        const team = {};
                        team['team_name'] = Team['team_name'];
                        team['team_code'] = Team['team_code'];
                        team['theme'] = themeToText(Team['theme']);
                        team['location'] = Team['team_location'];
                        // team['pitch_deck_link'] = Team['pitch_deck_link'];
                        // team['submission_repo_link'] = Team['submission_repo_link'];
                        // team['prerecording_link'] = Team['prerecording_link'];
                        team['team_members'] = Team.team_members.map((member) =>
                            `${member.first_name} ${member.last_name}`
                        ).join(', ');
                        team['id'] = Team['_id'];
                        team['mentor'] = Team['mentor'];
                        team['panel_selection'] = Team['panel_selection'] ? Team['panel_selection'] : "No Selection";
                        team['passport_points'] = Team['passport_points'];
                        team['submitted_files'] = Team['submission_drive_link'] ? "true" : "false";
                        teamsData.push(team);
                    }
                    setTeams(teamsData);
                })
                .catch((error) => {
                    setError(error.errorMsg);
                });
        };
        getApps();
        getTeams();
    }, [api, currentYear]);

    // const handleYearChange = (e) => {
    //     setCurrentYear(e.currentTarget.value);
    // };

    const statusToText = (status) => {
        if (status === APPLICATION_STATUS.SUBMITTED) return 'Under Review';
        if (status === APPLICATION_STATUS.NO_APP) return 'Not Received';
        if (status === APPLICATION_STATUS.ACCEPTED_NEED_RSVP)
            return 'Require RSVP';
        if (status === APPLICATION_STATUS.ACCEPTED_RSVP_YES) return 'Accepted';
        if (status === APPLICATION_STATUS.ACCEPTED_RSVP_NO) return "RSVP'ed No";
        if (status === APPLICATION_STATUS.WAITLISTED) return 'Waitlisted';
        if (status === APPLICATION_STATUS.ACCEPTED_OFFER_EXPIRED)
            return 'Offer Expired';
        if (status === APPLICATION_STATUS.REJECTED) return 'Rejected';
        return 'Unknown';
    };

    function statusFormatter(cell, row) {
        if (
            row.status === statusToText(APPLICATION_STATUS.ACCEPTED_RSVP_YES) ||
            row.status === statusToText(APPLICATION_STATUS.ACCEPTED_NEED_RSVP)
        ) {
            return <span className="text-success">{cell}</span>;
        } else if (
            row.status === statusToText(APPLICATION_STATUS.SUBMITTED) ||
            row.status === statusToText(APPLICATION_STATUS.WAITLISTED)
        ) {
            return <span className="text-warning">{cell}</span>;
        } else {
            return <span className="text-danger">{cell}</span>;
        }
    }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total" style={{marginLeft:"15px"}}>
            Showing {from} to {to} of {size} Results
        </span>
    );

    const appRowEvents = {
        onClick: (e, row, rowIndex) => {
            props.history.push(`${ROUTES.ADMIN_DASHBOARD_HACKER_INFO}/${row.id}`);
        },
    };

    const appColumns = [
        { text: 'Name', dataField: 'name' },
        { text: 'Status', dataField: 'status', formatter: statusFormatter },
        { text: 'Email', dataField: 'email' },
        { text: 'Team Name', dataField: 'team_name', hidden: true },
        { text: 'Team Code', dataField: 'team_code', hidden: true },
        { text: 'ID', dataField: 'HTG_GIVEN_id' },
        { text: 'Role', dataField: 'user_role' },
        { text: 'Dietary Restrictions', dataField: 'dietary_restrictions' },
        { text: 'Location', dataField: 'location'},
    ];

    const appOptions = {
        paginationSize: 4,
        pageStartIndex: 0,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {text: '20',value: 20,},
            {text: '50', value: 50,},
            {text: '100', value: 100,},
            {text: '500', value: 500,},
            {text: 'All', value: apps.length,},
        ],
    };

    const teamRowEvents = {
        onClick: (e, row, rowIndex) => {
            setNewMentor(row.mentor)
            handleShowMentorModal(row)
        },
    };

    // Map to new themes - temporary
    const themeToText = (theme) => {
        if (theme === 'Economic Advancement') return 'Economic Empowerment';
        if (theme === 'Sustainable Societies')
            return 'Sustainable Infrastructure';
        if (theme === 'Health & Humanity') return 'Health & Humanity';
        return 'Unselected';
    };

    const teamColumns = [
        { text: 'Team', dataField: 'team_name' },
        { text: 'Code', dataField: 'team_code', hidden: true },
        { text: 'Theme', dataField: 'theme' },
        { text: 'Members', dataField: 'team_members' },
        { text: 'Location', dataField: 'location' },
        { text: 'Mentor', dataField: 'mentor' },
        { text: 'HW/SW Panel Selection', dataField: 'panel_selection', hidden: true },
        {text: 'Passport Points', dataField: 'passport_points', hidden: true},
        {text: 'Submitted Files?', dataField: 'submitted_files', hidden: true}
    ];

    const teamOptions = {
        paginationSize: 4,
        pageStartIndex: 0,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {text: '20',value: 20,},
            {text: '50',value: 50,},
            {text: 'All',value: apps.length,},
        ],
    };

    // Get a random team code and push it as a popup
    const handleGetTeamCode = () => {
        api.generateTeamCode()
            .then((res) => {
                window.alert(res.code);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //this is part of the file upload
    const [file, setFile] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    
    const [locationErrors, setLocationErrors] = useState(null);             // list of people who have no team because the team location is not the same as their location
    const [otherErrors, setOtherErrors] = useState(null);                   // list of errors that occurred with mongoDB, should not be the fault of the user
    const [limitErrors, setLimitErrors] = useState(null);                   // list of teams that have more than 4 people on their team
    const [locationErrorsWTeam, setLocationErrorsWTeam] = useState(null);   // list of people who are in a team with the wrong location (should rarely occur, and might be useless)

    const [locationErrorsView, setViewLocationErrors] = useState(false);
    const [otherErrorsView, setViewOtherErrors] = useState(false);
    const [limitErrorsView, setViewLimitErrors] = useState(false);

    let errorKey = 0; //this is used for keys when displaying error items in the list

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
      };

    const validateHeader = (arr) => {
        return (
          arr[0] == "Name" &&
          arr[1] == "Status" &&
          arr[2] == "Email" &&
          arr[3] == "Team Code" &&
          arr[4] == "Hub" &&
          arr[5] == "ID"
        );
    };
    // documentation:
    // https://docs.google.com/document/d/1Wozq6U1SV2kGIbbAt6VXPnKshKbEU_fC7sT2cy7Z6OY/edit
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!file) {
            alert('Please select a file first!');
            return;
        }

        var result = window.confirm(
            "Have you downloaded the current Teams CSV, and are you sure you want to update the team formations with the chosen csv? "
        );
        if(result){
            Papa.parse(file, {
                complete: async (results) => {
                    const csvData = results.data;
                    setLoading(true);
                    if(validateHeader(csvData[0])){
                        Papa.parse(file, {
                            header: true,
                            complete: async (results) => {
                                const newData = results.data;
                                api.updateTeamFormation(newData)
                                .then((res) => {
                                    console.log(res.status);
                                    setLocationErrors(res.locationErrors);
                                    setLimitErrors(res.limitErrors);
                                    setOtherErrors(res.otherErrors);
                                    setLocationErrorsWTeam(res.locationErrorsWTeam);
                                    if(res.errCount !== 0){
                                        setErrors(true);
                                        alert('Errors are listed below! Consult the DnA team on how to fix.');
                                    } else {
                                        setErrors(false);
                                        alert('Finished updating the team formations! There were no errors. Reload the page to see the results.');
                                    }
                                    setLoading(false);
                                })
                                .catch((error) => {
                                    console.log(error);
                                    alert(error);
                                    setLoading(false);
                                });
                            }
                        })
                    } else {
                        alert('The format of the .csv is wrong! Please follow the format of team_formation.csv');
                        setLoading(false);
                    }
                },
            });
        };
    }

    //passport point csv file upload
    const [ppFile, setppFile] = useState(null);
    const [isppFileLoading, setppFileLoading] = useState(false);

    const handleppFileChange = (e) => {
        setppFile(e.target.files[0]);
      };
    
    const validateppCSVHeader = (arr) => {
        return (
          arr[0] == "team_code" &&
          arr[1] == "points" 
        );
    };

    const handlePassportPointSubmit = async (event) => {
        event.preventDefault();
        if (!ppFile) {
            alert('Please select a file first!');
            return;
        }

        var result = window.confirm(
            "Confirm wanting to update passport points? "
        );
        if(result){
            Papa.parse(ppFile, {
                complete: async (results) => {
                    const csvData = results.data;
                    setppFileLoading(true);
                    if(validateppCSVHeader(csvData[0])){
                        Papa.parse(ppFile, {
                            header: true,
                            complete: async (results) => {
                                const newData = results.data;
                                api.updatePassportPoints(newData)
                                .then((res) => {
                                    if (res.status === 200) {
                                        window.alert("success") // Temporary - add proper logging in future
                                    }
                                }).catch((error) => {
                                    console.log(error);
                                    alert(error);
                                });
                                setppFileLoading(false);
                            }
                        })
                    } else {
                        alert('The format of the .csv is wrong! Please have headers team_code,points');
                    }
                },
            });
        };
    }


    //passport point csv file upload
    const [mentorFile, setmentorFile] = useState(null);
    const [ismentorFileLoading, setmentorFileLoading] = useState(false);

    const handlementorFileChange = (e) => {
        setmentorFile(e.target.files[0]);
      };
    
    const validatementorCSVHeader = (arr) => {
        return (
          arr[0] == "team_code" &&
          arr[1] == "mentor_name" &&
          arr[2] == "mentor_email" &&
          arr[3] == "time" 
        );
    };

    const handlementorSubmit = async (event) => {
        event.preventDefault();
        if (!mentorFile) {
            alert('Please select a file first!');
            return;
        }

        var result = window.confirm(
            "Confirm wanting to update mentors? "
        );
        if(result){
            Papa.parse(mentorFile, {
                complete: async (results) => {
                    const csvData = results.data;
                    setmentorFileLoading(true);
                    if(validatementorCSVHeader(csvData[0])){
                        Papa.parse(mentorFile, {
                            header: true,
                            complete: async (results) => {
                                const newData = results.data;
                                
                                api.updateTeamMentor(newData)
                                .then((res) => {
                                    if (res.status === 200) {
                                        window.alert("success") // Temporary - add proper logging in future
                                    }
                                }).catch((error) => {
                                    console.log(error);
                                    alert(error);
                                });
                                setmentorFileLoading(false);
                                
                            }
                        })
                    } else {
                        alert('The format of the .csv is wrong! Please have headers team_code,points');
                    }
                },
            });
        };
    }


    const handleDeleteTesters = () => {

        var result = window.confirm(
            "Confirm wanting to delete all tester accounts? "
        );
        if(result){
            api.deleteAllTesterUsers()
            .then((res) => {
                if (res.status === 200) {
                    window.alert(`Success! deleted ${res.deletedCount} users`) 
                }
            }).catch((error) => {
                console.log(error);
                alert(error);
            });
        }
    }

    return (
        <>
            <DashboardNav />
            <div className="gs-dashboard-content">
                <div className="d-flex justify-content-end">
                    <Button
                        className={
                            view === 'Applications' ? 'mb-3 mr-3' : 'mb-3 mr-3 btn-secondary'
                        }
                        onClick={() => setView('Applications')}
                    >
                        Applications
                    </Button>
                    <Button
                        className={
                            view === 'Teams' ? 'mb-3 mr-3' : 'mb-3 mr-3 btn-secondary'
                        }
                        onClick={() => setView('Teams')}
                    >
                        Teams
                    </Button>
                </div>
                {error && <p className="text-red"> {error} </p>}
                {view === 'Applications' && (
                    <div className="gs-applications-container w-100 p-3" style={{ wordBreak: 'break-word'}}>
                        <ToolkitProvider
                            keyField="id"
                            data={apps}
                            columns={appColumns}
                            search
                            exportCSV
                        >
                            {(props) => (
                                <div>
                                    <ExportCSVButton
                                        {...props.csvProps}
                                        style={{ color: 'white' }}
                                    >
                                        Download CSV
                                    </ExportCSVButton>
                                    <br />
                                    <br />
                                    <Button onClick={handleDeleteTesters}>Delete all testers</Button>
                                    <br />
                                    <br />
                                    {/* <YearSelectDropDown handleOnChange={handleYearChange} /> */}
                                    <br />
                                    <SearchBar
                                        {...props.searchProps}
                                        placeholder="Search Anything"
                                    />
                                    <br />
                                    <BootstrapTable
                                        {...props.baseProps}
                                        pagination={paginationFactory(
                                            appOptions
                                        )}
                                        rowEvents={appRowEvents}
                                        hover
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    </div>
                )}
                {view === 'Teams' && (
                    <div className="gs-applications-container w-100 p-3" style = {{wordBreak: 'break-all'}}>
                        <ToolkitProvider
                            keyField="id"
                            data={teams}
                            columns={teamColumns}
                            search
                            exportCSV
                        >
                            {(props) => (
                                
                                <div>
                                    <u onClick={() => setShowTools(!showTools)}>Press here to {showTools ? 'hide' : 'show'} admin tools</u><span onClick={() => setShowTools(!showTools)}> {showTools ? '▲' : '▼'}</span>
                                    {/* <Button className={showTools ? 'btn-primary' : 'btn-secondary'}
                                        onClick={() => setShowTools(!showTools)}
                                        aria-expanded={showTools}>
                                        Utilities
                                    </Button> */}
                                    {!showTools && (<br/>) }
                                    <Collapse in={showTools}>
                                        <div>
                                        <div className='utilities-section'>
                                            <form onSubmit={handleSubmit}>
                                                <input type="file" onChange={handleFileChange} accept=".csv" />
                                                <button type="submit" disabled = {isLoading}>{isLoading ? "Updating..." : "Submit Team Formation CSV"}</button>
                                            </form>
                                        </div>
                                        {errors && (
                                            <div className='utilities-section'>
                                                <u onClick={() => setShowErrors(!showErrors)}>Press here to {showErrors ? 'hide' : 'show'} error tools</u><span onClick={() => setShowErrors(!showErrors)}> {showErrors ? '▲' : '▼'}</span>
                                                <div className='margin8px'>Reloading the page will get rid of the error log, but will update the page to see the changes.</div>
                                                <Collapse in={showErrors}><div>
                                                    {(locationErrors || locationErrorsWTeam) && (
                                                        <span className='margin8px'>
                                                            <Button onClick={() => setViewLocationErrors(!locationErrorsView)}>{locationErrorsView ? "Hide" : "Show"} Location Errors</Button>
                                                        </span>
                                                    )}
                                                    {limitErrors && (
                                                        <span className='margin8px'>
                                                            <Button onClick={() => setViewLimitErrors(!limitErrorsView)}>{limitErrorsView ? "Hide" : "Show"} Team Limit Errors</Button>
                                                        </span>
                                                    )}
                                                    {otherErrors && (
                                                        <span className='margin8px'>
                                                            <Button onClick={() => setViewOtherErrors(!otherErrorsView)}>{otherErrorsView ? "Hide" : "Show"} Other Errors</Button>
                                                        </span>
                                                    )}
                                                    <br/>
                                                    <div className='error-output-box'>
                                                        {locationErrorsView && (
                                                            <div>   
                                                                {locationErrors && (<div>
                                                                    Hackers whos location did not match their Team's location, these hackers don't have a team:
                                                                    <ul>
                                                                        {locationErrors.map((error) => (
                                                                        <li key={++errorKey} className="text-danger">
                                                                            {error}
                                                                        </li>
                                                                        ))}
                                                                    </ul> 
                                                                </div>)}
                                                                {locationErrorsWTeam && (<div>
                                                                Hackers whos location did not match their Team's location, these hackers ARE in a team:
                                                                <ul>
                                                                    {locationErrorsWTeam.map((error) => (
                                                                    <li key={++errorKey} className="text-danger">
                                                                        {error}
                                                                    </li>
                                                                    ))}
                                                                </ul>
                                                                </div>)}
                                                            </div>
                                                        )}
                                                        {limitErrorsView && (
                                                            <div>    
                                                                Teams that have over 4 people:
                                                                <ul>
                                                                    {limitErrors.map((error) => (
                                                                    <li key={++errorKey} className="text-danger">
                                                                        {error}
                                                                    </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                        {otherErrorsView && (
                                                            <div>    
                                                                Other Errors:
                                                                <ul>
                                                                    {otherErrors.map((error) => (
                                                                    <li key={++errorKey} className="text-danger">
                                                                        {error}
                                                                    </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div></Collapse>
                                            </div>
                                        )}
                                        {errors === false &&(
                                            <div className='text-success utilities-section'>
                                                Success! There were no errors. Reload the page to see your changes.
                                            </div>
                                        )}
                                        <div className='utilities-section'>
                                            <ExportCSVButton
                                                {...props.csvProps}
                                                style={{ color: 'white' }}
                                            >
                                                Download Teams CSV
                                            </ExportCSVButton>
                                            {" "}
                                            {/* <Button onClick={handleGetTeamCode}>
                                                Generate a Unique Team Code
                                            </Button> */}
                                        </div>
                                        <div className='utilities-section'>
                                            <form onSubmit={handlePassportPointSubmit}>
                                                <input type="file" onChange={handleppFileChange} accept=".csv" />
                                                <button type="submit" disabled = {isppFileLoading}>{isppFileLoading ? "Updating..." : "Submit Passport Point CSV"}</button>
                                            </form>
                                            <form onSubmit={handlementorSubmit}>
                                                <input type="file" onChange={handlementorFileChange} accept=".csv" />
                                                <button type="submit" disabled = {ismentorFileLoading}>{ismentorFileLoading ? "Updating..." : "Submit Mentor CSV"}</button>
                                            </form>
                                        </div>
                                    </div>
                                    </Collapse>
                                    <SearchBar
                                        {...props.searchProps}
                                        placeholder="Search Anything"
                                    />
                                    <br />
                                    <BootstrapTable
                                        {...props.baseProps}
                                        pagination={paginationFactory(teamOptions)}
                                        rowEvents={teamRowEvents}
                                        hover
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                        <Modal show={showMentorModal} onHide={() => setShowMentorModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Mentor for {selectedTeam?.team_name}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group>
                                        <Form.Label>New Mentor: Name,email,time</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={newMentor}
                                            onChange={(e) => setNewMentor(e.target.value)}
                                        />
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowMentorModal(false)}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleSaveMentor}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                            <JudgeSubmissionView page={"admin"} onClose={() => setShowMentorModal(false)} api={api} teamCode={selectedTeam?.team_code}/>
                        </Modal>
                        
                    </div>
                )}
            </div>
        </>
    );
};

const condition = (authUser) =>
    !!authUser && authUser.perm_level === PERM_LEVELS.ADMIN;
export default compose(
    withRouter,
    withAPI,
    withAuthorization(condition)
)(AdminHackerInfoPage);


    // Legacy code
    // Temporary to handle Team formation from the CSV, housed and uploaded in the backend
    // const handleBackendCSVTeamFormation = () => {
    //     var result = window.confirm(
    //         "Are you sure you want to proceed? Note that currently, the CSV must be populated from the backend. This will change everyone's teams!"
    //     );
    //     if (result) {
    //         api.backendTeamFormation()
    //             .then((res) => {
    //                 console.log(res.status);
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //             });
    //     }
    // };

    // const handleTeamChanger = () => {
    //     var result = window.confirm(
    //         'Are you sure you want to proceed? Changing ' +
    //         user +
    //         ' to team code ' +
    //         dest_team
    //     );
    //     if (result) {
    //         api.change_user_team(user, dest_team)
    //             .then((res) => {
    //                 console.log(res.status);
    //                 if (res.status === 200) {
    //                     window.alert('Success!');
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //             });
    //     }
    // };

    // const handleuserEmailChange = (event) => {
    //     setUser(event.target.value);
    // };

    // const handleDestTeamChange = (event) => {
    //     setdest_team(event.target.value);
    // };