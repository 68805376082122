/** @format */

import React, {useRef} from 'react';
import { withRouter } from 'react-router';
import { Container, Card, Accordion, Image, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { compose } from 'recompose';

import { withAPI } from '../../../api';
import { PAGES_IMAGES } from '../../../util';
import Footer from '../../../components/Footer';
import DashboardNav from '..';
import { withAuthorization } from '../../../session';

const HackersPage = (props) => {
    return (
        <>
            <DashboardNav />
            <div className="gs-dashboard-content">
                <Hackers {...props} />
                <div className="pt-4">
                    <Footer />
                </div>
            </div>
        </>
    );
};

const InfoPair = ({ label, value, is_link, is_email }) => {
    return (
        <>
            <div className="display-flex" style={{gap:"5px", flexWrap:"wrap"}}>
                <span className="font-weight-bold">{label}</span>
                {is_link ? (
                    <a
                        href={is_email ? `mailto:${value}` : value}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hacker-link"
                        style={{wordBreak:"break-word"}}
                    >
                        {value}
                    </a>
                ) : (
                    <span style={{wordBreak:"break-word"}}>{value}</span>
                )}
            </div>
        </>
    );
};

const Hacker = ({
    timezone,
    first_name,
    last_name,
    email,
    location,
    social_url,
    university,
    user_role,
    team,
    team_name,
    team_members,
}) => {
    const [accordionOpen, setAccordionOpen] = useState(false);

    const handleAccordionToggle = () => {
        setAccordionOpen(!accordionOpen);
    };

    return (
        <Container className="hackerinfo-container">
            <Accordion className="bg-white accordion-border">
                <Card className="gs-hacker-card">
                    <Card.Header>
                        <Accordion.Toggle
                            as={'p'}
                            eventKey="0"
                            className="accordion-toggle"
                            onClick={handleAccordionToggle}
                        >
                            <Image
                                src={PAGES_IMAGES.hacker_default}
                                fluid
                                className="gs-hacker-image mr-3"
                            />
                            <Col md={3} className="hacker_name mt-3">
                                <span>
                                    {first_name} {last_name}
                                </span>
                            </Col>
                            <Col md={3} className="display-flex">
                                <span className="mt-3">
                                    {team ? team_name : 'No team'}
                                </span>
                                {team && (
                                    <div className="display-flex mt-3">
                                        <span>&nbsp;(</span>
                                        <span className="purple-colour">
                                            {team_members.length}/4
                                        </span>
                                        <span>)</span>
                                    </div>
                                )}
                            </Col>
                            <Col md={3}>
                                <span className="mt-3">{user_role}</span>
                            </Col>
                            <div className="circle_button">
                                <Image
                                    src={PAGES_IMAGES.arrow_flat}
                                    fluid
                                    className={`mx-auto d-block ${
                                        accordionOpen
                                            ? 'hacker_arrow_rotate'
                                            : 'hacker_arrow'
                                    }`}
                                ></Image>
                            </div>
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <React.Fragment>
                                <div className="display-flex pl-5 pr-5 justify-content-between">
                                    <div className="info-pair-group">
                                        <InfoPair
                                            label="University:"
                                            value={university}
                                        />
                                        <InfoPair
                                            label="Time Zone:"
                                            value={timezone}
                                        />
                                    </div>
                                    <div className="info-pair-group">
                                        <InfoPair
                                            label="Role:"
                                            value={user_role}
                                        />
                                        <InfoPair
                                            label="Hub:"
                                            value={location}
                                        />
                                    </div>
                                    <div className="info-pair-group">
                                        <InfoPair
                                            label="Contact Email:"
                                            value={email}
                                            is_link
                                            is_email
                                        />
                                        <InfoPair
                                            label="Social:"
                                            value={social_url}
                                            is_link
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </Container>
    );
};

const Hackers = (props) => {
    const [apps, setApps] = useState([]);
    const [error, setError] = useState(null);
    const [sortBy, setSortBy] = useState(null);
    const [filterBy, setFilterBy] = useState(null);

    useEffect(() => {
        const getApps = () => {
            let appsData = [];
            props.api
                .getUsers()
                .then((res) => {
                    const Applicants = res.Users;
                    for (let Applicant of Applicants) {
                        const appStatus = Applicant['acceptance_status'];
                        if (appStatus === 'ACCEPTED_RSVP_YES') {
                            const applicant = {};
                            applicant['timezone'] = Applicant['timezone'] ?? '';
                            applicant['first_name'] =
                                Applicant['first_name'] ?? '';
                            applicant['last_name'] =
                                Applicant['last_name'] ?? '';
                            applicant['email'] = Applicant['email'] ?? '';
                            applicant['location'] = Applicant['location'] ?? '';
                            applicant['social_url'] =
                                Applicant['social_url'] ?? '';
                            applicant['university'] =
                                Applicant['university'] ?? '';
                            applicant['user_role'] =
                                Applicant['user_role'] ?? '';
                            applicant['team'] = Applicant['team'] ?? '';
                            applicant['team_name'] = Applicant.team
                                ? Applicant.team.team_name
                                : '';
                            applicant['team_members'] = Applicant.team
                                ? Applicant.team.team_members
                                : '';
                            appsData.push(applicant);
                        }
                    }
                    console.log(appsData.length);
                    // Sort the apps based on the selected sorting option
                    if (sortBy === 'alphabetically') {
                        appsData.sort((a, b) =>
                            a.first_name.localeCompare(b.first_name)
                        );
                    } else if (sortBy === 'team') {
                        // Sort by team membership first, then alphabetically within each group
                        appsData.sort((a, b) => {
                            if (a.team && b.team) {
                                // If both are in a team, sort alphabetically within the team
                                if (a.team_name === b.team_name) {
                                    return a.first_name.localeCompare(
                                        b.first_name
                                    );
                                } else {
                                    return a.team_name.localeCompare(
                                        b.team_name
                                    );
                                }
                            } else if (a.team && !b.team) {
                                return -1;
                            } else if (!a.team && b.team) {
                                return 1;
                            }
                            return a.first_name.localeCompare(b.first_name);
                        });
                    } else if (sortBy === 'toronto') {
                        appsData.sort((a, b) => {
                            if (
                                a.location === 'Toronto' &&
                                b.location !== 'Toronto'
                            ) {
                                return -1;
                            } else if (
                                a.location !== 'Toronto' &&
                                b.location === 'Toronto'
                            ) {
                                return 1;
                            }
                            return a.first_name.localeCompare(b.first_name);
                        });
                    } else if (sortBy === 'london') {
                        appsData.sort((a, b) => {
                            if (
                                a.location === 'London' &&
                                b.location !== 'London'
                            ) {
                                return -1;
                            } else if (
                                a.location !== 'London' &&
                                b.location === 'London'
                            ) {
                                return 1;
                            }
                            return a.first_name.localeCompare(b.first_name);
                        });
                    } else if (sortBy === 'online_est') {
                        appsData.sort((a, b) => {
                            if (
                                a.location === 'OnlineEST' &&
                                b.location !== 'OnlineEST'
                            ) {
                                return -1;
                            } else if (
                                a.location !== 'OnlineEST' &&
                                b.location === 'OnlineEST'
                            ) {
                                return 1;
                            }
                            return a.first_name.localeCompare(b.first_name);
                        });
                    } else if (sortBy === 'online_gmt') {
                        appsData.sort((a, b) => {
                            if (
                                a.location === 'OnlineGMT' &&
                                b.location !== 'OnlineGMT'
                            ) {
                                return -1;
                            } else if (
                                a.location !== 'OnlineGMT' &&
                                b.location === 'OnlineGMT'
                            ) {
                                return 1;
                            }
                            return a.first_name.localeCompare(b.first_name);
                        });
                    }

                    if (filterBy === 'assigned') {
                        appsData = appsData.filter(
                            (applicant) => applicant.team
                        );
                    } else if (filterBy === 'unassigned') {
                        appsData = appsData.filter(
                            (applicant) => !applicant.team
                        );
                    }
                    setApps(appsData);
                })
                .catch((error) => {
                    setError(error.errorMsg);
                });
        };
        getApps();
    }, [props.api, sortBy, filterBy]);

    return (
        <>
            <Container>
                <div className="people-header mb-5">
                    <h3 className="">Hackers</h3>
                    <div className="display-flex buttons-container">
                        <FilterByButton setFilterBy={setFilterBy} />
                        <SortByButton setSortBy={setSortBy} />
                    </div>
                </div>
            </Container>
            {apps &&
                apps.length > 0 &&
                apps.map((hacker) => (
                    <Hacker
                        timezone={hacker.timezone || ''}
                        first_name={hacker.first_name || ''}
                        last_name={hacker.last_name || ''}
                        email={hacker.email || ''}
                        location={hacker.location || ''}
                        social_url={hacker.social_url || ''}
                        university={hacker.university || ''}
                        user_role={hacker.user_role || ''}
                        team={hacker.team || ''}
                        team_name={hacker.team_name || ''}
                        team_members={hacker.team_members || ''}
                    />
                ))}
        </>
    );
};

const SortByButton = ({ setSortBy }) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const dropdownRef = useRef(null);
    const buttonRef=useRef(null);


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    const handleButtonClick = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleRadioChange = (event, option) => {
        handleSortByChange(option);
        handleOptionChange(event);
    };

    const handleSortByChange = (option) => {
        // changes if the data should be sorted or not be sorted
        setSortBy((prevSortBy) => (prevSortBy === option ? null : option));
    };

    const handleOptionChange = (event) => {
        // changes radio button to be shown or not shown
        if (selectedOption === event.target.value) {
            setSelectedOption('');
        } else {
            setSelectedOption(event.target.value);
        }
    };

    return (
        <div className={`sortby-button ${isDropdownOpen ? 'open' : ''}`}>
            <button
                className={`dropdown-button ${
                    isDropdownOpen ? 'dropdown-button-radius' : ''
                }`}
                onClick={handleButtonClick}
                ref={buttonRef}
            >
                Sort By
            </button>
            {isDropdownOpen && (
                <div className="dropdown-sortby" ref={dropdownRef}>
                    <label className="display-flex">
                        <input
                            type="radio"
                            name="sortOption"
                            value="alphabetically"
                            checked={selectedOption === 'alphabetically'}
                            onClick={handleRadioChange}
                            onChange={(event) =>
                                handleRadioChange(event, 'alphabetically')
                            }
                            className="mt-3 ml-3 radio-circle"
                        />
                        <p className="radio-text1">Alphabetically</p>
                    </label>
                    <label className="display-flex">
                        <input
                            type="radio"
                            name="sortOption"
                            value="team"
                            checked={selectedOption === 'team'}
                            onClick={handleRadioChange}
                            onChange={(event) =>
                                handleRadioChange(event, 'team')
                            }
                            className="mt-2 ml-3 radio-circle"
                        />
                        <p className="radio-text2">Team</p>
                    </label>
                    <label className="display-flex">
                        <input
                            type="radio"
                            name="sortOption"
                            value="toronto"
                            checked={selectedOption === 'toronto'}
                            onClick={handleRadioChange}
                            onChange={(event) =>
                                handleRadioChange(event, 'toronto')
                            }
                            className="mt-2 ml-3 radio-circle"
                        />
                        <p className="radio-text2">Toronto Hub</p>
                    </label>
                    <label className="display-flex">
                        <input
                            type="radio"
                            name="sortOption"
                            value="london"
                            checked={selectedOption === 'london'}
                            onClick={handleRadioChange}
                            onChange={(event) =>
                                handleRadioChange(event, 'london')
                            }
                            className="mt-2 ml-3 radio-circle"
                        />
                        <p className="radio-text2">London Hub</p>
                    </label>
                    <label className="display-flex">
                        <input
                            type="radio"
                            name="sortOption"
                            value="online_est"
                            checked={selectedOption === 'online_est'}
                            onClick={handleRadioChange}
                            onChange={(event) =>
                                handleRadioChange(event, 'online_est')
                            }
                            className="mt-2 ml-3 radio-circle"
                        />
                        <p className="radio-text2">Online EST Hub</p>
                    </label>
                    <label className="display-flex">
                        <input
                            type="radio"
                            name="sortOption"
                            value="online_gmt"
                            checked={selectedOption === 'online_gmt'}
                            onClick={handleRadioChange}
                            onChange={(event) =>
                                handleRadioChange(event, 'online_gmt')
                            }
                            className="mt-2 ml-3 radio-circle"
                        />
                        <p className="radio-text2">Online GMT Hub</p>
                    </label>
                </div>
            )}
        </div>
    );
};

const FilterByButton = ({ setFilterBy }) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const dropdownRef = useRef(null);
    const buttonRef=useRef(null);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    const handleButtonClick = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleRadioChange = (event, option) => {
        handleFilterByChange(option);
        handleOptionChange(event);
    };

    const handleFilterByChange = (option) => {
        // changes if the data should be filtered or not be filtered
        setFilterBy((prevFilterBy) =>
            prevFilterBy === option ? null : option
        );
    };

    const handleOptionChange = (event) => {
        // changes radio button to be shown or not shown
        if (
            (selectedOption === 'assigned' &&
                event.target.value === 'unassigned') ||
            (selectedOption === 'unassigned' &&
                event.target.value === 'assigned')
        ) {
            setSelectedOption(event.target.value);
        } else if (
            selectedOption === 'assigned' ||
            selectedOption === 'unassigned'
        ) {
            setSelectedOption('');
        } else {
            setSelectedOption(event.target.value);
        }
    };

    return (
        <div className={`sortby-button ${isDropdownOpen ? 'open' : ''}`}>
            <button
                className={`dropdown-button ${
                    isDropdownOpen ? 'dropdown-button-radius' : ''
                }`}
                onClick={handleButtonClick}
                ref={buttonRef}
            >
                Filter By
            </button>
            {isDropdownOpen && (
                <div className="dropdown-filterby" ref={dropdownRef}>
                    <label className="display-flex">
                        <input
                            type="radio"
                            name="filterOption"
                            value="assigned"
                            checked={selectedOption === 'assigned'}
                            onClick={handleRadioChange}
                            onChange={(event) =>
                                handleRadioChange(event, 'assigned')
                            }
                            className="mt-3 ml-3 radio-circle"
                        />
                        <p className="radio-text1">In Team</p>
                    </label>
                    <label className="display-flex">
                        <input
                            type="radio"
                            name="filterOption"
                            value="unassigned"
                            checked={selectedOption === 'unassigned'}
                            onClick={handleRadioChange}
                            onChange={(event) =>
                                handleRadioChange(event, 'unassigned')
                            }
                            className="mt-2 ml-3 radio-circle"
                        />
                        <p className="radio-text2">Without Team</p>
                    </label>
                </div>
            )}
        </div>
    );
};

const condition = (authUser) =>
    !!authUser &&
    ((authUser.acceptance_status === 'ACCEPTED_RSVP_YES' &&
        authUser.rsvp_option) ||
        authUser.perm_level === 'Admin');
export default compose(
    withRouter,
    withAPI,
    withAuthorization(condition)
)(HackersPage);
