import React from "react";

import AuthUserContext from "./context";
import { withAPI } from "../api";
import { withRouter } from "react-router-dom";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.updateAuthUser = this.updateAuthUser.bind(this);
      this.state = {
        authUser: {
          isLoading: true,
        },
        updateAuthUser: this.updateAuthUser,
      };
    }

    componentDidMount() {
      this.props.api
        .currentUser()
        .then((data) => {
          this.setState({ authUser: data.User });
        })
        .catch((error) => {
          this.setState({ authUser: null });
        });

      this.unlisten = this.props.history.listen(() => {
        this.props.api
          .currentUser()
          .then((data) => {
            this.setState({ authUser: data.User });
          })
          .catch((error) => {
            this.setState({ authUser: null });
          });
      });
    }

    updateAuthUser() {
      this.props.api
        .currentUser()
        .then((data) => {
          this.setState({ authUser: data.User });
        })
        .catch((error) => {
          this.setState({ authUser: null });
        });
    }

    componentWillUnmount() {
      this.unlisten();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withRouter(withAPI(WithAuthentication));
};

export default withAuthentication;
