import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Col, Container, Image, Row } from "react-bootstrap";
import { PAGES_IMAGES, ROUTES } from "../../util";
import { compose } from "recompose";
import Header from "../../components/Header";
import { withAPI } from "../../api";

class EmailVerificationBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      error: null,
    };
  }

  componentDidMount() {
    this.props.api
      .verifyEmail(this.props.match.params.key)
      .then(() => {
        this.setState({
          success: true,
        });
      })
      .catch((error) => {
        this.setState({
          success: false,
          error: error.errorMsg,
        });
      });
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row className="h-100 mt-auto mb-auto">
            <Col md={5} xl={4}>
              <Header />
              <div className="gs-form-container pr-5 pl-5">
                {this.state.success ? (
                  <h3 className="pb-3 pt-2">Email Verified!</h3>
                ) : (
                  <div>
                    <h3 className="pb-3 pt-2">Oops, Something Went Wrong</h3>
                    <p>{this.state.error}</p>
                  </div>
                )}
                <br />
                <Link to={ROUTES.LOGIN}>Back To Log In</Link>
              </div>
            </Col>
            <Col
              md={7}
              xl={8}
              className="bg-light-yellow h-100 p-0 d-none d-md-block"
            >
              <Image
                src={PAGES_IMAGES.password.default}
                className="gs-password-image"
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const EmailVerificationPage = compose(
  withRouter,
  withAPI,
)(EmailVerificationBase);

export default EmailVerificationPage;
