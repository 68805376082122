/** @format */

//TODO: add deadline, change banner
import React, { Component, useState, useEffect} from 'react';
import {
    Button,
    Card,
    Col,
    Container,
    Image,
    Row,
    Modal,
    Form,
} from 'react-bootstrap';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDiceOne,
    faDiceTwo,
    faEdit,
    faUser,
    faHandPeace,
    faBug,
    faBatteryFull,
    faBatteryEmpty,
    faEnvelope,
    faMapMarkerAlt,
    faMedal,
} from '@fortawesome/free-solid-svg-icons';

import { withAPI } from '../../../api';
import { AuthUserContext, withAuthorization } from '../../../session';
import {
    isDuringActivePeriod,
    PAGES_IMAGES,
    PERM_LEVELS,
    APPLICATION_STATUS,
} from '../../../util';
import DashboardNav from '..';
import { EVENTS } from '../../../events/event_constants';
import mixpanel from 'mixpanel-browser';
import RubricPopup from '../../judge/home/rubricPopup';

import FileSubmissionForm from '../../../components/FileSubmissionForm';
import JudgeSubmissionView from '../../../components/JudgeSubmissionView';

const TeamPage = () => {
    return (
        <>
            <DashboardNav />
            <div className="gs-dashboard-content pt-0">
                <Container className="gs-team-container">
                    <Team />
                </Container>
            </div>
        </>
    );
};

const MemberCard = ({
    self,
    profile_icon,
    profile_color,
    first_name,
    last_name,
    email,
    country,
    user_role,
    setModalView,
}) => {
    return (
        <div className="gs-member-card">
            <div className="d-flex justify-content-between">
                <h5 style={{color: profile_color ? PROFILE_COLORS[profile_color]: '#170B31',}}>
                    <FontAwesomeIcon size="lg" icon={profile_icon ? PROFILE_ICONS[profile_icon] : faUser}/>
                    {` `} {first_name} {` `} {last_name}
                </h5>
                {self && (
                    <FontAwesomeIcon onClick={() => {setModalView(EDIT_MEMBER_MODAL); }} icon={faEdit}/>
                )}
            </div>
            {email && (
                <p>
                    <FontAwesomeIcon className="text-blue" icon={faEnvelope} />
                    {` `}
                    {email}
                </p>
            )}
            {country && (
                <p>
                    <FontAwesomeIcon
                        className="text-red"
                        icon={faMapMarkerAlt}
                    />
                    {` `}
                    {country}
                </p>
            )}
            {user_role && (
                <p>
                    <FontAwesomeIcon className="text-yellow" icon={faMedal} />
                    {` `}
                    {user_role}
                </p>
            )}
        </div>
    );
};

const CREATE_TEAM_MODAL = 0;
const JOIN_TEAM_MODAL = 1;
const LEAVE_TEAM_MODAL = 2;
const UPDATE_TEAM_NAME_MODAL = 3;
const EDIT_MEMBER_MODAL = 4;
const SUSTAINABILITY = 'Sustainable Societies';
const ECONOMIC = 'Economic Advancement';
const HEALTH = 'Health & Humanity';
const PROFILE_ICONS = [
    faUser,
    faHandPeace,
    faBug,
    faBatteryFull,
    faBatteryEmpty,
];
const PROFILE_COLORS = ['#170B31', '#4542C2', '#36AB9D', '#1D65B1', '#F6BD42'];
const INITIAL_STATE = {
    // modals
    modalView: null,
    new_team_name: '',
    join_team_code: '',
    modalError: '',
    //copied: false,
    // edit member info
    new_color: 0,
    new_icon: 0,
    new_country: '',
    new_user_role: '',
    //edit theme
    isEditing: false,
    newTheme: null,
    themeError: null,
    theme_selection_close_date: process.env.REACT_APP_THEME_SELECTION_CLOSE_TIME,
    judge_panel: '',
    judges: [],
};

class TeamBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
        this.setModalView = this.setModalView.bind(this);

        //! Set debug to false before going into production
        mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
            cross_subdomain_cookie: false,
            ignore_dnt: true,
            debug: false,
        });
    }

    componentDidMount() {

        this.refreshTeamInfo();
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    setModalView(view) {
        this.setState({ modalView: view });
        this.setState({ modalError: null });
    }

    refreshTeamInfo() {
        // update team members and self info
        this.props.api.hackerGetTeam().then((data) => {
            this.setState({ team: data.Team });
        })
        .catch(error => {
            console.log(error);
        });
    }

    createNewTeam = (event, updateAuthUser) => {
        const { new_team_name } = this.state;
        this.props.api
            .createTeam(new_team_name)
            .then(() => {
                this.refreshTeamInfo();
                updateAuthUser();
                this.setState({ modalView: null });

                mixpanel.track(EVENTS.CREATE_TEAM_SUCCESS);
            })
            .catch((error) => {
                this.setState({ modalError: error.errorMsg });
            });
        event.preventDefault();
    };

    updateTeamName = (event, updateAuthUser) => {
        const { new_team_name } = this.state;
        this.props.api
            .updateTeam({ team_name: new_team_name })
            .then(() => {
                this.refreshTeamInfo();
                this.setState({ modalView: null });
            })
            .catch((error) => {
                this.setState({ modalError: error.errorMsg });
            });
        event.preventDefault();
    };

    joinNewTeam = (event, updateAuthUser) => {
        const { join_team_code } = this.state;
        this.props.api
            .joinTeam(join_team_code)
            .then(() => {
                this.refreshTeamInfo();
                updateAuthUser();
                this.setState({ modalView: null });

                mixpanel.track(EVENTS.JOIN_TEAM_SUCCESS);
            })
            .catch((error) => {
                this.setState({ modalError: error.errorMsg });
            });
        event.preventDefault();
    };

    leaveTeam = (event, updateAuthUser) => {
        this.props.api
            .leaveTeam()
            .then(() => {
                this.refreshTeamInfo();
                updateAuthUser();
                this.setState({ modalView: null });

                mixpanel.track(EVENTS.LEAVE_TEAM_SUCCESS);
            })
            .catch((error) => {
                this.setState({ modalError: error.errorMsg });
            });
        event.preventDefault();
    };

    updateUser = (event, updateAuthUser) => {
        const { new_color, new_icon, new_country, new_user_role } = this.state;
        // error check
        const toBeUpdated = {};
        if (new_color !== 0) {
            toBeUpdated.color = new_color;
        }
        if (new_icon !== 0) {
            toBeUpdated.icon = new_icon;
        }
        if (new_country !== '') {
            toBeUpdated.country = new_country;
        }
        if (new_user_role !== '') {
            toBeUpdated.user_role = new_user_role;
        }
        this.props.api
            .updateUser(toBeUpdated)
            .then(() => {
                this.setState({ modalView: null });
                this.refreshTeamInfo();
                updateAuthUser();
            })
            .catch((error) => {
                this.setState({ modalError: error.errorMsg });
            });
        event.preventDefault();
    };

    submitTheme = (event) => {
        const { newTheme } = this.state;
        this.props.api
            .updateTeam({ theme: newTheme })
            .then(() => {
                this.refreshTeamInfo();
                this.setState({ isEditing: false });

                mixpanel.track(EVENTS.SELECT_THEME_SUCCESS, {
                    Theme: newTheme,
                });
            })
            .catch((error) => {
                this.setState({ themeError: error.errorMsg });
            });
        event.preventDefault();
    };

    /* // this code is never referenced anywhere in index.js
    onSubmitFinalSubmission = (event) => {
        if (event.response_id !== undefined && event.response_id !== '') {
            this.props.api
                .updateTeam({ submission_typeform_id: event.response_id })
                .then(() => {
                    this.refreshTeamInfo();

                    mixpanel.track(EVENTS.SUBMIT_BEGIN);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        return true;
    }
    */

    // Used by TeamView to update the state of TeamBase
    updateState = (newState) => {
        console.log(newState);
        this.setState(newState);
    };

    

    render() {
        const {
            team ,
            modalView,
            new_team_name,
            join_team_code,
            isEditing,
            themeError,
            modalError,
            theme_selection_close_date
        } = this.state;
        return (
            <AuthUserContext.Consumer>
                {({ authUser, updateAuthUser }) => (
                    <div>
                        {authUser.team ? (
                            // 1. Already in a team.
                            <TeamView
                                team = {team}
                                authUser = {authUser}
                                isEditing = {isEditing}
                                themeError = {themeError}
                                setModalView = {this.setModalView}
                                updateState = {this.updateState}
                                submitTheme={this.submitTheme}
                                theme_selection_close_date={theme_selection_close_date}
                                api = {this.props.api}
                            ></TeamView>
                        ) : (
                            // 2. No team yet
                            <NoTeamView
                                setModalView={this.setModalView}
                            ></NoTeamView>
                        )}

                        {/* Create Team Modal */}
                        <Modal
                            centered
                            show={modalView === CREATE_TEAM_MODAL}
                            onHide={() => {}}
                        >
                            <Form
                                onSubmit={(e) => {
                                    this.createNewTeam(e, updateAuthUser);
                                }}
                            >
                                <Modal.Header>
                                    <Modal.Title>
                                        {' '}
                                        <b> Create New Team </b>{' '}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group>
                                        <Form.Label>
                                            {' '}
                                            Enter your team's name:{' '}
                                        </Form.Label>
                                        <Form.Control
                                            className="border pl-1"
                                            name="new_team_name"
                                            value={new_team_name}
                                            onChange={this.onChange}
                                            type="text"
                                            placeholder="Team 1"
                                        />
                                    </Form.Group>
                                    {modalError && (
                                        <p className="text-danger">
                                            {modalError}
                                        </p>
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        className="btn-secondary"
                                        onClick={() => {
                                            this.setState({
                                                modalView: null,
                                            }); /*; mixpanel.track(EVENTS.CREATE_TEAM_CANCEL)*/
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button className="btn-green" type="submit">
                                        Create
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>

                        {/* Join Team Modal */}
                        <Modal
                            centered
                            show={modalView === JOIN_TEAM_MODAL}
                            onHide={() => {}}
                        >
                            <Form
                                onSubmit={(e) => {
                                    this.joinNewTeam(e, updateAuthUser);
                                }}
                            >
                                <Modal.Header>
                                    <Modal.Title>
                                        {' '}
                                        <b> Join Existing Team </b>{' '}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group>
                                        <Form.Label>
                                            {' '}
                                            Enter your teams' <b>unique code</b>{' '}to join:{' '}
                                        </Form.Label>
                                        <Form.Control
                                            className="border pl-1"
                                            name="join_team_code"
                                            value={join_team_code}
                                            onChange={this.onChange}
                                            type="text"
                                            placeholder="HUB-123"
                                        />
                                    </Form.Group>
                                    {modalError && (
                                        <p className="text-danger">{modalError}</p>
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        className="btn-secondary"
                                        onClick={() => {
                                            this.setState({
                                                modalView: null,
                                            }); /*; mixpanel.track(EVENTS.JOIN_TEAM_CANCEL)*/
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button className="btn-green" type="submit">
                                        Join
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>

                        {/* Leave Team Modal */}
                        <Modal
                            centered
                            show={modalView === LEAVE_TEAM_MODAL}
                            onHide={() => {}}
                        >
                            <Modal.Header>
                                <Modal.Title>
                                    {' '}
                                    <b> Leave Team </b>{' '}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are you sure you want to leave this team?{' '}
                                <span className="text-red">
                                    {' '}
                                    This action cannot be undone.{' '}
                                </span>
                                {modalError && (
                                    <p className="text-danger">{modalError}</p>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    className="btn-secondary"
                                    onClick={() => {
                                        this.setState({
                                            modalView: null,
                                        }); /*; mixpanel.track(EVENTS.LEAVE_TEAM_CANCEL)*/
                                    }}
                                >
                                    No
                                </Button>
                                <Button
                                    className="btn-green"
                                    onClick={(e) => {
                                        this.leaveTeam(e, updateAuthUser);
                                    }}
                                >
                                    Yes
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* Update Team Name Modal */}
                        <Modal
                            centered
                            show={modalView === UPDATE_TEAM_NAME_MODAL}
                            onHide={() => {}}
                        >
                            <Form
                                onSubmit={(e) => {
                                    this.updateTeamName(e, updateAuthUser);
                                }}
                            >
                                <Modal.Header>
                                    <Modal.Title>
                                        {' '}
                                        <b> Update Team Name </b>{' '}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group>
                                        <Form.Label>
                                            {' '}Enter your new team name:{' '}
                                        </Form.Label>
                                        <Form.Control
                                            className="border pl-1"
                                            name="new_team_name"
                                            value={new_team_name}
                                            onChange={this.onChange}
                                            type="text"
                                            placeholder="Team 1"
                                        />
                                    </Form.Group>
                                    {modalError && (
                                        <p className="text-danger">
                                            {modalError}
                                        </p>
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        className="btn-secondary"
                                        onClick={() => {
                                            this.setState({ modalView: null });
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button className="btn-green" type="submit">
                                        Update
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>

                        {/* Edit Team Member Modal */}
                        <Modal
                            centered
                            show={modalView === EDIT_MEMBER_MODAL}
                            onHide={() => {}}
                        >
                            <Form
                                onSubmit={(e) => {
                                    this.updateUser(e, updateAuthUser);
                                }}
                            >
                                <Modal.Header>
                                    <Modal.Title>
                                        {' '}
                                        <b>
                                            {' '}{authUser.first_name}{` `}{authUser.last_name}{' '}
                                        </b>{' '}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group>
                                        <Form.Label> Profile Color </Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="new_color"
                                            style={{
                                                color: PROFILE_COLORS[this.state.new_color],
                                            }}
                                            defaultValue={authUser.color}
                                            onChange={this.onChange}
                                        >
                                            <option
                                                value={0}
                                                style={{color: PROFILE_COLORS[0],}}
                                            >
                                                &#xf111;
                                            </option>
                                            <option
                                                value={1}
                                                style={{color: PROFILE_COLORS[1],}}
                                            >
                                                &#xf111;
                                            </option>
                                            <option
                                                value={2}
                                                style={{color: PROFILE_COLORS[2],}}
                                            >
                                                &#xf111;
                                            </option>
                                            <option
                                                value={3}
                                                style={{color: PROFILE_COLORS[3],}}
                                            >
                                                &#xf111;
                                            </option>
                                            <option
                                                value={4}
                                                style={{color: PROFILE_COLORS[4],}}
                                            >
                                                &#xf111;
                                            </option>
                                        </Form.Control>
                                    </Form.Group>
                                    {/* <Form.Group>
                                        <Form.Label> Profile Icon </Form.Label>
                                        <Form.Control as="select" name="new_icon" style={{color: PROFILE_COLORS[this.state.new_color]}} defaultValue={authUser.icon} onChange={this.onChange}>
                                            <option value={0} style={{color: PROFILE_COLORS[this.state.new_color]}}>&#xf007;</option>
                                            <option value={1} style={{color: PROFILE_COLORS[this.state.new_color]}}>&#xf25b;</option>
                                            <option value={2} style={{color: PROFILE_COLORS[this.state.new_color]}}>&#xf188;</option>
                                            <option value={3} style={{color: PROFILE_COLORS[this.state.new_color]}}>&#xf240;</option>
                                            <option value={4} style={{color: PROFILE_COLORS[this.state.new_color]}}>&#xf244;</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label> Country </Form.Label>
                                        <Form.Control className='border pl-1' name="new_country" defaultValue={authUser.country} onChange={this.onChange} type="text" />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label> user_role </Form.Label>
                                        <Form.Control as="select" name="new_user_role" defaultValue={authUser.user_role} onChange={this.onChange}>
                                            <option>Frontend Developer</option>
                                            <option>Backend Developer</option>
                                            <option>UI/UX Designer</option>
                                            <option>Business</option>
                                            <option>Research</option>
                                        </Form.Control>
                                    </Form.Group> */}
                                    {modalError && (
                                        <p className="text-danger">
                                            {modalError}
                                        </p>
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        className="btn-secondary"
                                        onClick={() => {
                                            this.setState({ modalView: null });
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button className="btn-green" type="submit">
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    </div>
                )}
            </AuthUserContext.Consumer>
        );
    }
    
}

class TeamView extends Component{
    constructor(props){
        super(props);
        this.state = { 
            copied:false,
            judges:[],
        };
    }

    handlechangeTeamJudgingPanel = (newSelection) => {
        console.log(newSelection);
        this.setState({ panel_selection:newSelection});
        this.props.api.changeTeamJudgingPanel(newSelection)
        .catch((error) => {
            console.log(error);
        });
    }

    componentDidUpdate(prevProps){
        if (prevProps.team !== this.props.team) {


            this.setState({ panel_selection: this.props.team?.panel_selection });

            console.log(this.props);
            this.props.api.getTeamScores(this.props.team.team_code)
                .then((res) => {
                    console.log(res);
                    let judgesTemp=[];
                    const Scores = res.Scores;
                    console.log(Scores);
                    for (const score of Scores) {
                        judgesTemp.push(score.judge);
                    }
                    this.setState({judges:judgesTemp});
                })
                .catch((error) => {
                    console.log(error.errorMsg);
                });

        }
        const date = new Date(this.props.theme_selection_close_date);
        console.log(date.toString());
    }

    hideRubricPopup = () => {
        this.setState({ showRubricPopup: false });
    }

    render()
    {
    const {team, authUser, isEditing, themeError, setModalView, updateState, submitTheme, theme_selection_close_date } = this.props;
    const {
        copied,
        judges,
    } = this.state;


    return <>
    {team && (
        <>
            <div className="in-team-style">
                <Container className="gs-htg-block pb-3 d-flex justify-content-between">
                    <div style={{maxWidth: '100rem', }}>
                        <h1>
                            Hey, Team{' '}
                            <b>{team.team_name}</b>!{' '}
                            <FontAwesomeIcon
                                onClick={() => {setModalView(UPDATE_TEAM_NAME_MODAL);}}
                                icon={faEdit}
                                size="xs"/>
                        </h1>
                    </div>
                    {isDuringActivePeriod('TEAM_FORMATION_PERIOD',).isActive ? 
                    (
                        <p className="pt-4 text-danger gs-clickable-text"
                            onClick={() => {
                                setModalView(LEAVE_TEAM_MODAL);
                                mixpanel.track(EVENTS.LEAVE_TEAM_BEGIN);}}>
                            {' '}Leave Team{' '}
                        </p>
                    ) : (
                        <p className="pt-4 text-danger">Team formation period is now over</p>
                    )}
                </Container>
                <Container className="gs-in-team-block team-container mb-3">
                    
                        <div className="d-inline-flex team-item">
                            <p>
                                <b>Your Unique Code:</b>{' '}
                                {team.team_code}
                            </p>
                            <Button
                                className="btn-purple-secondary mx-2 p-0"
                                onClick={() => {
                                    navigator.clipboard.writeText(team.team_code);
                                }}
                                onFocus={() => {
                                    console.log("clicked copy");
                                    this.setState({copied: true,});
                                }}
                                onBlur={() => {
                                    this.setState({copied: false,});
                                }}
                                style={{
                                    height: '23px',
                                    width: '111px',
                                    fontSize: '14px',
                                    borderRadius: '5px',
                                }}
                            >
                                {copied? 'Copied': 'Copy Code'}
                            </Button>
                        </div>
                        <div className="team-item">
                            <b>Team Members:</b>
                            <div className="member-container ml-2" style={{gap:'1rem',}}>
                                {team.team_members.map((member, index) => (
                                    <div key={index}
                                            className="d-flex justify-content-start mt-2"
                                            >
                                    <MemberCard
                                        self = {authUser.email === member.email}
                                        //setModalView = {this.setModalView}
                                        setModalView = {setModalView}
                                        profile_color = {member.color}
                                        profile_icon = {member.icon}
                                        first_name = {member.first_name}
                                        last_name = {member.last_name}
                                        email = {member.email}
                                        country = {member.country}
                                        new_user_role = {member.new_user_role}
                                        />
                                    </div>
                                    )
                                )}
                            </div>
                        </div>

                        <div className="team-item">
                            <span>
                                
                                <b>
                                    Select Submission Theme:{' '}
                                </b>
                                {/* Below line is used to show/hide the edit button logic since theme submission deadline has passed (8pm) */}
                                {true && ( <>
                                        {isEditing ? (
                                            <>
                                                <span
                                                    className="gs-clickable-text"
                                                    onClick={submitTheme}
                                                    style={{color: '#A8CA71',}}
                                                >
                                                {' '}OK </span>
                                                {` `}
                                                <span
                                                    className="gs-clickable-text"
                                                    onClick={() => {
                                                        updateState({isEditing: false,}); 
                                                        /*; mixpanel.track(EVENTS.SELECT_THEME_CANCEL)*/}}>
                                                    {' '}Cancel{' '}
                                                </span>
                                                <br />
                                                {themeError && (
                                                    <>
                                                        {' '}
                                                        <br />{' '}
                                                        <span className="text-danger">
                                                            {' '} {themeError}{' '}
                                                        </span>{' '}
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {isDuringActivePeriod('THEME_SELECTION_PERIOD').isActive ? (
                                                    <span
                                                        className="gs-clickable-text"
                                                        onClick={() => {
                                                            updateState({isEditing: true,});
                                                            mixpanel.track(EVENTS.SELECT_THEME_BEGIN);
                                                        }}
                                                    >
                                                        {' '}Edit{' '}
                                                    </span>
                                                ) : (
                                                    <>
                                                        <span
                                                            className="gs-clickable-text"
                                                            onClick={() => {
                                                                mixpanel.track(
                                                                    EVENTS.SELECT_THEME_BEGIN
                                                                );
                                                            }}
                                                        >
                                                            {' '}Edit{' '}
                                                        </span>
                                                        <p className="text-danger">
                                                            {' '}Theme selection period is now over!
                                                        </p>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </span>
                            <div className="d-flex mt-2 flex-wrap ml-2" style={{gap: '1rem',}}>
                                <div
                                    className=" d-flex"
                                >
                                    <Button
                                        className={`btn-teal${
                                            team.theme ===SUSTAINABILITY && !isEditing? ' ': '-secondary ' 
                                        } gs-theme-button`}
                                        variant="secondary"
                                        onClick={() => {
                                            updateState({newTheme:SUSTAINABILITY,});
                                        }}
                                        disabled={!isEditing}
                                    
                                    >
                                        <Image
                                            src={PAGES_IMAGES.sustainable_icon.default}
                                        />
                                        Sustainable Infrastructure
                                    </Button>
                                </div>
                                <div
                                    className=" d-flex"
                                >
                                    <Button
                                        className={`btn-lime${
                                            team.theme === ECONOMIC && !isEditing ? ' ' : '-secondary '
                                        }gs-theme-button`}
                                        variant="secondary"
                                        onClick={() => {
                                            updateState({newTheme:ECONOMIC,});
                                        }}
                                        disabled={!isEditing}
                                    >
                                        <Image
                                            src={PAGES_IMAGES.economic_icon.default}
                                        />
                                        Economic Empowerment
                                    </Button>
                                </div>
                                <div
                                    className=" d-flex"
                                >
                                    <Button
                                        onClick={() => {
                                            updateState({newTheme:HEALTH,});
                                        }}
                                        variant={`${
                                            team.theme === HEALTH && !isEditing ? 'primary ' : 'secondary '
                                        }gs-theme-button btn-red`}
                                        disabled={!isEditing}
                                    >
                                        <Image src={PAGES_IMAGES.health_icon.default}/>
                                        Health &amp; Humanity
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="team-item">
                            {isDuringActivePeriod('THEME_SELECTION_PERIOD').isActive ? (
                                <></>
                            ) : (
                                <p className="text-danger">
                                    Judging Panel Selection period is now over!
                                </p>
                            )}
                        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', flexWrap:'wrap', flexDirection:'column' }}>
                            <b>Select preferred judging panel:</b>
                            <div className="d-flex align-items-center width-100 ml-2">
                                <Button className = {this.state.panel_selection === "Hardware" ? "panel-button-active" : "panel-button-disable"} onClick = {() => {this.handlechangeTeamJudgingPanel("Hardware")}} disabled = {!isDuringActivePeriod('THEME_SELECTION_PERIOD').isActive}>
                                    Hardware
                                </Button>
                                <Button className = {this.state.panel_selection === "Software" ? "panel-button-active" : "panel-button-disable"} onClick = {() => {this.handlechangeTeamJudgingPanel("Software")}} disabled = {!isDuringActivePeriod('THEME_SELECTION_PERIOD').isActive}>
                                    Software
                                </Button>
                            </div>
                        </div>
                        <div
                            style={{fontSize:'14px',}}
                            className="team-item"
                        >
                            Confirm your theme and preferred judging panel by {` `} {new Date(theme_selection_close_date).toLocaleString(undefined,{ year: "numeric",
                                                                                                                                                        month: "short", 
                                                                                                                                                        day: "numeric",
                                                                                                                                                        hour: "numeric",
                                                                                                                                                        minute: "numeric", 
                                                                                                                                                        timeZoneName: "short",})}

                        </div>
                    </div>

                    <Card className="mb-3" style={{ width: '100%' }}>
                    <Card.Body>
                        <Row>
                            <Col xs={6} md={4}>
                                <Card.Subtitle className="mb-2 text-muted">Mentor</Card.Subtitle>
                                <Card.Text>{team.mentor.split(',')[0] || "No Mentor Yet"}</Card.Text>
                            </Col>
                            <Col xs={6} md={4}>
                                <Card.Subtitle className="mb-2 text-muted">Email</Card.Subtitle>
                                <Card.Text>{team.mentor.split(',')[1] || "N/A"}</Card.Text>
                            </Col>
                            {/* <Col xs={6} md={4}>
                                <Card.Subtitle className="mb-2 text-muted">Time</Card.Subtitle>
                                <Card.Text>{team.mentor.split(',')[2] || "N/A"}</Card.Text>
                            </Col> */}
                            </Row>
                        </Card.Body>
                    </Card>
                    {isDuringActivePeriod("SCORE_VIEW_PERIOD").isActive && <Card style={{ width: '100%', zIndex: '1' }}>
                        <Card.Body>
                        <Card.Subtitle className="mb-2 text-muted">Judge Comments:</Card.Subtitle>
                            <div className="comment-grid">
                                {judges.map((judge, index) => (
                                    <>
                                        <TeamComments 
                                            team={team} 
                                            judge = {judge} 
                                            teamPage={true}
                                            api={this.props.api}/>
                                    </>
                                ))}
                            </div>

                        </Card.Body>
                    </Card>}
                    
                </Container>

                <Container className="gs-in-team-block"> 

                    <FileSubmissionForm api={this.props.api} team={team} submitter={authUser} />

                </Container>

            </div>
        </>
    )}
</>
}}

const TeamComments = ({team, judge, api})=>{
    const [comments, setComments] = useState([]);

    useEffect(() => {
        api.getScore(team.team_code, judge).then((res)=>{
            console.log(res.body.comments);
            let commentsTemp = [];
            for (const comment of res.body.comments) {
                if (comment !== null){
                    commentsTemp.push(comment);
                }
            }
            console.log(commentsTemp)
            setComments(commentsTemp);
            console.log(comments)
        })
      }, [api, team.team_code, judge]);
    
    return <> 
            {comments.map((comment, index)=>(
                comment &&
                <div className="comment-container">
                    <span key={index}>{comment}</span>
                </div>
            ))}

        {/* <Button className="btn-purple" onClick={()=>{
            setShowRubricPopup(true);
        }}>
            {judge}
        </Button>
        {showRubricPopup && <RubricPopup 
                                renderHomePage = {hideRubricPopup}
                                teamCode={team.team_code} 
                                teamName={team.team_name}
                                teamMembers={team.members}
                                judgeName = {judge} 
                                teamPage={true}/>} */}
    </>;
}

const NoTeamView = ({
        setModalView
    })=>{
    return <div style={{ paddingTop: '8rem' }}>
        <Container className="gs-htg-block pb-1">
            <div className="mr-auto ml-auto text-center" style={{ maxWidth: '20rem' }}>
                <h2 className="pb-3">
                    You are currently not in a team
                </h2>
                {!isDuringActivePeriod('TEAM_FORMATION_PERIOD').isActive && 
                (
                    <p className="text-danger">
                        The team formation period is now over <br /> You will be assigned to a team!
                    </p>
                )}
            </div>
        </Container>
        <Container className="gs-no-team-block">
            <Row className="d-flex justify-content-center">
                <Col
                    className="d-flex justify-content-center"
                    lg={5}
                >
                    <Card className="p-2">
                        <FontAwesomeIcon
                            style={{ color: '#C4D0E2' }}
                            icon={faDiceOne}
                            size="2x"
                        />
                        <Card.Body className="py-1">
                            <div>
                                <h3>
                                    <b> Create </b>
                                </h3>
                                <h5> New Team</h5>
                            </div>
                            <Image src={PAGES_IMAGES.pencil.default}/>
                        </Card.Body>
                        {isDuringActivePeriod('TEAM_FORMATION_PERIOD').isActive ? 
                        (
                            <Button
                                className="btn-green"
                                onClick={() => {
                                    setModalView(CREATE_TEAM_MODAL);
                                    mixpanel.track(EVENTS.CREATE_TEAM_BEGIN);
                                }}
                                style={{
                                    borderRadius:'18px',
                                    height: '40px',
                                    width: '170px',
                                    justifySelf:'center',
                                }}
                            >
                                {' '}Create Now{' '}
                            </Button>
                        ) : (
                            <Button
                                className="btn-green"
                                style={{
                                    borderRadius:'18px',
                                    height: '40px',
                                    width: '170px',
                                    justifySelf:'center', }} 
                            >
                                {' '}Create Now{' '}
                            </Button>
                        )}
                    </Card>
                </Col>
                <Col
                    lg={1}
                    className="d-flex align-items-center justify-content-center"
                >
                    {' '}OR{' '}
                </Col>
                <Col
                    lg={5}
                    className="d-flex justify-content-center"
                >
                    <Card className="p-2">
                        <FontAwesomeIcon
                            style={{ color: '#C4D0E2' }}
                            icon={faDiceTwo}
                            size="2x"
                        />
                        <Card.Body className="py-1">
                            <div>
                                <h3>
                                    <b> Join </b>
                                </h3>
                                <h5> Existing Team </h5>
                            </div>
                            <Image
                                src={PAGES_IMAGES.join_team.default}
                            />
                        </Card.Body>
                        {isDuringActivePeriod(
                            'TEAM_FORMATION_PERIOD',).isActive ? (
                            <Button
                                className="btn-yellow"
                                onClick={() => {
                                    setModalView(JOIN_TEAM_MODAL);
                                    mixpanel.track(EVENTS.JOIN_TEAM_BEGIN);
                                }}
                                style={{
                                    borderRadius:'18px',
                                    height: '40px',
                                    width: '170px',
                                    justifySelf:'center',
                                }}
                            >
                                {' '}Enter Code{' '}
                            </Button>
                        ) : (
                            <Button
                                className="btn-yellow"
                                style={{
                                    borderRadius:'18px',
                                    height: '40px',
                                    width: '170px',
                                    justifySelf:'center',
                                }}
                            >
                                {' '}Enter Code{' '}
                            </Button>
                        )}
                    </Card>
                </Col>
            </Row>
        </Container>
        <Image
            src={PAGES_IMAGES.no_team_bg.default}
            className="gs-no-team-bg-img"
        />
    </div>
}


// ATTENTION HERE - status must be ACCEPTED_RSVP_YES for teams page to be rendered.
const condition = (authUser) =>
    !!authUser &&
    ((authUser.acceptance_status === APPLICATION_STATUS.ACCEPTED_RSVP_YES &&
        authUser.rsvp_option) ||
        authUser.perm_level === PERM_LEVELS.ADMIN);

const Team = compose(withAPI, withAuthorization(condition))(TeamBase);

export default TeamPage;