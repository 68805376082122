/** @format */

import React, { useState, useEffect, useCallback } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Row, Button, Card, Form, Modal } from 'react-bootstrap';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEdit } from '@fortawesome/free-solid-svg-icons';
import DashboardNav from '../dashboard';
import { withAPI } from '../../api';
import { withAuthorization } from '../../session';
import { ROUTES, PERM_LEVELS, APPLICATION_STATUS } from '../../util';

const AdminViewHackerPage = (props) => {
    const { api } = props;
    const [app, setApp] = useState({});

    // modals can be either: "Attributes", "Status", "Team", "None"
    const [activeModal, setActiveModal] = useState("None");
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [changeList, setChangeList] = useState([]);
    const [prev, setPrev] = useState(null);  // this variable is used in the case that the confirm modal is closed without saving

    const [error, setError] = useState(null);
    const { id } = useParams();

    // below are the fields that you can edit on a user
    const [statusEdit, setStatusEdit] = useState(null);
    const [first_name, setFirstName] = useState(null);        // custom
    const [last_name, setLastName] = useState(null);          // custom
    const [email, setEmail] = useState(null);                 // custom
    const [location, setLocation] = useState(null);           // options
    const [university, setUniversity] = useState(null);       // custom
    const [timezone, setTimeZone] = useState(null);           // custom for now
    const [user_role, setRole] = useState(null);              // options
    const [diet_restriction, setDietRes] = useState(null);    // custom
    const [social_url, setSocialURL] = useState(null);        // custom
    const [team_code, setTeamCode] = useState('');

    // below are all the change functions needed for the custom attributes
    // not including the attributes that should have fixed values
    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };
    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handleUniversityChange = (event) => {
        setUniversity(event.target.value);
    };
    const handleTimeZoneChange = (event) => {
        setTimeZone(event.target.value);
    };
    const handleDietResChange = (event) => {
        setDietRes(event.target.value);
    };
    const handleSocialURLChange = (event) => {
        setSocialURL(event.target.value);
    };
    const handleTeamCodeChange = (event) => {
        setTeamCode(event.target.value);
    }

    const statusColourMap = (status) => {
        if (
            status === APPLICATION_STATUS.ACCEPTED_RSVP_YES ||
            status === APPLICATION_STATUS.ACCEPTED_NEED_RSVP
        ) {
            return 'text-success';
        } else if (
            status === APPLICATION_STATUS.SUBMITTED ||
            status === APPLICATION_STATUS.WAITLISTED
        ) {
            return 'text-warning';
        } else {
            return 'text-danger';
        }
    };
    const statusToText = (status) => {
        if (status === APPLICATION_STATUS.SUBMITTED) return 'Under Review';
        if (status === APPLICATION_STATUS.NO_APP) return 'Not Received';
        if (status === APPLICATION_STATUS.ACCEPTED_NEED_RSVP)
            return 'Require RSVP';
        if (status === APPLICATION_STATUS.ACCEPTED_RSVP_YES) return 'Accepted';
        if (status === APPLICATION_STATUS.ACCEPTED_RSVP_NO) return "RSVP'ed No";
        if (status === APPLICATION_STATUS.WAITLISTED) return 'Waitlisted';
        if (status === APPLICATION_STATUS.ACCEPTED_OFFER_EXPIRED)
            return 'Offer Expired';
        if (status === APPLICATION_STATUS.REJECTED) return 'Rejected';
        return 'Unknown';
    };

    const acceptanceEditHandler = (event) => {
        setError(null);
        setStatusEdit(app.acceptance_status);
        setActiveModal("Status");
        event.preventDefault();
    };

    const attributeEditHandler = (event) => { // this is when you 
        setError(null);
        setFirstName(app.first_name); // set all the attributes if they want to edit
        setLastName(app.last_name);
        setEmail(app.email);
        setLocation(app.location);
        setUniversity(app.university);
        setTimeZone(app.timezone);
        setRole(app.user_role);
        setDietRes(app.diet_restriction);
        setSocialURL(app.social_url);
        setActiveModal("Attributes");
        event.preventDefault();
    };

    const teamEditHandler = (event) => {
        setError(null);
        if(app.team){
            setTeamCode(app.team.team_code);
        }
        setActiveModal("Team");
        event.preventDefault();
    }

    // this is the general confirm button that will be called by the confirm modal
    const confirm = () => {
        if(prev === "Status"){
            handleStatusSave();
        } else if (prev === "Attributes"){
            handleAttributeEditSave();
        } else if (prev === "Team"){
            handleTeamChanger();
        } else if (prev === "None") {
            handleDeleteApplicant();
        }
        setActiveModal(prev);
        setShowConfirmModal(false);
    }

    const confirmStatusEdit = () => {
        if(statusEdit !== app.acceptance_status && statusEdit !== null){
            const list = [];
            list.push("Changing " + app.first_name + "'s status from " + app.acceptance_status + " to {" + statusEdit + "}.");
            list.push("Are you sure you want to continue?");
            setActiveModal("None");
            setPrev("Status");
            setChangeList(list);
            setShowConfirmModal(true);
        } else {
            setError("No change was made!");
        }
    }

    const confirmAttributeEdit = () => {
        const list = [];
        if(app.team){
            if(app.team.team_location){
                if(location !== app.location && location !== app.team.team_location){
                    list.push("WARNING: This hacker's location will not match their Team's location!");
                }
            }
        }
        if (first_name !== app.first_name && first_name !== ''){
            list.push("Changing " + app.first_name + "'s First Name from {" + app.first_name + "} to {" + first_name + "}.");
        }
        if (last_name !== app.last_name && last_name !== ''){
            list.push("Changing " + app.first_name + "'s Last Name from {" + app.last_name + "} to {" + last_name + "}.");
        }
        if (email !== app.email && email !== ''){
            list.push("Changing " + app.first_name + "'s Email from {" + app.email + "} to {" + email + "}.");
        }
        if (location !== app.location && location !== ''){
            list.push("Changing " + app.first_name + "'s Location from {" + app.location + "} to {" + location + "}.");
        }
        if (university !== app.university && university !== ''){
            list.push("Changing " + app.first_name + "'s University from {" + app.university + "} to {" + university + "}.");
        }
        if (timezone !== app.timezone && timezone !== ''){
            list.push("Changing " + app.first_name + "'s Time Zone from {" + app.timezone + "} to {" + timezone + "}.");
        }
        if (user_role !== app.user_role && user_role !== ''){
            list.push("Changing " + app.first_name + "'s Role from {" + app.user_role + "} to {" + user_role + "}.");
        }
        if (diet_restriction !== app.diet_restriction){
            list.push("Changing " + app.first_name + "'s Dietary Restriction from {" + app.diet_restriction + "} to {" + diet_restriction + "}.");
        }
        if (social_url !== app.social_url && social_url !== ''){
            list.push("Changing " + app.first_name + "'s Social URL from {" + app.social_url + "} to {" + social_url + "}.");
        }
        if(list.length === 0){
            setError("No change was made!");
        } else {
            list.push("Are you sure you want to continue?");
            setActiveModal("None");
            setPrev("Attributes");
            setChangeList(list);
            setShowConfirmModal(true);
            
        }
    }

    const confirmTeamEdit = () => {
        let valid = false;
        if(team_code !== ''){ // check that its not empty and its not the same as before
            if(app.team){
                if(team_code !== app.team.team_code){
                    valid = true;
                }
            } else {
                valid = true;
            }
        }
        if(valid){
            setActiveModal("None");
            setPrev("Team");
            const list = [];
            api.getTeamInfo(team_code).then((newTeamInfo) => {
                if(newTeamInfo.team_location && app.team){ // use newTeamInfo.location to check if the new team exists
                    if(newTeamInfo.team_location !== app.location){
                        list.push("WARNING: This hacker's location (" + app.location + ") will not match their Team's location (" + newTeamInfo.team_location + ").");
                        list.push("Moving " + app.first_name + " from {" + app.team.team_name + "} to {" + newTeamInfo.team_name + "}.");
                    }
                } else if (app.team) {
                    list.push("Moving " + app.first_name + " from {" + app.team.team_name + "} to {" + newTeamInfo.team_name + "}.");
                } else {
                    list.push("Moving " + app.first_name + " to {" + newTeamInfo.team_name + "}.");
                }
                
                list.push("Are you sure you want to continue?");
                setChangeList(list);
                setShowConfirmModal(true);
            }).catch(err =>{
                setError("Internal Error! Try Again");
            });
        } else {
            setError("No change was made!");
        }
    }

    const confirmDelete = () => {
        const list = [];
        list.push("Are you sure you want to delete " + app.first_name + "?");
        list.push("This action cannot be undone!");
        setPrev("None");
        setChangeList(list);
        setShowConfirmModal(true);
    }

    const handleTeamChanger = () => {
        api.change_user_team(app.email, team_code)
            .then((res) => {
                console.log(res.status);
                setError('Success! ');
                getApp();
            })
            .catch((error) => {
                console.log(error);
                setError(error.errorMsg);
            }
        );
    }

    const handleStatusSave = () => {
        const status = statusEdit;
        const statusMap = {
            Accepted: APPLICATION_STATUS.ACCEPTED_NEED_RSVP,
            Rejected: APPLICATION_STATUS.REJECTED,
            Waitlisted: APPLICATION_STATUS.WAITLISTED,
        };
        api.updateApplicationById(
            id,
            JSON.stringify({ status: statusMap[status] })
        )
            .then((res) => {
                setError('Success! ');
                getApp();
            })
            .catch((error) => {
                setError(error.errorMsg);
            });
    };

    const handleAttributeEditSave = () => {
        api.updateApplicationById(id, JSON.stringify({ first_name, last_name, email, location, university, timezone, user_role, diet_restriction, social_url }))
            .then((res) => {
                setError('Success! ');
                // set all the states back to '' so the placeholder text goes back to grey
                getApp();
            })
            .catch((error) => {
                setError(error.errorMsg);
        });
        
    };

    const handleDeleteApplicant = () => {
        api.deleteApplicationById(id)
            .then(() => {
                alert('Success! ');
            })
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                alert(error.errorMsg);
            });
        props.history.push(`${ROUTES.ADMIN_DASHBOARD_HACKER_INFO}`);
    };

    const onBackClick = () => {
        props.history.push(`${ROUTES.ADMIN_DASHBOARD_HACKER_INFO}`);
    };

    const getApp = useCallback(() => {
        api.getApplicationById(id)
            .then((res) => {
                setApp(res.Applicant);
            })
            .catch((error) => {
                setError(error.errorMsg);
            });
    }, [api, id]);

    useEffect(() => {
        getApp();
    }, [getApp]);

    return (
        <>
            <DashboardNav />
            <div className="gs-dashboard-content">
                {app != null && (
                    <div className="gs-application-container p-3">
                        <Row className="m-3">
                            <Button className="mr-3" onClick={onBackClick}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </Button>
                            <h2>
                                <b>Applicant:</b> {app.first_name}{' '}
                                {app.last_name}
                            </h2>
                        </Row>
                        {/* Application Review */}
                        <Card className="mt-4 mb-4">
                            <Card.Header>
                                <span className="float-right">
                                    <Button
                                        variant="link"
                                        className="p-2 mx-1"
                                        onClick={acceptanceEditHandler}
                                    >
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                </span>
                                Application Review
                            </Card.Header>
                            <Card.Body>
                                <p>
                                    <b>Current Application Status:</b>{' '}
                                    <span
                                        className={statusColourMap(
                                            app.acceptance_status
                                        )}
                                    >
                                        {statusToText(app.acceptance_status)}
                                    </span>
                                </p>
                            </Card.Body>
                        </Card>


                        {/* Personal Information */}
                        <Card className="mb-4">
                            <Card.Header>
                                Personal Information
                                <span className="float-right">
                                    <Button
                                        variant="link"
                                        className="p-2 mx-1"
                                        onClick={attributeEditHandler}
                                    >
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                </span>
                            </Card.Header>

                            <Card.Body>
                                {/* Updated FEB 2024 */}
                                <p>
                                    <b>First Name:</b> {app.first_name}
                                </p>
                                <p>
                                    <b>Last Name:</b> {app.last_name}
                                </p>
                                <p>
                                    <b>Email:</b> {app.email}
                                </p>
                                <p>
                                    <b>Location:</b> {app.location}
                                </p>
                                <p>
                                    <b>University:</b> {app.university}
                                </p>
                                <p>
                                    <b>ID:</b> {app.application_id}
                                </p>
                                <p>
                                    <b>Time Zone:</b> {app.timezone}
                                </p>
                                <p>
                                    <b>Role:</b> {app.user_role}
                                </p>
                                <p>
                                    <b>Dietary Restrictions:</b>{' '}
                                    {app.diet_restriction}
                                </p>
                                <p>
                                    <b>Social URL:</b>{' '}
                                    {app.social_url}
                                </p>
                            </Card.Body>
                        </Card>
                        {/* Team Information */}
                        <Card className="mb-4">
                            <Card.Header>
                                Team Information
                                <span className="float-right">
                                    <Button
                                        variant="link"
                                        className="p-2 mx-1"
                                        onClick={teamEditHandler}
                                    >
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                </span>
                            </Card.Header>
                            <Card.Body>
                                {app.team ? (
                                    <>
                                        <p>
                                            <b>Team name:</b>{' '}
                                            {app.team.team_name}
                                        </p>
                                        <p>
                                            <b>Unique Team Code:</b>{' '}
                                            {app.team.team_code}
                                        </p>
                                    </>
                                ) : (
                                    'Applicant does not have a team'
                                )}
                            </Card.Body>
                        </Card>

                        {/* Delete Applicant */}
                        <Card className="mb-4">
                            <Card.Header className="text-danger font-weight-bold">
                                -- Danger Zone --
                            </Card.Header>
                            <Card.Body>
                                <p>
                                    Are you sure you want to delete this
                                    applicant? This action cannot be undone.
                                </p>
                                <Button onClick={confirmDelete}>
                                    Delete Applicant
                                </Button>
                            </Card.Body>
                        </Card>

                        {/* Edit Modal */}
                        <Modal
                            centered
                            show={activeModal === "Status"}
                            onHide={() => {
                                setActiveModal("None");
                            }}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {app.first_name} {app.last_name} Application Review
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    {error && (
                                        <p className="text-danger">{error}</p>
                                    )}
                                    <Form.Group>
                                        <Form.Label>
                                            Acceptance Status
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            defaultValue="Select Option"
                                            onChange={(e) => {
                                                setStatusEdit(e.target.value);
                                            }}
                                        >
                                            <option disabled>
                                                Select Option
                                            </option>
                                            <option>Accepted</option>
                                            <option>Waitlisted</option>
                                            <option>Rejected</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={confirmStatusEdit}>Save</Button>
                            </Modal.Footer>
                        </Modal>

                        {/* Edit Hacker Info Modal (Not Sanitized, temporary) */}
                        <Modal
                            centered
                            show={activeModal == "Attributes"}
                            onHide={() => {
                                setActiveModal("None");
                            }}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    Editing {app.first_name} {app.last_name}'s INFO
                                    (DANGER!!)
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {error && (
                                    <p className="text-danger">{error}</p>
                                )}
                                <Form.Group>
                                    <Form.Label>First Name:</Form.Label>
                                    <Form.Control
                                        className="form-input"
                                        value={first_name}
                                        type="text"
                                        placeholder={app.first_name}
                                        onChange={handleFirstNameChange}
                                    />
                                    <div className='divider'> </div>
                                    <Form.Label>Last Name:</Form.Label>
                                    <Form.Control
                                        className="form-input"
                                        value={last_name}
                                        type="text"
                                        placeholder={app.last_name}
                                        onChange={handleLastNameChange}
                                    />
                                    <div className='divider'> </div>
                                    <Form.Label>Email (try not to change to avoid duplicate emails!):</Form.Label>
                                    <Form.Control
                                        className="form-input"
                                        value={email}
                                        type="text"
                                        placeholder={app.email}
                                        onChange={handleEmailChange}
                                    />
                                    <div className='divider'> </div>
                                    <Form.Label>Location:</Form.Label>
                                    <Form.Control
                                        className="form-input"
                                        as="select"
                                        defaultValue={user_role}
                                        onChange={(e) => {
                                            setLocation(e.target.value);
                                        }}
                                    >
                                        <option disabled value="">
                                                Select Option
                                        </option>
                                        <option>OnlineGMT</option>
                                        <option>OnlineEST</option>
                                        <option>London</option>
                                        <option>Toronto</option>
                                    </Form.Control>
                                    <div className='divider'> </div>
                                    <Form.Label>University:</Form.Label>
                                    <Form.Control
                                        className="form-input"
                                        value={university}
                                        type="text"
                                        placeholder={app.university}
                                        onChange={handleUniversityChange}
                                    />
                                    <div className='divider'> </div>
                                    <Form.Label>Time Zone:</Form.Label>
                                    <Form.Control
                                        className="form-input"
                                        value={timezone}
                                        type="text"
                                        placeholder={app.timezone}
                                        onChange={handleTimeZoneChange}
                                    />
                                    <div className='divider'> </div>
                                    <Form.Label>Role in Team:</Form.Label>
                                    <Form.Control
                                        className="form-input"
                                        as="select"
                                        defaultValue={user_role}
                                        onChange={(e) => {
                                            setRole(e.target.value);
                                        }}
                                    >
                                        <option disabled value="">
                                                Select Option
                                        </option>
                                        <option>The Problem Detective</option>
                                        <option>The Entrepreneur</option>
                                        <option>The Financial Architect</option>
                                        <option>The Technical Alchemist</option>
                                    </Form.Control>
                                    <div className='divider'> </div>
                                    <Form.Label>Dietary Restrictions:</Form.Label>
                                    <Form.Control
                                        className="form-input"
                                        value={diet_restriction}
                                        type="text"
                                        placeholder={app.diet_restriction == null ? "None" : app.diet_restriction}
                                        onChange={handleDietResChange}
                                    />
                                    <div className='divider'> </div>
                                    <Form.Label>Social URL:</Form.Label>
                                    <Form.Control
                                        className="form-input"
                                        value={social_url}
                                        type="text"
                                        placeholder={app.social_url == null ? "None" : app.social_url}
                                        onChange={handleSocialURLChange}
                                    />
                                    <div className='divider'> </div>
                                </Form.Group>
                                {error && (
                                    <p className="text-danger">{error}</p>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={confirmAttributeEdit}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        {/* below is the team modal */}
                        <Modal
                            centered
                            show={activeModal === "Team"}
                            onHide={() => {
                                setActiveModal("None");
                            }}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {app.first_name} {app.last_name} Team Review
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {error && (
                                    <p className="text-danger">{error}</p>
                                )}
                                <Form.Group>
                                    <Form.Label>
                                        Team Code
                                    </Form.Label>
                                    <Form.Control
                                        className="form-input"
                                        value={team_code}
                                        type="text"
                                        onChange={handleTeamCodeChange}
                                    />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={confirmTeamEdit}>Save</Button> 
                            </Modal.Footer>
                        </Modal>

                        {/* below is the confirmation modal */}
                        <Modal
                            centered
                            show={showConfirmModal}
                            onHide={() => {
                                setShowConfirmModal(false);
                                setActiveModal(prev);
                            }}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    Confirm your changes
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group>
                                    {changeList.map((change, index) => (
                                    <Form.Label key={index}>
                                        {change}
                                    </Form.Label>
                                    ))}
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={confirm}>Confirm</Button> 
                            </Modal.Footer>
                        </Modal>
                    </div>
                )}
            </div>
        </>
    );
};

const condition = (authUser) =>
    !!authUser && authUser.perm_level === PERM_LEVELS.ADMIN;
export default compose(
    withRouter,
    withAPI,
    withAuthorization(condition)
)(AdminViewHackerPage);
