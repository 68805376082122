import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { withAPI } from "../../api";
import { PAGES_IMAGES, ROUTES } from "../../util";
import { compose } from "recompose";
import Header from "../../components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const ForgotPasswordPage = () => (
  <div>
    <Container fluid>
      <Row className="h-100 mt-auto mb-auto">
        <Col md={5} xl={4}>
          <Header />
          <div className="gs-form-container pr-5 pl-5">
            <Link to={ROUTES.LOGIN}>Back to Login</Link>
            <h3 className="pb-3 pt-2">Reset Password</h3>
            We'll Email You A Password Reset Link
            <ForgotPasswordForm />
          </div>
        </Col>
        <Col
          md={7}
          xl={8}
          className="bg-light-yellow h-100 p-0 d-none d-md-block"
        >
          <Image
            src={PAGES_IMAGES.password.default}
            className="gs-password-image"
          />
        </Col>
      </Row>
    </Container>
  </div>
);

const INITIAL_STATE = {
  email: "",
  error: null,
};

class ForgotPasswordFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.refs.btn.setAttribute("disabled", "disabled");

    const { email } = this.state;
    this.props.api
      .sendResetPasswordEmail(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.FORGET_PASSWORD_CONFIRM);
      })
      .catch((error) => {
        this.refs.btn.removeAttribute("disabled");
        this.setState({ error: error.errorMsg });
      });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === "";

    return (
      <Form onSubmit={this.onSubmit}>
        <div className="pt-5 pb-5">
          <Form.Group>
            <Form.Control
              name="email"
              value={email}
              onChange={this.onChange}
              type="email"
              placeholder="Email"
            />
          </Form.Group>
        </div>
        <Button ref="btn" className="mb-3" disabled={isInvalid} type="submit">
          Let's Go <FontAwesomeIcon icon={faArrowRight} />
        </Button>
        {error && <p>{error}</p>}
      </Form>
    );
  }
}

export default ForgotPasswordPage;

const ForgotPasswordForm = compose(withRouter, withAPI)(ForgotPasswordFormBase);
