/* 
Features: 
* general stats: # of undecided, accepted, waitlisted, rejected
* other stats: charts by countries, major, etc. 
* Upload statuses: update applicant statuses using an excel sheet (maybe send email too) 
*/

import React, { Component, useEffect } from "react";
import { Col, Container, Row, Form} from "react-bootstrap";
import { compose } from "recompose";
import { AuthUserContext, withAuthorization } from "../../session";
import { withAPI } from "../../api";
import DashboardNav from "../dashboard";
import { PERM_LEVELS,  APPLICATION_STATUS } from "../../util";
import { PieChart } from "react-minimal-pie-chart";
import { useState } from "react";
import ReactTooltip from "react-tooltip";

function Pie({ countData, title, colour = 360 }) {
  // [var, function to update var] = useState(initial state)
  const [selected, setSelected] = useState(undefined);
  const [hovered, setHovered] = useState(undefined);
  useEffect(() => {}, [countData]);

  let totalCount = 0;
  const data = countData.map((entry, i) => {
    // compute total count for calculating the % of total in the tooltip
    totalCount += entry["count"];

    if (hovered === i) {
      return {
        title: countData[i]["_id"],
        value: countData[i]["count"],
        color: "#ffcd45",
      };
    }
    return {
      title: countData[i]["_id"],
      value: countData[i]["count"],
      color: "hsl(" + (((colour * i) / 9) % 360) + "," + 50 + "%," + 65 + "%)",
    };
  });

  return (
    <div data-tip="" data-for="chart">
      <h3 style={{ justifyContent: "center", display: "flex" }}>{title}</h3>
      <PieChart
        data={data}
        radius={PieChart.defaultProps.radius - 7}
        lineWidth={60}
        segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
        // if segment index is hovered then shift segments by 6
        segmentsShift={(index) => (index === hovered ? 6 : 1)} // === strict equality
        animate
        animationDuration={1000}
        onClick={(_, index) => {
          // if index is currently being selected, deselect it by setting selected to undefined
          setSelected(index === selected ? undefined : index);
        }}
        onMouseOver={(_, index) => {
          setHovered(index);
        }}
        onMouseOut={() => {
          setHovered(undefined);
        }}
        style={{ height: "40vh" }}
      />
      <ReactTooltip
        id="chart"
        getContent={() =>
          hovered !== undefined
            ? `${data[hovered].title} | Count: ${data[hovered].value} (${
                (data[hovered].value * 100) / totalCount
              }%)`
            : null
        }
      />
    </div>
  );
}

const AdminHomePage = () => {
  return (
    <>
      <DashboardNav />
      <div className="gs-dashboard-content">
        <Container>
          <AdminHome />
        </Container>
      </div>
    </>
  );
};

const INITIAL_STATE = {
  accepted: 0,
  rejected: 0,
  undecided: 0,
  waitlisted: 0,
  error: null,
  pieError: null,
  statuses: [],
  //htgYear: new Date().getFullYear(),
  isLoading: false,
  pieDataUniversity: null,
  pieDataTimezone: null,
  pieDataLocation: null,
  animationKey: 0,
  selectedChart: "university",
  successCount: 0,
  errEmails: null,
};


class AdminHomeBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  // built-in function that runs everytime when loading
  componentDidMount() {
    const reviewCounts = {
      accepted: 0,
      rejected: 0,
      waitlisted: 0,
      undecided: 0,
    };
    const year = this.state.htgYear;
    this.setState(reviewCounts);

    this.getPieData(year, "university", "pieDataUniversity");
    this.getPieData(year, "location", "pieDataLocation");
    this.getPieData(year, "timezone", "pieDataTimezone");
    this.getProgressData(year);
    console.log("HTG year", this.state.htgYear);

    // this.props.firebase.applications().once('value').then(snapshot => {
    //     var reviewCounts = {
    //     accepted: 0,
    //     rejected: 0,
    //     undecided: 0,
    //     waitlisted: 0
    //     };
    //     const data = snapshot.val();
    //     for (const app in data) {
    //     reviewCounts[data[app].acceptanceStatus] += 1;
    //     }
    //     this.setState(reviewCounts);
    // });
  }
  handleChartChange = (e) => {
    this.setState({ 
      selectedChart: e.currentTarget.value,
    });
  };
  
  handleYearChange = (e) => {
    const year = e.currentTarget.value;
    this.setState({ htgYear: year });
    this.getPieData(year, "major", "pieDataMajor");
    this.getPieData(year, "country", "pieDataCountry");
    this.getPieData(year, "gender", "pieDataGender");
    this.getProgressData(year);
  };

  handleFileSubmit = () => {
    this.setState({ isLoading: true });
    const csvData = this.state.statuses;
    if (csvData?.length === 0 || !this.validateHeader(csvData[0])) {
      this.setState({
        error:
          "File format is incorrect, please double check your file and try again",
      });
    } else {
      this.props.api
        .updateApplicantionStatuses(csvData)
        .then((res) => {
          this.setState({
            error: null,
            successCount: res.successCount,
            errEmails: res.errEmails,
          });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ error: error.errorMsg });
        });
    }
    this.setState({ isLoading: false });
  };

  validateHeader = (row) => {
    return (
      row.hasOwnProperty("response_id") &&
      row.hasOwnProperty("email") &&
      row.hasOwnProperty("first_name") &&
      row.hasOwnProperty("last_name") &&
      row.hasOwnProperty("acceptance_status") &&
      row.hasOwnProperty("location")
    );
  };

  getPieData = (year, group, pieDataState) => {
    const parsedYear = !isNaN(parseInt(year)) ? year : undefined;
    this.props.api
      .getPieData(group, parsedYear)
      .then((res) => {
        // using state to resolve return "undefined" async issue
        this.setState({ [pieDataState]: res.PieData });
      })
      .catch((error) => {
        console.log("getPieData(): Error occured when getting pie data.");
        this.setState({ pieError: "getPieData() returns error." });
      });
  };

  getProgressData = (year) => {
    const parsedYear = !isNaN(parseInt(year)) ? year : undefined;
    this.props.api
      .getProgressData(parsedYear)
      .then((res) => {
        let data = res.ProgressData[0];
        // Replace undefined values with 0
        for (const status in APPLICATION_STATUS) {
          if (data[status] === undefined) data[status] = 0;
        }

        // Application status Mapping
        this.setState({
          accepted:
            data.ACCEPTED_NEED_RSVP +
            data.ACCEPTED_RSVP_YES +
            data.ACCEPTED_RSVP_NO,
        });
        this.setState({ rejected: data.REJECTED });
        this.setState({ waitlisted: data.WAITLISTED });
        this.setState({ undecided: data.SUBMITTED });
        this.setState({ expired: data.ACCEPTED_OFFER_EXPIRED });
      })
      .catch((error) => {
        console.log(`getProgressData(): Error occured when getting the applicant progress data.`,);
      });
  };

  render() {
    const { selectedChart, pieDataUniversity, pieDataLocation, pieDataTimezone, animationKey } = this.state;

    const renderSelectedChart = () => {
      const key = `${selectedChart}-${animationKey}`;
      switch (selectedChart) {
        case "university":
          return pieDataUniversity && (
            <Pie
              key={key}
              countData={pieDataUniversity}
              title="University Breakdown"
            />
          );
        case "location":
          return pieDataLocation && (
            <Pie
              key={key}
              countData={pieDataLocation}
              title="Location Breakdown"
            />
          );
        case "timezone":
          return pieDataTimezone && (
            <Pie
              key={key}
              countData={pieDataTimezone}
              title="Time Zone Breakdown"
            />
          );
        default:
          return null;
      }
    };


    return (
      <AuthUserContext.Consumer>
        {({ authUser, updateAuthUser }) => (
          <>
            <div className="p-2">
              <h3 className="pb-5 text-center">Hey, {authUser.first_name}!</h3>
              <Row className="pb-3"> 
                <Form.Group as={Row}>
                    <Form.Label column sm="5">
                        Pie Chart
                    </Form.Label>
                    <Col sm="7">
                        <Form.Control
                            className="form-input"
                            name="user_role"
                            as="select"
                            value={selectedChart}
                            onChange={this.handleChartChange}
                        >
                            <option value="university">University</option>
                            <option value="timezone">Time Zone</option>
                            <option value="location">Location </option>
                        </Form.Control>
                    </Col>
                  </Form.Group>
              </Row>
              <Row className="pb-3">
                <Col>
                  {renderSelectedChart()}
                </Col>
                {this.state.pieError && (
                  <p className="text-danger"> {this.state.pieError} </p>
                )}
              </Row>
              <h5 className="pb-3">Application Review Progress: </h5>
              <Row className="pb-3">
                <Col xs={3}>
                  <h5 className="text-success text-center">
                    {this.state.accepted} Accepted
                  </h5>
                </Col>
                <Col xs={3}>
                  <h5 className="text-warning text-center">
                    {this.state.waitlisted} Waitlisted
                  </h5>
                </Col>
                <Col xs={3}>
                  <h5 className="text-warning text-center">
                    {this.state.expired} Offer Expired
                  </h5>
                </Col>
                <Col xs={3}>
                  <h5 className="text-danger text-center">
                    {this.state.rejected} Rejected
                  </h5>
                </Col>
              </Row>
              <h4 className="pb-3">
                {this.state.undecided +
                  this.state.accepted +
                  this.state.waitlisted +
                  this.state.rejected +
                  this.state.expired}{" "}
                Total Applications
              </h4>
              <h4 className="pb-3">{this.state.undecided} Not Yet Reviewed</h4>
            </div>
            <hr />
          </>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser && authUser.perm_level === PERM_LEVELS.ADMIN;

const AdminHome = compose(withAPI, withAuthorization(condition))(AdminHomeBase);

export default AdminHomePage;
