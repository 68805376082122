/** @format */

import React, {useState} from 'react';
import {
    Modal,
    ModalBody,
    Button,
    Dropdown,
    FormControl,
} from 'react-bootstrap';
import { Component } from 'react';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { withAPI } from '../../../api';
import { PAGES_IMAGES } from '../../../util';
import { compose } from 'recompose';
import {SearchableDropdown} from './searchableDropdown.js';

const INITIAL_STATE = {
    name:'',
    judgeList:[]
};

class Popup extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onChange = (event) => {
        if (event.target.value === '') {
            this.setState({
                error: 'Please complete all fields before continuing',
            });
        } else {
            this.setState({ error: '' });
        }
        this.setState({ [event.target.name]: event.target.value });
    };

    componentDidMount = () => {
        this.props.api.getJudgeNameList()
        .then((res) => {
            this.setState({judgeList: res.body})
        })
    }

    render() {
        
        const handleSelectionChange = (value) => {
            console.log('Selected value:', value);
            this.props.judgeNameSetter(value);
            
        };

        return (
            <Modal show centered size="xl" className="waitlisted no-transition">
                <ModalHeader className="welcome"> 
                    Welcome, Judges!
                </ModalHeader>
                <ModalBody>
                    <div className="popup-content-1">
                        Please select your name below, then enter your password to proceed with the judging process.
                        <p/>
                        
                        <SearchableDropdown onSelect={handleSelectionChange} judgeList={this.state.judgeList} />

                        {this.props.displayCancelButton &&
                            <Button
                                className="btn-red"
                                onClick={()=>{
                                    this.props.renderHomePage();
                                }}
                            >
                                Cancel
                            </Button>

                        }
                    </div>
                </ModalBody>
                <img
                    className="waitlist-img"
                    src={PAGES_IMAGES.no_team_bg.default}
                ></img>
            </Modal>
        );
    }
}


const PopupRegistration = compose(withAPI)(Popup);

export default PopupRegistration;
