import React, { useState } from "react";
import "../assets/style/drop-file-input.css";

const AddLinkPopup = ({ onClose, onAddLink, team, submitter }) => {
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");

  const handleSubmitLink = (e) => {
    e.preventDefault();
    if (url && title) {
      const linkData = { url, title, note, team, submitter };
      onAddLink(linkData);
      onClose();
    } else {
      alert("URL and Title are required.");
    }
  };

  return (
    <div className="popup-overlay" onClick={(e) => {
      if (e.target.classList.contains("popup-overlay")) {
        onClose();
      }
    }}>
      <div className="popup-box">
        <div className="popup-header">
          <h2>Add Link</h2>
          <button className="close-btn" onClick={onClose}>×</button>
        </div>
        <form onSubmit={handleSubmitLink}>
          <div className="form-group">
            <label>URL:</label>
            <input
              type="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="http://"
              required
            />
          </div>
          <div className="form-group">
            <label>Title:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Title"
              required
            />
          </div>
          <div className="form-group">
            <label>Note:</label>
            <textarea
              value={note}
              onChange={(e) => setNote(e.target.value)}
              placeholder="Note (optional)"
            />
          </div>
          <div className="popup-actions">
            <button type="button" className="cancel-btn" onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className="submit-btn">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddLinkPopup;