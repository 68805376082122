/*
Do some kind of processing with the raw scores based on the specification from the HTG team.

Returns an integer which is the processed score.

data is a list of "Rubric" objects which can be further parsed
*/
const processScore = (data, pp_points) => {
    let running_total = 0;
    for (const rubric of data){
        if (rubric.scores){
            for (const score of rubric.scores){
                running_total += +score;
            }
        }
    }

    running_total = (running_total / data.length) + pp_points
    

    if (isNaN(running_total)) {
        return 0
    }
    running_total = parseFloat(running_total.toFixed(2));
    return running_total
}

export default (processScore)