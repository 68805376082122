/**
 * Features:
 * search bar
 * view all applicants
 * view and edit a given applicants application status (TODO: get typeform response for all data including resume)
 * view all teams
 * view and edit specific team (link to typeform responses)
 *
 * @format
 */

//TODO: make teams page

import React, { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withAPI } from '../../api';
import { PERM_LEVELS, APPLICATION_STATUS, ROUTES } from '../../util';
import { withAuthorization } from '../../session';
import DashboardNav from '../dashboard';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, {
    CSVExport,
    Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import YearSelectDropDown from '../../components/YearSelectDropDown';
import processScore from './util/scoreAlgorithm'
import ScorePopup from './scorePopup';
import { PAGES_IMAGES } from '../../util';

import Papa from 'papaparse';

const { SearchBar } = Search;

const { ExportCSVButton } = CSVExport;

const AdminJudging = (props) => {
    const { api } = props;
    const [allTeams, setAllTeams] = useState([]);
    const [filteredTeams, setFilteredTeams]=useState([]);
    const [displayedTeams, setDisplayedTeams] = useState([]);
    const [error, setError] = useState(null);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [file, setFile] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [displayScorePopup, setDisplayScorePopup] = useState(false);
    const [currentTeamCode, setCurrentTeamCode]=useState(null);
    const [filterBy, setFilterBy]=useState(null);
    const [sortBy, setSortBy]=useState(null);
    const [accordionOpen, setAccordionOpen]=useState(false);

    useEffect(() => {
        const getTeams = () => {
            let teamsData = [];
            api.getTeams()
                .then((res) => {
                    console.log(res);
                    const Teams = res.Teams;
                    for (let Team of Teams) {
                        const team = {};
                        team['team_name'] = Team['team_name'];
                        team['team_code'] = Team['team_code'];
                        team['theme'] = themeToText(Team['theme']);
                        team['location'] = Team['team_location'];
                        team['team_members'] = Team.team_members .map( (member) =>
                            `${member.first_name} ${member.last_name}`
                        ).join(', ');
                        team['id'] = Team['_id'];
                        team['processed_score'] = processScore(Team['scores'],Team['passport_points']);
                        teamsData.push(team);
                    }
                    teamsData.sort((a,b)=>b.processed_score - a.processed_score);
                    setAllTeams(teamsData);
                    setDisplayedTeams(teamsData);
                })
                .catch((error) => {
                    setError(error.errorMsg);
                });
        };
        getTeams();
    }, [api, currentYear]);

    const handleAccordionToggle = () => {
        setAccordionOpen(!accordionOpen);
    };

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total" style={{marginLeft:"15px"}}>
            Showing {from} to {to} of {size} Results
        </span>
    );

    
    useEffect(()=>{
        const locations = [
            'Toronto', 'London', 'OnlineGMT', 'OnlineEST',
        ];
        const themes = [
            'Sustainable Infrastructure', 'Economic Empowerment', 'Health & Humanity',
        ]
        console.log(filterBy);        
        let teamsTemp = allTeams;
        if(locations.includes(filterBy)){
            teamsTemp = teamsTemp.filter(team=>team.location === filterBy);
        }
        else if(themes.includes(filterBy)){
            teamsTemp = teamsTemp.filter(team=>team.theme === filterBy);
        }
        console.log(teamsTemp);
        setDisplayedTeams(teamsTemp);

    },[filterBy])

    const teamRowEvents = {
        onClick: (e, row, rowIndex) => {
            console.log(`clicked on row with index: ${row.id}`);
            setCurrentTeamCode(row.team_code);
            console.log(currentTeamCode);
            setDisplayScorePopup(true);
            //   props.history.push(`${ROUTES.ADMIN_DASHBOARD_HACKER_INFO}/${row.id}`);
        },
    };

    // Map to new themes - temporary
    const themeToText = (theme) => {
        if (theme === 'Economic Advancement') return 'Economic Empowerment';
        if (theme === 'Sustainable Societies')
            return 'Sustainable Infrastructure';
        if (theme === 'Health & Humanity') return 'Health & Humanity';
        return 'Unselected';
    };

    const teamColumns = [
        { text: 'Score (incl. Passport Points)', dataField: 'processed_score' },
        { text: 'Team Code', dataField: 'team_code'},
        { text: 'Theme', dataField: 'theme' },
        { text: 'Location', dataField: 'location' },
    ];

    const teamOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {text: '20',value: 20,},
            {text: '50',value: 50,},
            {text: 'All',value: displayedTeams.length,},
        ],
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
        alert('Please select a file first!');
        return;
    }

    const validateHeader = (arr) => {
        return (
          arr[0].toLowerCase() == "name" &&
          arr[1].toLowerCase() == "password"
        );
    };

    var result = window.confirm(
        "Are you sure you want to overwrite the judge CSV with the chosen csv? "
    );
    if(result){
        Papa.parse(file, {
            complete: async (results) => {
                const csvData = results.data;
                setLoading(true);
                if(validateHeader(csvData[0])){
                    Papa.parse(file, {
                        header: true,
                        complete: async (results) => {
                            const newData = results.data;
                            api.updateJudgeCSV(newData)
                            .then((res) => {
                                setLoading(false);
                                setDisplaySuccess(true);
                                console.log("Finished")
                            })
                            .catch((error) => {
                               console.log(error)
                            });
                        }
                    })
                } else {
                    alert('The format of the .csv is wrong! Please follow the format of current Judge.csv file');
                }
            },
        });
    };
    }

    const closeModal=()=>{
        setDisplayScorePopup(false);
    }

    return (
        <>
            <DashboardNav />
            <div className="gs-dashboard-content">
                {displayScorePopup && <ScorePopup closeModal={closeModal} teamCode={currentTeamCode}/>}
                
                {error && <p className="text-red"> {error} </p>}


                <div className="judging-buttons">
                    <form onSubmit={handleSubmit}>
                        <input type="file" onChange={handleFileChange} accept=".csv" />
                        <button type="submit" disabled = {isLoading}>{isLoading ? "Updating..." : "Submit Judge CSV"}</button>
                        {displaySuccess && <span>CSV Updated</span>}
                    </form>
                    <div className="people-header mb-5">
                        <div className="display-flex buttons-container">
                            <FilterByButton setFilterBy={setFilterBy} />
                        </div>
                    </div>
                </div>

                <div className="gs-applications-container w-100 p-3" style = {{wordBreak: 'break-all'}}>
                    <ToolkitProvider
                        keyField="id"
                        data={displayedTeams}
                        columns={teamColumns}
                        search
                        exportCSV
                    >
                        {(props) => (
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <SearchBar
                                        {...props.searchProps}
                                        placeholder="Search teams"
                                    />
                                
                                    <ExportCSVButton
                                            {...props.csvProps}
                                            style={{ color: 'white'}}
                                        >
                                            Download CSV
                                    </ExportCSVButton>
                                </div>
                                
                                <BootstrapTable
                                    {...props.baseProps}
                                    pagination={paginationFactory(teamOptions)}
                                    rowEvents={teamRowEvents}
                                    hover
                                />
                            </div>
                        )}
                    </ToolkitProvider>
                </div>
            </div>
        </>
    );
};

const FilterByButton = ({ setFilterBy }) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const dropdownRef = useRef(null);
    const buttonRef=useRef(null);

    const handleButtonClick = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleRadioChange = (event, option) => {
        handleFilterByChange(option);
        handleOptionChange(event);
    };

    const handleFilterByChange = (option) => {
        // changes if the data should be filtered or not be filtered
        setFilterBy((prevFilterBy) =>
            prevFilterBy === option ? null : option
        );
    };

    const handleOptionChange = (event) => {
        // changes radio button to be shown or not shown
        if (selectedOption === event.target.value) {
            setSelectedOption('');
        } else {
            setSelectedOption(event.target.value);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    return (
        <div className={`sortby-button ${isDropdownOpen ? 'open' : ''}`}>
            <button
                className={`dropdown-button ${
                    isDropdownOpen ? 'dropdown-button-radius' : ''
                }`}
                onClick={handleButtonClick}
                ref={buttonRef}
            >
                Filter By
            </button>
            {isDropdownOpen && (
                <div className="dropdown-filterby" ref={dropdownRef}>
                    <DropdownItem name='Toronto' handleRadioChange = {handleRadioChange} selectedOption = {selectedOption}></DropdownItem>
                    <DropdownItem name='London' handleRadioChange = {handleRadioChange} selectedOption = {selectedOption}></DropdownItem>
                    <DropdownItem name='OnlineGMT' handleRadioChange = {handleRadioChange} selectedOption = {selectedOption}></DropdownItem>
                    <DropdownItem name='OnlineEST' handleRadioChange = {handleRadioChange} selectedOption = {selectedOption}></DropdownItem>
                    <DropdownItem name='Economic Empowerment' handleRadioChange = {handleRadioChange} selectedOption = {selectedOption}></DropdownItem>
                    <DropdownItem name='Sustainable Infrastructure' handleRadioChange = {handleRadioChange} selectedOption = {selectedOption}></DropdownItem>
                    <DropdownItem name='Health & Humanity' handleRadioChange = {handleRadioChange} selectedOption = {selectedOption}></DropdownItem>
                </div>
            )}
        </div>
    );
};

const DropdownItem = ({name, handleRadioChange, selectedOption})=>{
    return(
    <label className="display-flex">
            <input
                type="radio"
                name="filterOption"
                value={name}
                checked={selectedOption === name}
                onClick={handleRadioChange}
                onChange={(event) =>
                    handleRadioChange(event, name)
                }
                className="mt-3 ml-3 radio-circle"
            />
            <p className="radio-text1">{name}</p>
        </label>
    );
}


const condition = (authUser) =>
    !!authUser && authUser.perm_level === PERM_LEVELS.ADMIN;
export default compose(
    withRouter,
    withAPI,
    withAuthorization(condition)
)(AdminJudging);
