import { API_BASE_URL } from "../config";

const API_BASE_URL_USER = API_BASE_URL + "/user";
const API_BASE_URL_HACKER = API_BASE_URL + "/hacker";
const API_BASE_URL_ADMIN = API_BASE_URL + "/admin";
const API_BASE_URL_JUDGE = API_BASE_URL + "/judge";
const API_BASE_URL_FILE = API_BASE_URL + "/file";

const HEADERS = {
  "Content-Type": "application/json",
};

const toJson = (response) => response.json();

const handleErrors = (response) => {
  if (response.status !== 200) throw response;
  return response;
};

class API {
  currentUser = () =>
    fetch(API_BASE_URL_USER + "/current", {
      method: "GET",
      credentials: "include",
    })
      .then(toJson)
      .then(handleErrors);

  register = (
    email,
    password,
    first_name,
    last_name,
    acceptance_status,
    hub,
    adminCode,
  ) =>
    fetch(API_BASE_URL_USER + "/register", {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify({
        email,
        password,
        first_name,
        last_name,
        acceptance_status,
        hub,
        adminCode,
      }),
    })
      .then(toJson)
      .then(handleErrors);

  getUploadedFilesForTeam = (teamCode) => {
    return fetch(`${API_BASE_URL_FILE}/team/${teamCode}`, {
      method: "GET",
      credentials: "include",
    })
      .then(handleErrors)
      .then(toJson)
      .then(data => {
        console.log("Retrieve files for team successful, response data:", data);
        return data;
      });
  };

  
  deleteFile = (fileId) => {
    return fetch(`${API_BASE_URL_FILE}/delete/${fileId}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then(handleErrors)
      .then(toJson)
      .then((data) => {
        console.log(`File with ID ${fileId} deleted successfully, response data:`, data);
        return data;
      });
  };


  submitFiles = (files, team, submitter) => {
    const formData = new FormData();
  
    files.forEach((file) => {
      formData.append("files", file);
    });
  
    formData.append("teamObject", JSON.stringify(team));
    formData.append("userObject", JSON.stringify(submitter));
  
    console.log("Team:", team);
    console.log("Files submitted by:", submitter);
  
    return fetch(API_BASE_URL_FILE + "/upload/file", {
      method: "POST",
      body: formData,
      credentials: "include",
    })
      .then(handleErrors)
      .then(toJson)
      .then((data) => {
        console.log("Upload successful, response data:", data);
        return data;
      });
  };

  submitLink = (link, team, submitter) => {
    const payload = {
      url: link.url,
      title: link.title,
      note: link.note,
      teamObject: JSON.stringify(team),
      userObject: JSON.stringify(submitter),
    };
  
    return fetch(`${API_BASE_URL}/file/upload/link`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Link submitted successfully:", data);
        return data;
      });
  };

  deleteLink = (linkId) => {
    return fetch(`${API_BASE_URL_FILE}/delete-link/${linkId}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then(handleErrors)
      .then(toJson)
      .then((data) => {
        console.log(`Link with ID ${linkId} deleted successfully, response data:`, data);
        return data;
      });
  };

  getUploadedFilesAndLinksForTeam = (teamCode) => {
    return fetch(`${API_BASE_URL}/file/team/${teamCode}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Files and links retrieved successfully:", data);
        return data;
      });
  };
          
  login = (email, password) =>
    fetch(API_BASE_URL_USER + "/login", {
      method: "POST",
      credentials: "include",
      headers: HEADERS,
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then(toJson)
      .then(handleErrors);

  logout = () =>
    fetch(API_BASE_URL_USER + "/logout", {
      method: "POST",
      credentials: "include",
    })
      .then(toJson)
      .then(handleErrors);

  sendResetPasswordEmail = (email) =>
    fetch(API_BASE_URL_USER + "/forgot-password", {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify({ request_email: email }),
    })
      .then(toJson)
      .then(handleErrors);

  resetPassword = (password, key) =>
    fetch(API_BASE_URL_USER + `/reset-password/${key}`, {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify({ new_password: password }),
    })
      .then(toJson)
      .then(handleErrors);

  sendVerificationEmail = (email) =>
    fetch(API_BASE_URL_USER + "/send-email-verification", {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify({ email }),
    })
      .then(toJson)
      .then(handleErrors);

  verifyEmail = (key) =>
    fetch(API_BASE_URL_USER + `/verify-email/${key}`, {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify({}),
    })
      .then(toJson)
      .then(handleErrors);

  updateUser = (body) =>
    fetch(API_BASE_URL_USER + "/update-user", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify(body),
    })
      .then(toJson)
      .then(handleErrors);

  getUsers = () =>
    fetch(API_BASE_URL_USER + "/get-users", {
      method: "GET",
      credentials: "include",
    })
      .then(toJson)
      .then(handleErrors);

  hackerGetTeam = () =>
    fetch(API_BASE_URL_HACKER + "/get-team", {
      method: "GET",
      credentials: "include",
    })
      .then(toJson)
      .then(handleErrors);

  createTeam = (team_name) =>
    fetch(API_BASE_URL_HACKER + "/create-team", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({ team_name }),
    })
      .then(toJson)
      .then(handleErrors);

  joinTeam = (team_code) =>
    fetch(API_BASE_URL_HACKER + "/join-team", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({ team_code }),
    })
      .then(toJson)
      .then(handleErrors);

  leaveTeam = () =>
    fetch(API_BASE_URL_HACKER + "/leave-team", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({}),
    })
      .then(toJson)
      .then(handleErrors);

  updateTeam = (body) =>
    fetch(API_BASE_URL_HACKER + "/update-team", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify(body),
    })
      .then(toJson)
      .then(handleErrors);
  
  changeTeamJudgingPanel = (newSelection) => 
    fetch(API_BASE_URL_HACKER + "/update-team-panel", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({ newSelection }),
    })
      .then(toJson)
      .then(handleErrors);
  

  getPassportPoints = (team_code) => 
    fetch(API_BASE_URL_HACKER + "/get-pp-points", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({team_code}),
    })
    .then(toJson)
    .then(handleErrors);

  // ******************* Admin API Calls *********************************
  getApplications = (year = undefined) =>
    fetch(
      API_BASE_URL_ADMIN + "/get-applications" + (year ? "?year=" + year : ""),
      {
        method: "GET",
        credentials: "include",
      },
    )
      .then(toJson)
      .then(handleErrors);

  getTeams = (year = undefined) =>
    fetch(API_BASE_URL_ADMIN + "/get-teams" + (year ? "&year=" + year : ""), {
      method: "GET",
      credentials: "include",
    })
      .then(toJson)
      .then(handleErrors);

  getPieData = (group, year = undefined) =>
    fetch(
      API_BASE_URL_ADMIN +
        "/get-pie-data?group=" +
        group +
        (year ? "&year=" + year : ""),
      {
        method: "GET",
        credentials: "include",
      },
    )
      .then(toJson)
      .then(handleErrors);

  getProgressData = (year = undefined) =>
    fetch(
      API_BASE_URL_ADMIN + "/get-progress-data" + (year ? "?year=" + year : ""),
      {
        method: "GET",
        credentials: "include",
      },
    )
      .then(toJson)
      .then(handleErrors);

  getApplicationById = (id) =>
    fetch(API_BASE_URL_ADMIN + "/get-application-by-id?id=" + id, {
      method: "GET",
      credentials: "include",
    })
      .then(toJson)
      .then(handleErrors);

  getTeamInfo = (team_code) =>
    fetch(API_BASE_URL_ADMIN + "/get-team-info?team_code=" + team_code, {
      method: "GET",
      credentials: "include",
    })
      .then(toJson)
      .then(handleErrors);

  updateApplicationById = (id, key_val) =>
    fetch(API_BASE_URL_ADMIN + "/update-application-by-id", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({ id, key_val }),
    })
      .then(toJson)
      .then(handleErrors);

  updateApplicantionStatuses = (csvData) =>
    fetch(API_BASE_URL_ADMIN + "/update-application-statuses", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({ csvData }),
    })
      .then(toJson)
      .then(handleErrors);

  deleteApplicationById = (id) =>
    fetch(API_BASE_URL_ADMIN + "/delete-application-by-id", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({ id }),
    })
      .then(toJson)
      .then(handleErrors);

  generateTeamCode = () =>
    fetch(API_BASE_URL_ADMIN + "/generate-team-code", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
    })
      .then(toJson)
      .then(handleErrors);

  //this is if the file is sent from the front end
  updateTeamFormation = (csvData) =>
    fetch(API_BASE_URL_ADMIN + "/update-team-formation", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({ csvData }),
    })
      .then(toJson)
      .then(handleErrors);

  change_user_team = (user_email, team_code) =>
    fetch(API_BASE_URL_ADMIN + "/change-user-team", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({ user_email, team_code }),
    })
      .then(toJson)
      .then(handleErrors);
  
  updateJudgeCSV = (data) => 
    fetch(API_BASE_URL_ADMIN + "/update-judge-csv", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({ data }),
    })
      .then(toJson)
      .then(handleErrors);

  updateTeamMentor = (csvData) => 
    fetch(API_BASE_URL_ADMIN + "/update-team-mentor", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({ csvData }),
    })
      .then(toJson)
      .then(handleErrors);

  updatePassportPoints = (csvData) => 
    fetch(API_BASE_URL_ADMIN + "/update-pp-points", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({ csvData }),
    })
      .then(toJson)
      .then(handleErrors);
  
    newUser = (
    firstname,
    lastname,
    email,
    password,
    acceptance_status,
    linkedin,
    university,
    location,
    user_role,
    timezone,
    id,
    is_tester,
    is_admin,
    food
  ) => 
    fetch(API_BASE_URL_ADMIN + "/new-user", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({ 
        firstname,
        lastname,
        email,
        password,
        acceptance_status,
        linkedin,
        university,
        location,
        user_role,
        timezone,
        id,
        is_tester,
        is_admin,
        food
      }),
    })
      .then(toJson)
      .then(handleErrors);

  deleteAllTesterUsers = () => 
    fetch(API_BASE_URL_ADMIN + "/delete-all-tester-users", {
      method: "GET",
      credentials: "include",
    })
      .then(toJson)
      .then(handleErrors);


   // ******************* Judge API Calls *********************************
   submit_score = (team_code, judge, scores, comments) => 
    fetch(API_BASE_URL_JUDGE + "/submit-score", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({team_code, judge, scores, comments}),
    })
    .then(toJson)
    .then(handleErrors);

  getScore = (team_code, judge)=>
  fetch(API_BASE_URL_JUDGE + "/get-score", {
    method: "POST",
    headers: HEADERS,
    credentials: "include",
    body: JSON.stringify({team_code, judge}),
  })
  .then(toJson)
  .then(handleErrors);

  getPassword = (judge) => 
  fetch(API_BASE_URL_JUDGE + "/get-password", {
    method: "POST",
    headers: HEADERS,
    credentials: "include",
    body: JSON.stringify({judge}),
  })
  .then(toJson)
  .then(handleErrors);

  getJudgeNameList= () => 
  fetch(API_BASE_URL_JUDGE + "/get-judges", {
    method: "GET",
    headers: HEADERS,
    credentials: "include",
  })
  .then(toJson)
  .then(handleErrors);

  getSubmittedRubrics= (judge) => 
  fetch(API_BASE_URL_JUDGE + "/get-submitted-rubrics", {
    method: "POST",
    headers: HEADERS,
    credentials: "include",
    body: JSON.stringify({judge}),
  })
  .then(toJson)
  .then(handleErrors);

  getTeamScores = (team_code) => 
    fetch(API_BASE_URL_JUDGE + "/get-team-scores", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({team_code}),
    })
    .then(toJson)
    .then(handleErrors);


}

export default API;

/*

// Removed during 2024 refactor, pitch_deck_link is deprecated and the function is never called
getTypeformSubmissionFile = (pitch_deck_link) =>
  fetch(
    API_BASE_URL_HACKER +
      "/get-submission?pitch_deck_link=" +
      encodeURIComponent(pitch_deck_link),
    {
      method: "GET",
      credentials: "include",
    }
  ).then((data) => data.blob());

*/

// rendered useless with the front end submission
  // backendTeamFormation = () =>
  //   fetch(API_BASE_URL_ADMIN + "/backend-team-formation", {
  //     method: "POST",
  //     headers: HEADERS,
  //     credentials: "include",
  //   })
  //     .then(toJson)
  //     .then(handleErrors);