import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { compose } from "recompose";

import AuthUserContext from "./context";
import { withAPI } from "../api";
import { ROUTES } from "../util";

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    // componentDidMount() {
    //     this.props.api.currentUser(data => {
    //         if (!condition(data.User)) {
    //             this.props.history.push(ROUTES.LOGIN);
    //         }
    //     }).catch(() => {
    //         this.props.history.push(ROUTES.LOGIN);
    //     });
    // }

    render() {
      return (
        <AuthUserContext.Consumer>
          {({ authUser, updateAuthUser }) =>
            condition(authUser) ? (
              <Component {...this.props} />
            ) : (
              <Redirect to={ROUTES.LOGIN} />
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return compose(withRouter, withAPI)(WithAuthorization);
};

export default withAuthorization;
