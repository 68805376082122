/** @format */

// This file is is the nav bar of the dashboard

import React from 'react';
import { Nav, Navbar, Row, Image } from 'react-bootstrap';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCog,
    faFolder,
    faHome,
    faClipboardCheck,
    faUsers,
    faGavel,
    faUserFriends,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
// import { faUser } from '@fortawesome/free-regular-svg-icons';

import { AuthUserContext, withAuthorization } from '../../session';
import Header from '../../components/Header';
import LogOutButton from '../logout';
import { NavLink } from 'react-router-dom';
import {
    ROUTES,
    SOCIAL_MEDIA_IMAGES,
    PERM_LEVELS,
    APPLICATION_STATUS,
} from '../../util';

const DashboardNav = () => {
    return (
        <AuthUserContext.Consumer>
            {({ authUser, updateAuthUser }) => (
                <div>
                    <div className="gs-dashboard-nav mt-auto mb-auto bg-light-blue p-0 d-none d-lg-block">
                        <Header />
                        <Nav variant="pills" className="flex-column pt-5">
                            {authUser.perm_level === PERM_LEVELS.ADMIN && (
                                <>
                                    <h3 className='nav-link'>
                                        Admin
                                    </h3>
                                    <NavLink
                                        className="nav-link"
                                        activeClassName="active"
                                        to={ROUTES.ADMIN_DASHBOARD_HOME}
                                    >
                                        <FontAwesomeIcon icon={faHome} /> Home
                                    </NavLink>
                                    <NavLink
                                        className="nav-link"
                                        activeClassName="active"
                                        to={ROUTES.ADMIN_DASHBOARD_HACKER_INFO}
                                    >
                                        <FontAwesomeIcon
                                            icon={faClipboardCheck}
                                        />{' '}
                                        Hacker Infos
                                    </NavLink>
                                    <NavLink
                                        className="nav-link"
                                        activeClassName="active"

                                        to={ROUTES.ADMIN_DASHBOARD_JUDGING}
                                    >
                                        <FontAwesomeIcon
                                            icon={faGavel}
                                        />{' '}
                                        Judging
                                    </NavLink>
                                    <NavLink
                                        className="nav-link"
                                        activeClassName="active"
                                        to={ROUTES.ADMIN_DASHBOARD_MAKE_NEW_USER}
                                    >
                                        <FontAwesomeIcon
                                            icon={faUser}
                                        />{' '}
                                        Make New User

                                    </NavLink>
                                    <h3 className='nav-link'>
                                        User
                                    </h3>
                                </>
                            )}
                            {       
                                <>
                                    <NavLink
                                        className="nav-link"
                                        activeClassName="active"
                                        to={ROUTES.DASHBOARD_HOME}
                                    >
                                        <FontAwesomeIcon icon={faHome} /> Home
                                    </NavLink>
                                    <NavLink
                                        className="nav-link"
                                        activeClassName="active"
                                        to={ROUTES.DASHBOARD_TEAM}
                                    >
                                        <FontAwesomeIcon icon={faUsers} /> Team{' '}
                                    </NavLink>
                                    <NavLink
                                        className="nav-link"
                                        activeClassName="active"
                                        to={ROUTES.DASHBOARD_RESOURCES}
                                    >
                                        <FontAwesomeIcon icon={faFolder} /> Resources{' '}
                                    </NavLink>
                                    <NavLink
                                        className="nav-link"
                                        activeClassName="active"
                                        to={ROUTES.DASHBOARD_HACKERS}
                                    >
                                        <FontAwesomeIcon icon={faUserFriends} /> Hackers{' '}
                                    </NavLink>
                                    <NavLink
                                        className="nav-link"
                                        activeClassName="active"
                                        to={ROUTES.DASHBOARD_ACCOUNT}
                                    >
                                        <FontAwesomeIcon icon={faCog} /> Account
                                    </NavLink>
                                    {/* <NavLink className="nav-link" activeClassName="active" to={ROUTES.DASHBOARD_SCHEDULE}><FontAwesomeIcon icon={faCalendarAlt}/> Schedule</NavLink> */}
                                    {/* <NavLink className="nav-link" activeClassName="active" to={ROUTES.DASHBOARD_WORKSHOP}><FontAwesomeIcon icon={faUser}/> Workshops</NavLink> */}
                                </>
                            }
          
                        </Nav>

                        <div className="gs-logout-button">
                            <LogOutButton />
                        </div>
                    </div>
                    <div className="gs-dashboard-nav-mobile d-lg-none bg-grey">
                        <Navbar collapseOnSelect expand="lg">
                            <Row className="gs-navbar-logo">
                                <Image
                                    src={SOCIAL_MEDIA_IMAGES.logo_short}
                                    className="pr-2 pl-3 pt-4 pb-4 mt-auto mb-auto"
                                    fluid
                                />
                                <h4 className="text-black mt-auto mb-auto">
                                    <b>Global</b> Spark
                                </h4>
                            </Row>
                            <Navbar.Toggle />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="mr-auto">
                                    {authUser.perm_level ===
                                        PERM_LEVELS.ADMIN && (
                                        <>
                                            <NavLink
                                                className="nav-link"
                                                activeClassName="active"
                                                to={ROUTES.ADMIN_DASHBOARD_HOME}
                                            >
                                                {' '}
                                                Home
                                            </NavLink>
                                            <NavLink
                                                className="nav-link"
                                                activeClassName="active"
                                                to={
                                                    ROUTES.ADMIN_DASHBOARD_HACKER_INFO
                                                }
                                            >
                                                {' '}
                                                Hacker Infos
                                            </NavLink>
                                            <NavLink
                                                className="nav-link"
                                                activeClassName="active"
                                                to={
                                                    ROUTES.ADMIN_DASHBOARD_MAKE_NEW_USER
                                                }
                                            >
                                                {' '}
                                                Make New User
                                            </NavLink>
                                        </>
                                    )}
                                    {authUser.perm_level ===
                                        PERM_LEVELS.USER && (
                                        <NavLink
                                            className="nav-link"
                                            activeClassName="active"
                                            to={ROUTES.DASHBOARD_HOME}
                                        >
                                            {' '}
                                            Home
                                        </NavLink>
                                    )}
                                    {(authUser.acceptance_status ===
                                        APPLICATION_STATUS.ACCEPTED_RSVP_YES
                                        || authUser.perm_level === PERM_LEVELS.ADMIN) && (
                                        <>
                                            <NavLink
                                                className="nav-link"
                                                activeClassName="active"
                                                to={ROUTES.DASHBOARD_TEAM}
                                            >
                                                Team
                                            </NavLink>
                                            <NavLink
                                                className="nav-link"
                                                activeClassName="active"
                                                to={ROUTES.DASHBOARD_RESOURCES}
                                            >
                                                Resources{' '}
                                            </NavLink>
                                            <NavLink
                                                className="nav-link"
                                                activeClassName="active"
                                                to={ROUTES.DASHBOARD_HACKERS}
                                            >
                                                Hackers{' '}
                                            </NavLink>
                                        </>
                                    )}
                                    <NavLink
                                        className="nav-link"
                                        activeClassName="active"
                                        to={ROUTES.DASHBOARD_ACCOUNT}
                                    >
                                        Account
                                    </NavLink>
                                    <div className="gs-logout-button pill">
                                        <LogOutButton />
                                    </div>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                </div>
            )}
        </AuthUserContext.Consumer>
    );
};

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition))(DashboardNav);
