/**
 * Returns an object indicating whether the current time is within the specified period.
 * 
 * Period Descriptions:
 * - REGISTRATION_PERIOD: Time period during which participants can register.
 * - TEAM_FORMATION_PERIOD: Time period during which participants can form teams.
 * - THEME_SELECTION_PERIOD: Time period during which participants can select themes after being assigned to a team.
 * - HACKATHON_PERIOD: Time period during which the hackathon event takes place.
 * 
 * @param {"REGISTRATION_PERIOD" | "TEAM_FORMATION_PERIOD" | "THEME_SELECTION_PERIOD" | "HACKATHON_PERIOD"} periodType - The type of period with start and end dates.
 * @returns {Object} An object containing a boolean `isActive` to indicate if the current time is within the specified period and a `status` string to describe the period's state.
 */
export const isDuringActivePeriod = (periodType) => {
  const currentDateTime = Date.now();
  let startDateTime = 0;
  let endDateTime = 0;

  if (process.env.REACT_APP_MODE === "debug") {
    return { isActive: true, status: "debugMode" };
  }

  // console.log("isActive", process.env.APP_MODE);

  switch (periodType) {
    case "REGISTRATION_PERIOD":
      // Registration period: time period during which participants can register
      startDateTime = Date.parse(process.env.REACT_APP_REGISTRATION_START_TIME);
      endDateTime = Date.parse(process.env.REACT_APP_REGISTRATION_CLOSE_TIME);
      break;
    case "TEAM_FORMATION_PERIOD":
      // Team formation period: time period during which participants can form teams
      startDateTime = Date.parse(process.env.REACT_APP_TEAM_FORMATION_START_TIME);
      endDateTime = Date.parse(process.env.REACT_APP_TEAM_FORMATION_CLOSE_TIME);
      break;
    case "THEME_SELECTION_PERIOD":
      // Theme selection period: time period during which participants can select themes / concepts for their project after being assigned to a team
      startDateTime = Date.parse(process.env.REACT_APP_THEME_SELECTION_START_TIME);
      endDateTime = Date.parse(process.env.REACT_APP_THEME_SELECTION_CLOSE_TIME);
      break;
    case "HACKATHON_PERIOD":
      // Hackathon period: time period during which the hackathon event takes place
      startDateTime = Date.parse(process.env.REACT_APP_HACKATHON_START_TIME);
      endDateTime = Date.parse(process.env.REACT_APP_HACKATHON_CLOSE_TIME);
      break;
    case "SUBMISSION_PERIOD":
      // Submission period: time period during which hackers can submit their work
      startDateTime = Date.parse(process.env.REACT_APP_SUBMISSION_START_TIME);
      endDateTime = Date.parse(process.env.REACT_APP_SUBMISSION_CLOSE_TIME);
      break;
    case "SCORE_VIEW_PERIOD":
      // Score view period: time period during which hackers can view their scores on the teams page after juding is finished
      startDateTime = Date.parse(process.env.REACT_APP_SCORE_VIEW_START_TIME);
      endDateTime = Date.parse(process.env.REACT_APP_SCORE_VIEW_CLOSE_TIME);
      break;
    default:
      startDateTime = Date.parse(process.env.REACT_APP_DEFAULT_START_TIME);
      endDateTime = Date.parse(process.env.REACT_APP_DEFAULT_CLOSE_TIME);
      break;
  }

  // console.log("startDateTime:", startDateTime);
  // console.log("endDateTime:", endDateTime);
  // console.log("currentDateTime:", currentDateTime);

  if (currentDateTime < startDateTime) {
    return { isActive: false, status: "notStarted" };
  } else if (currentDateTime > endDateTime) {
    return { isActive: false, status: "ended" };
  }

  return { isActive: true, status: "active" };
};
