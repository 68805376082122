/** @format */

import React, { Component } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import DashboardNav from '../dashboard';
import { withAPI } from '../../api';
import { ROUTES, PASSWORD_REGEX } from '../../util';
import { withAuthorization } from '../../session';
import {APPLICATION_STATUS} from '../../util';

const MakeNewUserPage = () => (
    <>
        <DashboardNav />
        <div className="gs-dashboard-content">
            <MakeNewUser />
        </div>
    </>
);

const INITIAL_STATE = {
    firstname: '',
    lastname: '',
    password: '',
    email: '',
    linkedin: '',
    university: '',
    acceptance_status: '',
    user_role: '',
    timezone: '',
    food: '',
    location: '',
    csv_line: '',
    id: '',
    is_tester: false,
    is_admin: false,
    error: null,
};

class AccountBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    handleDiscard = (event) => {
        window.location.reload();
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onSubmit = (event) => {
        const {
            firstname,
            lastname,
            password,
            acceptance_status,
            linkedin,
            email,
            university,
            location,
            user_role,
            timezone,
            id,
            is_tester,
            is_admin,
            food,
        } = this.state;

        this.props.api.newUser(
            firstname,
            lastname,
            email,
            password,
            acceptance_status,
            linkedin,
            university,
            location,
            user_role,
            timezone,
            id,
            is_tester,
            is_admin,
            food
        ).then((res) => {
            if (res.status === 200) {
                window.alert("Success!") // Temporary - add proper logging in future
            } else {
                console.log(res)
            }
            this.setState({ ...INITIAL_STATE });
            this.handleDiscard();
        })
        .catch((error) => {
            console.log(error);
            if (error.status === 11000) {
                window.alert("User with this email already exists!") // temporary - need to make better later
            }
        });
        event.preventDefault();
    }


    render() {
        const {
            firstname,
            lastname,
            password,
            email,
            acceptance_status,
            linkedin,
            university,
            location,
            user_role,
            timezone,
            id,
            is_tester,
            // csv_line, in future should add some kind of utility to take a csv line (as would be in applicants.csv) and autopopulate the fields
            is_admin,
            food,
        } = this.state;

        const isInvalid = firstname === '' || lastname === '' || password === '' || location === '' || acceptance_status === '' || email === '';
        return (
            <Container className="gs-account-container">
                <h3>Make a New User</h3>
                <br />
                <Form>
                    <div className="pt-5 pb-2">
                        <h4>Required</h4>
                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                First Name
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="firstname"
                                    value={firstname}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="First Name"
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                Last Name
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="lastname"
                                    value={lastname}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="Last Name"
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                Email
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="email"
                                    value={email}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="Email"
                                />
                            </Col>
                        </Form.Group>
                        
                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                Password
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="password"
                                    value={password}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="Password"
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                Hub
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="location"
                                    as="select"
                                    onChange={this.onChange}
                                    value={location}
                                >
                                    <option value="">
                                        -- Choose a hub --
                                    </option>
                                    <option>Toronto</option>
                                    <option>London</option>
                                    <option>OnlineGMT</option>
                                    <option>OnlineEST</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                Acceptance Status
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    as="select"
                                    name = "acceptance_status"
                                    defaultValue="Select Option"
                                    onChange={this.onChange}
                                    value = {acceptance_status}
                                >
                                    <option value = "">
                                        Select Option
                                    </option>
                                    <option>ACCEPTED_NEED_RSVP</option>
                                    <option>WAITLISTED</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <h4>Debug Options</h4>

                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                DEBUG SETTING: is_tester
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="is_tester"
                                    value={is_tester}
                                    onChange={this.onChange}
                                    as="select"
                                >
                                    <option>false</option>
                                    <option>true</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                DEBUG SETTING: is_admin
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="is_admin"
                                    value={is_admin}
                                    onChange={this.onChange}
                                    as="select"
                                >
                                    <option>false</option>
                                    <option>true</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <h4>Optional Fields</h4>

                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                Time Zone
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="timezone"
                                    value={timezone}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="Time Zone"
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                Any Dietary Restrictions?
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="food"
                                    value={food}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="Food Allergies, Dietary Restrictions, etc."
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                School / Institution
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="university"
                                    value={university}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="School / Institution"
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            {/* Called "Linkedin" for continuity purposes - can be any social media URL */}
                            <Form.Label column sm="5">
                                Social URL
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="linkedin"
                                    value={linkedin}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="Linkedin URL, etc."
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                Team Role
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="user_role"
                                    as="select"
                                    onChange={this.onChange}
                                    value={user_role}
                                >
                                    <option value="">
                                        -- Choose a team role --
                                    </option>
                                    <option>The Social Impact Strategist</option>
                                    <option>The Entrepreneur</option>
                                    <option>The Financial Architect</option>
                                    <option>The Technical Alchemist</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                HTG ID
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="id"
                                    value={id}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="HTG team given user ID"
                                />
                            </Col>
                        </Form.Group>

                    </div>   
                </Form>
                <Button
                    className="mb-3 mr-3 btn-purple"
                    disabled={isInvalid}
                    onClick={this.onSubmit}
                >
                    Save
                </Button>

                <div className="d-flex justify-content-end">
                    <Button className="mb-3 mr-3" onClick={this.handleDiscard}>
                        Discard Changes
                    </Button>
                </div>
            </Container>
        );
    }
}

const condition = (authUser) =>
    !!authUser &&
    ((authUser.acceptance_status === 'ACCEPTED_RSVP_YES' &&
        authUser.rsvp_option) ||
        authUser.perm_level === 'Admin');

const MakeNewUser = compose(
    withRouter,
    withAPI,
    withAuthorization(condition)
)(AccountBase);

export default MakeNewUserPage;
