/** @format */

import React from 'react';
import {
    Modal,
    ModalBody,
    Button,
} from 'react-bootstrap';
import { Component } from 'react';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { withAPI } from '../../api';
import { compose } from 'recompose';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, {
    Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {questions} from '../judge/util/questions'
const { SearchBar } = Search;

const INITIAL_STATE = {
    scores:[],
    teamColumns:[ { text: 'Judge', dataField: 'judge' },]
};

class Popup extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        console.log(this.props.teamCode);
        this.getScores();
        // this.props.api.getScore(this.props.teamCode, this.props.judgeName).then((res)=>{
        //     console.log(res.body);
        //     if (res.body.scores){this.setState({scores:res.body.scores});}
        //     if (res.body.comments){this.setState({comments:res.body.comments});}
        // })

    }

    handleSubmission = () => {
        console.log(this.state);
    };

    onChange = (event) => {
        if (event.target.value === '') {
            this.setState({
                error: 'Please complete all fields before continuing',
            });
        } else {
            this.setState({ error: '' });
        }
        this.setState({ [event.target.name]: event.target.value });
    };

    

    componentDidUpdate(prevState, prevProps){
        console.log('updated');
        if(prevProps.teamCode !== this.props.teamCode){
            // this.getScores();
        }
    }

    getScores = () => {
        console.log(this.props.teamCode);
        this.props.api.getTeamScores(this.props.teamCode)
            .then((res) => {
                console.log(res);
                let scoresTemp=[];
                const Scores = res.Scores;
                for (const Score of Scores) {
                    const score = {};
                    score['judge'] = Score.judge;
                    if (Score.scores){ 
                        for (let i = 0; i < Score.scores.length; i++){
                            score[`${i}`] = Score.scores[i];
                        }
                        if (Score.comments) {
                            for (let i = Score.scores.length; i < Score.comments.length; i++){
                                score[`${i}`] = Score.comments[i];
                            }
                        }
                        scoresTemp.push(score);
                    }
                }

                let questionList = questions[0];
                console.log(scoresTemp);
                this.setState({scores:scoresTemp});

                if(scoresTemp){
                    let teamColumnsTemp = [{ text: 'Judge', dataField: 'judge' },];
                    for(let i = 0; i < questionList.length; i ++){
                        console.log(questionList[i]);
                        if (questionList[i].type === 'comment'){
                            teamColumnsTemp.push({text: questionList[i].title, dataField:`${i}`,headerStyle: { minWidth: '300px', width:'300px' }, style: { width: '300px', minWidth:'300px' } });
                        } else{
                            teamColumnsTemp.push({text: questionList[i].title, dataField:`${i}`});
                        }
                    }
                    console.log(teamColumnsTemp);

                    this.setState({teamColumns:teamColumnsTemp});
                }
                console.log(this.state.scores);
            })
            .catch((error) => {
                console.log(error.errorMsg);
            });
        this.props.api.getPassportPoints(this.props.teamCode)
            .then((res) => {
                console.log(res);
                this.setState({ppPoints:res.points});
            })
            .catch((error) => {
                console.log(error.errorMsg);
            });
    };


    render() {
        const {
            teamCode,
            scores,
            ppPoints,
        } = this.state;
        const isMissingInfo = !(
            teamCode
        );
        
        const customTotal = (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total" style={{marginLeft:"15px"}}>
                Showing {from} to {to} of {size} Results
            </span>
        ); 

        const teamOptions = {
            paginationSize: 4,
            pageStartIndex: 0,
            showTotal: true,
            paginationTotalRenderer: customTotal,
            disablePageTitle: true,
            sizePerPageList: [
                {text: '20',value: 20,},
                {text: '50',value: 50,},
                {text: 'All',value: 10,},
            ],
        };


        return (
            <Modal show centered size="xl" className="waitlisted rubricPopupContainer">
                <ModalHeader className="welcome">
                    
                </ModalHeader>
                <ModalBody className="rubric-body" >
                    <div className="rubric-content padding-top-0">
                        <span>{`Code: ${this.props.teamCode}`}</span>
                        <span>{`Passport Points:${ppPoints}`}</span>
                        <ToolkitProvider
                        keyField="id"
                        columns={this.state.teamColumns}
                        data={scores}
                        exportCSV
                    >
                        {(props) => (
                            <div>
                                <br />
                                <BootstrapTable
                                    
                                    {...props.baseProps}
                                    pagination={paginationFactory(teamOptions)}
                                    hover
                                />
                            </div>
                        )}
                    </ToolkitProvider>
                        
                        <div className="button-container">
                            <Button
                                className="btn-red"
                                onClick={() => {
                                    this.props.closeModal();
                                }}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </ModalBody>
                

                
            </Modal>
        );
    }
}

const ScorePopup = compose(withAPI)(Popup);

export default ScorePopup;
