/** @format */

import React, { Component } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import DashboardNav from '../';
import { withAPI } from '../../../api';
import { ROUTES, PASSWORD_REGEX } from '../../../util';
import { withAuthorization } from '../../../session';

const AccountPage = () => (
    <>
        <DashboardNav />
        <div className="gs-dashboard-content">
            <Account />
        </div>
    </>
);

const INITIAL_STATE = {
    firstname: '',
    lastname: '',
    passwordOne: '',
    passwordTwo: '',
    nameUpdated: false,
    passwordUpdated: false,
    linkedin: '',
    university: '',
    user_role: '',
    timezone: '',
    food: '',
    location: '',
    profileUpdated: false,
    error: null,
};

class AccountBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    async resetForm() {
        this.props.api.currentUser().then((data) => {
            this.setState({
                firstname: data.User.first_name,
                lastname: data.User.last_name,
                linkedin: data.User.social_url,
                university: data.User.university,
                user_role: data.User.user_role,
                timezone: data.User.timezone,
                food: data.User.diet_restriction,
                location: data.User.location,
            });
        });
        /*this.props.firebase.user(this.props.firebase.curUser().uid).once('value').then((snapshot) => {
            const data = snapshot.val();
            this.setState({firstname: data.first_name, lastname: data.last_name})
        });*/
    }

    componentDidMount() {
        this.resetForm();
    }

    handleDiscard = (event) => {
        this.props.history.push(ROUTES.DASHBOARD_HOME);
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onSubmitName = (event) => {
        const { firstname, lastname } = this.state;
        this.setState({ error: null });

        this.props.api
            .updateUser({ first_name: firstname, last_name: lastname })
            .then(() => {
                this.setState({ nameUpdated: true });
                this.resetForm();
            })
            .catch((error) => {
                this.setState({ error: error.errorMsg });
            });
        /*this.props.firebase.doUpdateName(firstname, lastname).then(() => {
            this.setState({nameUpdated: true});
            this.resetForm();
        }).catch(error => {
            this.setState({ error });
        });*/

        event.preventDefault();
    };

    onSubmitPassword = (event) => {
        const { passwordOne, passwordTwo } = this.state;
        this.setState({ error: null });

        if (!PASSWORD_REGEX.test(passwordTwo)) {
            this.setState({
                error: 'Password must have minimum 8 characters, at least 1 letter and 1 number.',
            });
            return;
        }
        this.props.api
            .updateUser({ oldPassword: passwordOne, password: passwordTwo })
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.setState({ passwordUpdated: true });
                this.resetForm();
            })
            .catch((error) => {
                this.setState({ error: error.errorMsg });
            });
        /*this.props.firebase.doLoginWithEmailAndPassword(this.props.firebase.curUser().email, passwordOne).then(() => {
            this.props.firebase.doPasswordUpdate(passwordTwo).then(() => {
                this.setState({ ...INITIAL_STATE });
                this.setState({passwordUpdated: true});
                this.resetForm();
            }).catch(error => {
                this.setState({ error });
            });
        }).catch(error => {
            this.setState({ error });
        });*/

        event.preventDefault();
    };

    onSubmitProfile = (event) => {
        const { linkedin, university, user_role, food, timezone } = this.state;
        const timezoneRegex = /^GMT[+-](?:[0-9]|1[0-2])$/;
        if (!timezoneRegex.test(timezone)) {
            this.setState({ error: 'Use GMT format time, e.g. GMT+0' });
            event.preventDefault();
            return;
        }
        this.setState({ error: null });
        this.props.api
            .updateUser({
                social_url: linkedin,
                university: university,
                user_role: user_role,
                diet_restriction: food,
                timezone: timezone,
            })
            .then(() => {
                this.setState({ profileUpdated: true });
                this.resetForm();
            })
            .catch((error) => {
                this.setState({ error: error.errorMsg });
            });

        event.preventDefault();
    };

    render() {
        const {
            firstname,
            lastname,
            passwordOne,
            passwordTwo,
            error,
            nameUpdated,
            passwordUpdated,
            linkedin,
            university,
            location,
            user_role,
            timezone,
            profileUpdated,
            food,
        } = this.state;
        const isInvalidName = firstname === '' || lastname === '';
        const isInvalidPassword = passwordOne === '' || passwordTwo === '';
        const isInvalidProfile =
            linkedin === '' || university === '' || user_role === '';

        return (
            <Container className="gs-account-container">
                <h3>Account Settings</h3>
                <br />
                <p>
                    Please contact hacktheglobe@globalspark.world with concerns.
                </p>
                <Form>
                    <div className="pt-5 pb-2">
                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                First Name
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="firstname"
                                    value={firstname}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="First Name"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                Last Name
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="lastname"
                                    value={lastname}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="Last Name"
                                />
                            </Col>
                        </Form.Group>
                    </div>
                    {nameUpdated ? (
                        <p className="text-red">Success!</p>
                    ) : (
                        <Button
                            className="mb-3 mr-3 btn-purple"
                            disabled={isInvalidName}
                            onClick={this.onSubmitName}
                        >
                            {' '}
                            Save
                        </Button>
                    )}
                </Form>
                <Form>
                    <div className="pt-3 pb-2">
                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                Old Password
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="passwordOne"
                                    value={passwordOne}
                                    onChange={this.onChange}
                                    type="password"
                                    placeholder="Old Password"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                New Password
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="passwordTwo"
                                    value={passwordTwo}
                                    onChange={this.onChange}
                                    type="password"
                                    placeholder="New Password"
                                />
                            </Col>
                        </Form.Group>
                    </div>
                    {passwordUpdated ? (
                        <p className="text-red">Success!</p>
                    ) : (
                        <Button
                            className="mb-3 mr-3 btn-purple"
                            disabled={isInvalidPassword}
                            onClick={this.onSubmitPassword}
                        >
                            Save
                        </Button>
                    )}
                    <div className="pt-3">
                        {error && <p className="text-red">{error}</p>}
                    </div>
                </Form>

                <Form>
                    <div className="pt-5 pb-2">
                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                Time Zone
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="timezone"
                                    value={timezone}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="Time Zone"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                School / Institution
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="university"
                                    value={university}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="School / Institution"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            {/* Called "Linkedin" for continuity purposes - can be any social media URL */}
                            <Form.Label column sm="5">
                                Social URL
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="linkedin"
                                    value={linkedin}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="Linkedin URL, etc."
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="5">
                                Which best suits you?
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    className="form-input"
                                    name="user_role"
                                    as="select"
                                    onChange={this.onChange}
                                    value={user_role}
                                >
                                    <option value="">
                                        -- Choose a team role --
                                    </option>
                                    <option>The Social Impact Strategist</option>
                                    <option>The Entrepreneur</option>
                                    <option>The Financial Architect</option>
                                    <option>The Technical Alchemist</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        {
                            // Dietary Restrictions here
                            (location === 'Toronto' || location === 'London') && (
                                <Form.Group as={Row}>
                                    <Form.Label column sm="5">
                                        Any Dietary Restrictions?
                                    </Form.Label>
                                    <Col sm="7">
                                        <Form.Control
                                            className="form-input"
                                            name="food"
                                            value={food}
                                            onChange={this.onChange}
                                            type="text"
                                            placeholder="Food Allergies, Dietary Restrictions, etc."
                                        />
                                    </Col>
                                </Form.Group>
                            )
                        }

                        {profileUpdated ? (
                            <p className="text-red">Success!</p>
                        ) : (
                            <Button
                                className="mb-3 mr-3 btn-purple"
                                disabled={isInvalidProfile}
                                onClick={this.onSubmitProfile}
                            >
                                Save
                            </Button>
                        )}
                        <div className="pt-3">
                            {error && <p className="text-red">{error}</p>}
                        </div>
                    </div>
                </Form>
                <div className="d-flex justify-content-end">
                    <Button className="mb-3 mr-3" onClick={this.handleDiscard}>
                        Back to Dashboard
                    </Button>
                </div>
            </Container>
        );
    }
}

const condition = (authUser) =>
    !!authUser &&
    ((authUser.acceptance_status === 'ACCEPTED_RSVP_YES' &&
        authUser.rsvp_option) ||
        authUser.perm_level === 'Admin');

const Account = compose(
    withRouter,
    withAPI,
    withAuthorization(condition)
)(AccountBase);

export default AccountPage;
