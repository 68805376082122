import React, { Component } from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { compose } from 'recompose';

import { withAPI } from '../../../api';
import { AuthUserContext, withAuthorization } from '../../../session';
import { PAGES_IMAGES, PERM_LEVELS } from '../../../util';
import Footer from '../../../components/Footer';
import FAQs from './FAQs';
import Mentor from './mentor';
import DashboardNav from '../';
import PopupRegistration from './popup';
import './popup_style.css';
const HomePage = () => {
    return (
        <>
            <DashboardNav />
            <div className="gs-dashboard-content">
                <Container className="gs-home-container">
                    <Home />
                </Container>
            </div>
        </>
    );
};

class HomeBase extends Component {
    componentDidMount() {
        console.log('welcome!');
    }

    state = {
      isPopupRendered: false,
    };
  
    handlePopupRendered = () => {
      this.setState({ isPopupRendered: true });
    };

    render() {
        const { isPopupRendered } = this.state;
        return (
            <AuthUserContext.Consumer>
                {({ authUser, updateAuthUser }) => (
                    <div>
                        {!isPopupRendered && authUser.perm_level !== PERM_LEVELS.ADMIN && (
                            <PopupRegistration renderHomePage={this.handlePopupRendered} />
                        )}
                        {(isPopupRendered || authUser.perm_level === PERM_LEVELS.ADMIN) && (
                            <div>
                                <Container className="gs-htg-block">
                                    <div
                                        className="mr-auto ml-auto text-center"
                                        style={{ maxWidth: '30rem' }}
                                    >
                                        <Image
                                            src={PAGES_IMAGES.youre_in}
                                            fluid
                                            className="p-3"
                                        />
                                        <h1 className="pb-3">
                                            Hey {authUser.first_name}, you're in.
                                        </h1>
                                        <p>
                                            Welcome to Hack the Globe 2025.
                                            <br />
                                            Here's what you need to know!
                                        </p>
                                    </div>
                                </Container>

                                <Mentor mentorInfo={authUser.mentorInfo} />

                                <Container className="gs-htg-block">
                                    <Row>
                                        <Col md={6}>
                                            <Card className="mr-md-3 mb-5 mb-md-0" style={{height:"100%"}}>
                                                <Card.Img
                                                    variant="top"
                                                    src={PAGES_IMAGES.schedule.default}
                                                />
                                                <Card.Body style={{display:"flex", flexDirection:"column"}}>
                                                    <Card.Title as="h4">
                                                        Resources
                                                    </Card.Title>
                                                    <Card.Text>
                                                        Find information about HTG
                                                        2025, including guides,
                                                        schedules, judging criteria,
                                                        and more!
                                                    </Card.Text>
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="btn-blue w-50"
                                                        style = {{marginTop:"auto"}}
                                                        onClick={() =>
                                                            (window.location.href ='/dashboard/resources')}
                                                    >
                                                        Resources
                                                    </a>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col md={6}>
                                            <Card className="ml-md-3" style={{height:"100%"}}>
                                                <Card.Img
                                                    variant="top"
                                                    src={PAGES_IMAGES.hacker_guide.default}
                                                />
                                                <Card.Body style={{display:"flex", flexDirection:"column"}}>
                                                    <Card.Title as="h4">
                                                        Hacker Directory
                                                    </Card.Title>
                                                    <Card.Text>
                                                        Here's where you can see a
                                                        list of all HTG 2025
                                                        hackers, view their
                                                        profiles, and find
                                                        prospective teammates!
                                                    </Card.Text>
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="btn-secondary w-50"
                                                        style = {{marginTop:"auto"}}
                                                        onClick={() =>
                                                            (window.location.href ='/dashboard/hackers')
                                                        }
                                                    >
                                                        Hacker List
                                                    </a>
                                                    {/* <Button disabled> Coming Soon...</Button> */}
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>
                                <FAQs />
                                <Footer />
                            </div>
                        )}
                    </div>
                )}
            </AuthUserContext.Consumer>
        );
    }
}

const condition = (authUser) => !!authUser;

const Home = compose(withAPI, withAuthorization(condition))(HomeBase);

export default HomePage;

/* Deprecated code, with user application integrated. Not needed for 2024, may be useful for future.

  render() {
    return (
      <AuthUserContext.Consumer>
        {({ authUser, updateAuthUser }) => (
          <div>
            {authUser.acceptance_status ===
              APPLICATION_STATUS.ACCEPTED_RSVP_YES ||
            authUser.role === ROLES.ADMIN ? (
              // 1. Accepted and RSVP'ed
              <div>
                <Container className="gs-htg-block">
                  <div
                    className="mr-auto ml-auto text-center"
                    style={{ maxWidth: "30rem" }}
                  >
                    <Image src={PAGES_IMAGES.youre_in} fluid className="p-3" />
                    <h1 className="pb-3">
                      Hey {authUser.first_name}, you're in.
                    </h1>
                    <p>
                      Thanks for applying to Hack the Globe 2023. Here's what
                      you need to know:
                    </p>
                  </div>
                </Container>

                <Mentor mentorInfo={authUser.mentorInfo} />

                <Container className="gs-htg-block">
                  <div className="gs-home-slack-container">
                    <Row className="justify-content-center">
                      <Image
                        src={PAGES_IMAGES.slack.default}
                        fluid
                        className="pr-5 pl-5 p-lg-0"
                      />
                      <Col lg={9} className="pr-lg-5 pl-lg-5">
                        <h3>Onboard onto our Teams</h3>
                        <p>
                          This is where we'll make announcements, make teams,
                          and talk about activities going on throughout the
                          hackathon! Meet us there to stay up to date with all
                          the exciting things happening at Hack the Globe 2023!
                        </p>
                        <p>
                        Please look out for the email from Microsoft with instructions on how to sign up for the Hack the Globe Teams workspace (and receive your free Microsoft 365 license). You will only be able to access Teams through this link if you have registered in advance!
                        </p>
                      </Col>
                      <div className="mt-auto pr-5 pl-5 p-lg-0">
                        <a
                          href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fteam%2F19%3AWfDVEO-PcMvWptJSqmSvz216rwgN_lxKWJ2BUJEvvmA1%40thread.tacv2%2Fconversations%3FgroupId%3Deb37cd98-3464-4a48-8b95-6d098a0a0097%26tenantId%3D749c2b36-8c89-472d-b8ca-b7b5c0d02590&type=team&deeplinkId=8ea77cdb-0ad9-4da6-a414-09d099c4d074&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true"
                          className="btn-blue p-3"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Join
                        </a>
                      </div>
                    </Row>
                  </div>
                </Container>

                <Container className="gs-htg-block">
                  <Row>
                    <Col md={6}>
                      <Card className="mr-md-3 mb-5 mb-md-0">
                        <Card.Img
                          variant="top"
                          src={PAGES_IMAGES.schedule.default}
                        />
                        <Card.Body>
                          <Card.Title as="h4">Schedule</Card.Title>
                          <Card.Text>
                            Get the full schedule for the kickoff and the
                            hackathon weekend! Schedules for the kickoff
                            activity rotation will be provided separately.
                          </Card.Text>
                          <a
                            
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn-blue w-50"
                          >
                            Coming soon
                          </a>
                          { <Button disabled> Coming Soon...</Button> }
                          </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card className="ml-md-3">
                            <Card.Img
                              variant="top"
                              src={PAGES_IMAGES.hacker_guide.default}
                            />
                            <Card.Body>
                              <Card.Title as="h4">Hacker Guide</Card.Title>
                              <Card.Text>
                                Here's everything you need to know about Hack the
                                Globe including our theme experts, judging &amp;
                                evaluation criteria, and prizes!
                              </Card.Text>
                              <a
                                
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn-secondary w-50"
                              >
                                Coming soon
                              </a>
                              { <Button disabled> Coming Soon...</Button> }
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Container>
    
                    
                    <FAQs />
                    <Footer />
                  </div>
                ) : (
                  <div className="gs-home-preadmit mr-auto ml-auto">
                    <h3 className="pb-5 text-center">Hey {authUser.first_name}</h3>
                    {authUser.acceptance_status === APPLICATION_STATUS.NO_APP ||
                    authUser.role === ROLES.ADMIN ? (
                      // 2. No Application
                      <div>
                        {this.state.isApplicationOpen ? (
                          <>
                            <h5 className="pb-3 text-red">
                              Thanks for registering. Please apply to HTG below
                            </h5>
                            <div className="d-flex justify-content-center p-3">
                              <Button
                                disabled={authUser.application_typeform_id !== ""}
                                onClick={() => this.handleApplyShow(authUser.email)}
                                className="mr-3 btn-lg "
                              >
                                Apply now <FontAwesomeIcon icon={faArrowRight} />
                              </Button>
                            </div>
                          </>
                        ) : (
                          <>
                            <h5 className="pb-3 text-red">
                              Applications for Hack The Globe 2023 are now closed.
                            </h5>
                            <p className="pt-3 pb-3">
                              Follow our social media for updates!
                            </p>
                          </>
                        )}
                      </div>
                    ) : (
                      // 3, 4, 5. Submitted, Wailsted, Rejected, or Offer Expired
                      <div>
                        <h5 className="pb-3 text-red">
                          Thanks for applying for Hack The Globe 2023.
                        </h5>
                        <p className="pb-3">
                          Your application status is currently:
                        </p>
                        <h4 className="pb-2 text-center w-100 border-bottom">
                          {this.statusToText(authUser.acceptance_status)}
                        </h4>
                        {
                          // 6, 7. Accepted but have not RSVP'ed or RSVP'ed NO
                          (authUser.acceptance_status ===
                            APPLICATION_STATUS.ACCEPTED_NEED_RSVP ||
                            authUser.acceptance_status ===
                              APPLICATION_STATUS.ACCEPTED_RSVP_NO ||
                            authUser.role === ROLES.ADMIN) && (
                            <span>
                              {authUser.acceptance_status ===
                              APPLICATION_STATUS.ACCEPTED_RSVP_NO ? (
                                <p>
                                  You have submitted the RSVP form for Hack The
                                  Globe. Thank you!
                                </p>
                              ) : (
                                <span>
                                  <Button
                                    disabled={authUser.diet_restriction !== ""}
                                    onClick={() =>
                                      this.handleRSVPShow(authUser.email)
                                    }
                                    className="mr-3"
                                  >
                                    RSVP <FontAwesomeIcon icon={faArrowRight} />
                                  </Button>
                                </span>
                              )}
                            </span>
                          )
                        }
                        <p className="pt-3 pb-3">Stay tuned for updates!</p>
                        <div className="pt-3">
                          {this.state.error && (
                            <p className="text-red">{this.state.error}</p>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="gs-social-media text-center">
                      <a
                        href={EXTERNAL_LINKS.FACEBOOK}
                        className="show-nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faFacebook} />
                      </a>
                      <a
                        href={EXTERNAL_LINKS.INSTAGRAM}
                        className="show-nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                      <a
                        href={EXTERNAL_LINKS.LINKEDIN}
                        className="show-nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faLinkedin} />
                      </a>
                      <a
                        href={EXTERNAL_LINKS.TWITTER}
                        className="show-nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                    </div>
                  </div>
                )}
              </div>
            )}
          </AuthUserContext.Consumer>
        );
      }
    }

*/