import React from "react";
import { Container, Col, Row, Table } from "react-bootstrap";

const Mentor = (props) => {
  const mentorInfo = props.mentorInfo;
  return (
    <div>
      {mentorInfo && (
        <Container className="gs-htg-block">
          <div className="gs-home-mentor-session-container">
            <Row className="justify-content-center">
              <Col md={5} style={{ borderRight: "2px solid #3d3d3d" }}>
                <p>Team #: {mentorInfo.team_num}</p>
                <p>Rotation #: {mentorInfo.rotation_num}</p>
                <p>
                  Activity A (1:15-2:00PM ET): {mentorInfo.activity_a} <br />
                  Activity B (2:00-2:45PM ET): {mentorInfo.activity_b} <br />
                  Activity C (2:45-3:30PM ET): {mentorInfo.activity_c} <br />
                </p>
                <p>
                  Your mentor is: {mentorInfo.mentor_name}
                  <br />
                  Mentor email: {mentorInfo.mentor_email}
                </p>
              </Col>
              <Col md={7}>
                <p>
                  During your theme sessions, your team will split into pairs
                  and attend different rooms.
                </p>
                <Table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>{mentorInfo.timeslot_1}</th>
                      <th>{mentorInfo.timeslot_2}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Pair 1</td>
                      <td>{mentorInfo.ngo_1}</td>
                      <td>{mentorInfo.other_ngo_1}</td>
                    </tr>
                    <tr>
                      <td>Pair 2</td>
                      <td>{mentorInfo.ngo_2}</td>
                      <td>{mentorInfo.other_ngo_2}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </div>
  );
};

export default Mentor;
