import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { withAPI } from "../api";
import compose from "recompose/compose";
import { FaEye, FaTrash, FaLink, FaUpload } from "react-icons/fa";
import DropFileInput from "./DropFileInput";
import AddLinkPopup from "./AddLinkPopup";
import "../assets/style/file-submission-form.css";
import {
  isDuringActivePeriod,
} from '../util';

const FileSubmissionForm = ({ api, team, submitter }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedLinks, setUploadedLinks] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLinkPopupOpen, setIsLinkPopupOpen] = useState(false);
  const [uploadingMessage, setUploadingMessage] = useState(false);
  const [submissionClose, setSubmissionClose] = useState(process.env.REACT_APP_SUBMISSION_CLOSE_TIME);
  const [submissionStart, setSubmissionStart] = useState(process.env.REACT_APP_SUBMISSION_START_TIME);

  // Fetch uploaded files and links when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.getUploadedFilesAndLinksForTeam(team.team_code);
        const data = response.data;

        // Separate files and links
        const files = data.filter((item) => !item.is_link);
        const links = data.filter((item) => item.is_link);

        setUploadedFiles(files);
        setUploadedLinks(links);
      } catch (error) {
        console.error("Failed to fetch uploaded files and links:", error.message);
      }
    };

    fetchData();
  }, [api, team.team_code]);

  // Handle file submission
  const handleSubmitFile = (event) => {
    event.preventDefault();
    console.log("Selected files:", selectedFiles);

    if (!isDuringActivePeriod("SUBMISSION_PERIOD").isActive) {
      setIsPopupOpen(false);
      setSubmissionClose(process.env.REACT_APP_SUBMISSION_CLOSE_TIME);
      return;
    }
  
    if (selectedFiles && selectedFiles.length > 0) {
      setIsPopupOpen(false);
      setUploadingMessage(true);
      api.submitFiles(selectedFiles, team, submitter)
        .then((response) => {
          console.log("Upload response:", response);
          if (response && response.files) {
            // Append the new files to the existing state
            setUploadedFiles(prevFiles => [...prevFiles, ...response.files]);
            setSelectedFiles([]);
          } else {
            console.error("Invalid response format:", response);
          }
          setUploadingMessage(false);
        })
        .catch((error) => {
          console.error("File upload failed:", error.message);
        });
    } else {
      console.error("No file selected");
    }
  };

  

  // Handle file deletion
  const handleDelete = (fileId) => {
    api.deleteFile(fileId)
      .then(() => {
        setUploadedFiles(uploadedFiles.filter(file => file.id !== fileId));
        console.log(`File with ID ${fileId} deleted successfully`);
      })
      .catch(error => {
        console.error(`Failed to delete file with ID ${fileId}:`, error.message);
      });
  };

  // Handle link submission
  const handleAddLink = (link) => {
    api.submitLink(link, team, submitter)
      .then((response) => {
        setUploadedLinks([...uploadedLinks, response.link]);
        console.log("Link added:", response.link);
      })
      .catch((error) => {
        console.error("Failed to submit link:", error);
        alert("Failed to submit link. Please try again.");
      });
  };

  // Handle link deletion
  const handleDeleteLink = (linkId, index) => {
    if (window.confirm("Are you sure you want to delete this link?")) {
      api.deleteLink(linkId)
        .then(() => {
          // Remove the link from the state
          setUploadedLinks((prevLinks) => prevLinks.filter((_, i) => i !== index));
          console.log(`Link with ID ${linkId} deleted successfully`);
        })
        .catch((error) => {
          console.error(`Failed to delete link with ID ${linkId}:`, error.message);
        });
    }
  };

  return (
    <div className="file-submission-form">
      <form onSubmit={handleSubmitFile}>
        <h1>File Submission</h1>

        {isDuringActivePeriod("SUBMISSION_PERIOD").isActive ?
          <>
          <p>File submission ends {new Date(submissionClose).toLocaleString(undefined,{ year: "numeric",
                                                                                                                    month: "short", 
                                                                                                                    day: "numeric",
                                                                                                                    hour: "numeric",
                                                                                                                    minute: "numeric", 
                                                                                                                    timeZoneName: "short",})}.</p>
          <button type="button" onClick={() => setIsPopupOpen(true)} className="submission-btn upload-file-btn">
            <FaUpload className="submission-icon" />
            Upload File
          </button>
          <button type="button" disabled={!isDuringActivePeriod("SUBMISSION_PERIOD").isActive} onClick={() => setIsLinkPopupOpen(true)} className="submission-btn add-link-btn">
            <FaLink /> Add Link
          </button> </>
          :
          (isDuringActivePeriod("SUBMISSION_PERIOD").status === "notStarted" ?
            <p className="text-danger">File submission starts {new Date(submissionStart).toLocaleString(undefined,{ year: "numeric",
                                                                                                                    month: "short", 
                                                                                                                    day: "numeric",
                                                                                                                    hour: "numeric",
                                                                                                                    minute: "numeric", 
                                                                                                                    timeZoneName: "short",})}</p>
            :
            <p className="text-danger">File submission has ended.</p>)
        }
        

        {/* File Upload Popup */}
        {isPopupOpen && (
            <DropFileInput
                onFileChange={setSelectedFiles}
                onClose={() => setIsPopupOpen(false)}
            />
        )}

        {/* Link Submission Popup */}
        {isLinkPopupOpen && (
          <AddLinkPopup
            onClose={() => setIsLinkPopupOpen(false)}
            onAddLink={handleAddLink}
            team={team}
            submitter={submitter}
          />
        )}

        {/* Uploaded Links Section */}
        <h2>Uploaded Links</h2>
        <ul className="uploaded-links-list">
          {uploadedLinks.length > 0 ? (
            uploadedLinks.map((link, index) => (
              <li key={index} className="uploaded-link-item">
                <div className="link-info">
                  <a href={link.link_url} target="_blank" rel="noopener noreferrer">
                    {link.link_title}
                  </a>
                  {link.link_note && <p className="link-note">{link.link_note}</p>}
                </div>
                <div>
                  {/* Add the FaEye icon for viewing the link */}
                  <a href={link.link_url} target="_blank" rel="noopener noreferrer" className="file-action-icon">
                    <FaEye />
                  </a>
                  {/* Add the FaTrash icon for deleting the link */}
                  
                  {isDuringActivePeriod("SUBMISSION_PERIOD").isActive &&
                    <span
                      onClick={() => handleDeleteLink(link._id, index)} // Pass link._id and index
                      className="file-action-icon"
                    >
                      
                      <FaTrash />
                    </span>
                  }
                </div>
              </li>
            ))
          ) : (
            <p>No links uploaded</p>
          )}
        </ul>

        {/* Uploaded Files Section */}
        <h2>Uploaded Files {uploadingMessage && " - Uploading..."}</h2>
        <ul className="uploaded-links-list">
          {uploadedFiles.length > 0 ? (
            uploadedFiles.map((file, index) => (
              <li key={index} className="uploaded-file-item">
                <span>{file.title}</span>
                <div>
                  <a href={file.webViewLink} target="_blank" rel="noopener noreferrer" className="file-action-icon">
                    <FaEye />
                  </a>
                  {isDuringActivePeriod("SUBMISSION_PERIOD").isActive &&
                    <span onClick={() => window.confirm("Delete this file?") && handleDelete(file.id)} className="file-action-icon">
                      <FaTrash />
                    </span>
                  }
                </div>
              </li>
            ))
          ) : (
            <p>No files uploaded</p>
          )}
        </ul>
      </form>
    </div>
  );
};

export default withRouter(withAPI(FileSubmissionForm));