import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./assets/style/style.css";
import API, { APIContext } from "./api";

ReactDOM.render(
  <APIContext.Provider value={new API()}>
    <Router>
      <App />
    </Router>
  </APIContext.Provider>,
  document.getElementById("root"),
);
