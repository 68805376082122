import React, {useState} from 'react';
import {
    Modal,
    ModalBody,
    Button,
    Dropdown,
    FormControl,
} from 'react-bootstrap';
import { Component } from 'react';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { withAPI } from '../../../api';
import { PAGES_IMAGES } from '../../../util';
import { compose } from 'recompose';

export function SearchableDropdown({onSelect, text, setDisplayCancelButton, judgeList}) {
    const [filter, setFilter] = useState('');
    const [selectedItem, setSelectedItem] = useState('Change Judge Name');
  
    if(text){
        setSelectedItem(text);
    }
    const items = judgeList;

    const handleFilterChange = (e) => {
      setFilter(e.target.value);
    };
  
    const handleSelect = (eventKey) => {
      setSelectedItem(eventKey);
      setFilter(''); 

      if (onSelect) {
        onSelect(eventKey);
        if(setDisplayCancelButton){
          setDisplayCancelButton();
        }
      }
      
    };
  
    const filteredItems = items.filter((item) =>
      item.toLowerCase().includes(filter.toLowerCase())
    );
  
    return (
      <Dropdown onSelect={handleSelect}>
        <Dropdown.Toggle variant="success" id="dropdown-basic" className="dropdown-color">
          Select Your Name
        </Dropdown.Toggle>
  
        <Dropdown.Menu
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={handleFilterChange}
            value={filter}
          />
          <div className="dropdown-items">
          {filteredItems.length > 0 ? (
            filteredItems.map((item, index) => (
              <Dropdown.Item key={index} eventKey={item}>
                {item}
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item disabled>No matching items</Dropdown.Item>
          )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

export default SearchableDropdown;